import System from '@/classes/System'
import Settings from '@/constants/settings'

class Api {

    constructor () {
        this.baseURL = Settings.guiBaseUrl;
    }

    // GET request
    get (url, params = false, isAuth = true) {
        return System.request('get', this.baseURL + url, params, false, isAuth, 'json', false);
    }

    // POST request
    post (url, data = false, params = false, isAuth = true) {
        return System.request('post', this.baseURL + url, params, data, isAuth, 'json', false);
    }

    // DELETE request
    delete (url, data = false, params = false, isAuth = true) {
        if (Settings.usePostMethod) data._method = 'DELETE';
        return System.request((Settings.usePostMethod ? 'post' : 'delete'), this.baseURL + url, params, data, isAuth, 'json', false);
    }

    // PATCH request
    patch (url, data = false, params = false, isAuth = true) {
        if (Settings.usePostMethod) data._method = 'PATCH';
        return System.request((Settings.usePostMethod ? 'post' : 'patch'), this.baseURL + url, params, data, isAuth, 'json', false);
    }

}


export default new Api();