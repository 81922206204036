<template>
    <v-alert
        v-model="show"
        :type="color"
        :icon="icon"
        dismissible
        text
    >
        {{message.text}}
    </v-alert>
</template>


<script>
import Interface from '@/classes/Interface'

export default {
    name: 'TopMessage',
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        message () {
            return this.$store.state.system.message;
        },
        show: {
            get () {
                return this.message.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/messageOff')
                }
            }
        },
        color () {
            return Interface.messageColor(this.message.type);
        },
        icon () {
            return Interface.messageIcon(this.message.type);
        },
    },
    methods: {
    },
}
</script>


<style scoped>
</style>
