<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('stress.stress')"
            :icon="mdiGaugeFull"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <SimpleForm 
            @ref="stressForm.ref = $event"
            @input="stressForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('stress.stressForm.url')"
                        v-model="apiUrl"
                        :rules="stressForm.rules.url"
                        :error-messages="stressForm.errors.url"
                        :disabled="running"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-slider
                        min="1"
                        max="10"
                        thumb-label
                        v-model="numThreads"
                        :label="System.lang('stress.stressForm.threads')"
                        :disabled="running"
                    ></v-slider>
                    <v-slider
                        min="0.2"
                        max="10"
                        step="0.2"
                        thumb-label
                        v-model="interval"
                        :label="System.lang('stress.stressForm.interval')"
                        :disabled="running"
                    ></v-slider>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-textarea
                        :label="System.lang('stress.stressForm.terminals')"
                        rows="2"
                        v-model="terminalKeys"
                        :rules="stressForm.rules.terminals"
                        :error-messages="stressForm.errors.terminals"
                        :disabled="running"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-textarea
                        :label="System.lang('stress.stressForm.channels')"
                        rows="2"
                        v-model="channelKeys"
                        :rules="stressForm.rules.channels"
                        :error-messages="stressForm.errors.channels"
                        :disabled="running"
                    ></v-textarea>
                </v-col>
            </v-row>
        </SimpleForm>
        <ActionButton
            v-if="running"
            color="error" 
            :icon="mdiStop"
            :label="System.lang('stress.buttons.stop.label')"
            :confirm="System.lang('stress.buttons.stop.confirm')"
            :fn="stop"
        />
        <ActionButton
            v-else
            color="primary" 
            :icon="mdiPlay"
            :label="System.lang('stress.buttons.start.label')"
            :confirm="System.lang('stress.buttons.start.confirm')"
            :fn="start"
        />
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="running">
        <HeaderPanel
            :title="`${System.lang('stress.threads')} (${numThreads})`"
            :icon="mdiTableRow"
            color="success"
            class="mb-6"
        />
        <v-row>
            <v-col cols="1" v-for="thread in threads" :key="thread.id" class="text-center">
                <p class="grey--text text-caption mb-1">{{System.lang('stress.done')}}</p>
                <p class="success--text">{{thread.done}}</p>
                <p class="grey--text text-caption mb-1">{{System.lang('stress.errors')}}</p>
                <p class="error--text">{{thread.errors}}</p>
                <p class="grey--text text-caption mb-1">{{System.lang('stress.queue')}}</p>
                <p class="warning--text">{{thread.queue}}</p>
            </v-col>
        </v-row>
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="!running && totals.done">
        <HeaderPanel
            :title="System.lang('stress.totals')"
            :icon="mdiTableRow"
            color="success"
            class="mb-6"
        />
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.startAt')}} {{Dates.human(totals.start_at, 2)}}</p>
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.finishAt')}} {{Dates.human(totals.finish_at, 2)}}</p>
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.done')}} {{totals.done}}</p>
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.errors')}} {{totals.errors}}</p>
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.threads')}} {{totals.threads}}</p>
        <p class="text-caption grey--text mb-1">{{System.lang('stress.totalsForm.interval')}} {{totals.interval}}</p>
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-show="root">
        <HeaderPanel
            :title="System.lang('stress.chart')"
            :icon="mdiChartScatterPlot"
            color="success"
            class="mb-6"
        />
        <div id="chart" ref="chart" :style="{height: `${height}px`}"></div>
    </v-sheet>

</div>
</template>


<script>
import {
    mdiGaugeFull,
    mdiPlay,
    mdiStop,
    mdiTableRow,
    mdiChartScatterPlot,
} from '@mdi/js';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import sample from 'lodash/sample';
import axios from 'axios';

import System from '@/classes/System';
import Dates from '@/classes/Dates'
// import Interface from '@/classes/Interface'
// import Api from '@/services/api'
import Settings from '@/constants/settings'

import HeaderPanel from '@/components/system/HeaderPanel'
import ActionButton from '@/components/system/ActionButton'
import {Form} from '@/classes/Elements'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'StressView',
    components: {
        HeaderPanel,
        SimpleForm,
        ActionButton,
    },
    data () {
        return {
            // Icons
            mdiGaugeFull,
            mdiPlay,
            mdiStop,
            mdiTableRow,
            mdiChartScatterPlot,
            // Globals
            System,
            Dates,
            // Data
            terminalKeys: "0000000000000000\n0000000000000001",
            channelKeys: "0000000000000000",
            apiUrl: '',
            terminals: [],
            channels: [],
            numThreads: 1,
            interval: 1,
            running: false,
            threads: [],
            totals: {
                start_at: undefined,
                finish_at: undefined,
                done: 0,
                errors: 0,
                threads: 0,
                interval: 0,
            },
            root: undefined,
            height: 350,
            seriesBill: undefined,
            seriesPay: undefined,
            // Forms
            stressForm: new Form({
                url: [
                    (v) => !!v || System.lang('val.required'),
                ],
                terminals: [
                    (v) => !!v || System.lang('val.required'),
                ],
                channels: [
                    (v) => !!v || System.lang('val.required'),
                ],
            }),
        }
    },
    computed: {
    },
    methods: {
        start () {
            if (!this.stressForm.validate()) return false;

            this.createChart();
            this.running = true;
            this.totals.start_at = new Date();
            this.totals.threads = this.numThreads;
            this.totals.interval = this.interval;

            for (let i = 0; i < this.numThreads; i++) {
                let th = {
                    id: i,
                    timerId: undefined,
                    done: 0,
                    errors: 0,
                    queue: 0,
                };
                this.threads.push(th);
                this.startThread(th);
            }
        },
        stop () {
            this.running = false;
            this.totals.finish_at = new Date();

            this.threads.forEach(thread => {
                clearInterval(thread.timerId);
            });

            this.threads = [];
        },
        startThread (th) {
            th.timerId = setInterval(this.startSet, this.interval * 1000, th);
        },
        async startSet (th) {
            th.queue++;
            try {
                let bill = await this.sendCreateBill();
                await this.sendPayBill(bill);
                th.done++;
                this.totals.done++;
            } catch (error) {
                console.log('ERROR: ', error);
                th.errors++;
                this.totals.errors++;
            } finally {
                th.queue--;
            }
        },
        async sendCreateBill () {
            let token = sample(this.terminalKeys.split("\n"));
            let config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            let req_bill = {
                "operation": "sale",
                "amount": 5,
                "currency": "USD"
            };

            console.log('Create request: ', req_bill);
            let start = new Date();
            let rsp = await axios.post(`${this.apiUrl}/terminal/dynamic/create`, req_bill, config);
            let end = new Date();
            console.log('Create response: ', rsp);

            let dataItem = {
                when: end.getTime(),
                msec: end - start,
            };
            this.seriesBill.data.push(dataItem);

            return rsp.data;
        },
        async sendPayBill (bill) {
            let token = sample(this.channelKeys.split("\n"));
            let config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            let req_pay = {
                "qr": bill.qr.data,
                "amount": 5,
                "currency": "USD"
            };

            let start = new Date();
            let rsp = await axios.post(`${this.apiUrl}/paychannel/pay-bill`, req_pay, config);
            let end = new Date();

            let dataItem = {
                when: end.getTime(),
                msec: end - start,
            };
            this.seriesPay.data.push(dataItem);

            return rsp;
        },
        createChart () {
            if (this.root) {
                this.root.dispose();
                this.seriesBill = undefined;
                this.seriesPay = undefined;
            }

            let root = am5.Root.new(this.$refs.chart);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                maxTooltipDistance: 0,
                pinchZoomX: true,
            }));

            chart.set("cursor", am5xy.XYCursor.new(root, {}));
            // let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            // cursor.lineY.set("visible", false);

            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0,
                baseInterval: {
                    timeUnit: "millisecond",
                    count: 1,
                },
                renderer: am5xy.AxisRendererX.new(root, {
                    minorGridEnabled: true,
                    minGridDistance: 200,    
                    minorLabelsEnabled: true
                }),
                // tooltip: am5.Tooltip.new(root, {}),
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            }));

            let series1 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Terminal",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "msec",
                valueXField: "when",
                // tooltip: am5.Tooltip.new(root, {
                //     labelText: "{valueY} msec."
                // }),
                strokeOpacity: 0,
            }));

            series1.set("fill", am5.color(0x7986CB));

            series1.bullets.push(function () {
                let bulletCircle = am5.Circle.new(root, {
                    radius: 4,
                    fill: series1.get("fill"),
                    tooltipText: "[bold]Terminal:[/] {valueY} msec.",
                });
                return am5.Bullet.new(root, {
                    sprite: bulletCircle
                })
            });

            series1.strokes.template.setAll({
                visible: false,
            });

            let series2 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Paychannel",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "msec",
                valueXField: "when",
                // tooltip: am5.Tooltip.new(root, {
                //     labelText: "{valueY}"
                // }),
                strokeOpacity: 0,
            }));

            series2.set("fill", am5.color(0x689F38));

            series2.bullets.push(function () {
                let bulletCircle = am5.Circle.new(root, {
                    radius: 4,
                    fill: series2.get("fill"),
                    tooltipText: "[bold]Paychannel:[/] {valueY} msec.",                    
                });
                return am5.Bullet.new(root, {
                    sprite: bulletCircle
                })
            });

            series2.strokes.template.setAll({
                visible: false,
            });

            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));

            var legend = chart.children.push(am5.Legend.new(root, {
                x: am5.p50,
                centerX: am5.p50,
                y: am5.p0,
                dy: 40,
            }));
            legend.data.setAll(chart.series.values);

            series1.appear(100);
            series2.appear(100);
            chart.appear(100, 100);

            this.root = root;
            this.seriesBill = series1;
            this.seriesPay = series2;
        },
    },
    mounted () {
        System.setTitle('stress.stress');
        this.apiUrl = Settings.apiBaseUrl;
    },
    beforeDestroy() {
        if (this.running) {
            this.stop();
        }
        
        if (this.root) {
            this.root.dispose();
        }
    },
}
</script>


<style scoped>
</style>
