const state = () => ({
    data: false,
    langs: [],
    popup: {
        show: false,
        type: undefined,
        text: undefined,
        descr: undefined,
    },
    snack: {
        show: false,
        type: undefined,
        text: undefined,
    },
    message: {
        show: false,
        type: undefined,
        text: undefined,
    },
    debug: true,
})

const getters = {};

const mutations = {
    setData (state, data) {
        state.data = data;
    },
    setLangs (state, data) {
        state.langs = data;
    },
    popupOn (state, popup) {
        Object.assign(state.popup, popup);
        state.popup.show = true;
    },
    popupOff (state) {
        state.popup.show = false;
    },
    snackOn (state, snack) {
        Object.assign(state.snack, snack);
        state.snack.show = true;
    },
    snackOff (state) {
        state.snack.show = false;
    },
    messageOn (state, message) {
        Object.assign(state.message, message);
        state.message.show = true;
    },
    messageOff (state) {
        state.message.show = false;
    },
    debugOn (state) {
        state.debug = true;
    },
    debugOff (state) {
        state.debug = false;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }