class Dates {

    // Format dates function

    human (date, format = 1) {
        let dt = new Date(date);

        switch (format) {
            case 1: return dt.toLocaleDateString();  // Date
            case 2: return dt.toLocaleString();      // Date + Time
            case 3: return dt.toLocaleTimeString();  // Time
            default: return '';
        }
    }

    // Generate dates functions

    formatter (dt, format = 1) {
        // dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        switch (format) {
            case 1: return dt.toJSON().substring(0,10); // YYYY-MM-DD
            case 2: return dt.toJSON();                 // YYYY-MM-DDTHH:MM:SS.000Z
            default: return '';
        }
    }

    today (format = 1) {
        let dt = new Date();
        return this.formatter(dt, format);
    }

    todayStart (format = 2) {
        let dt = new Date();
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }

    todayEnd (format = 2) {
        let dt = new Date();
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }

    dayStart (date, format = 2) {
        let dt = new Date(date);
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }

    dayEnd (date, format = 2) {
        let dt = new Date(date);
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }

    daysAgoStart (days, format = 2) {
        let dt = new Date();
        dt.setDate(dt.getDate() - days);
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }

    daysAgoEnd (days, format = 2) {
        let dt = new Date();
        dt.setDate(dt.getDate() - days);
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }

    thisMonthStart (format = 2) {
        let dt = new Date();
        dt.setDate(1);
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }

    thisMonthEnd (format = 2) {
        let dt = new Date();
        dt.setFullYear(dt.getFullYear(), dt.getMonth() + 1, 1);
        dt.setDate(dt.getDate() - 1);
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }   

    prevMonthStart (format = 2) {
        let dt = new Date();
        dt.setDate(1);
        dt.setMonth(dt.getMonth() - 1);
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }
    
    prevMonthEnd (format = 2) {
        let dt = new Date();
        dt.setDate(0);
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }
   
    monthStart (date, format = 2) {
        const [year, month] = date.split('-');
        let dt = new Date(year, parseInt(month) - 1, 1);
        dt.setHours(0, 0, 0, 0);
        return this.formatter(dt, format);
    }
    
    monthEnd (date, format = 2) {
        const [year, month] = date.split('-');
        let dt = new Date(year, parseInt(month, 10), 0);
        dt.setHours(23, 59, 59, 999);
        return this.formatter(dt, format);
    }

    // Helper functions 

    diffDays(dateString1, dateString2) {
        const date1 = new Date(dateString1);
        const date2 = new Date(dateString2);
        const timeDiff = date2 - date1;
        const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
        return Math.abs(Math.round(daysDiff));
    }    
}

export default new Dates();