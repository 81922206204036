<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <InstSelector
            @selected="instId = $event; init()"
        />
    </v-sheet>

    <!-- Filters -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="instId">
        <HeaderPanel
            :title="System.lang('billlog.search')"
            :icon="mdiMagnify"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <v-expansion-panels v-model="openedPanel">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="d-flex align-center">
                        <v-icon left color="light-blue lighten-1">{{mdiTextBoxSearchOutline}}</v-icon>
                        <span class="text-button grey--text text--darken-1">{{System.lang('billlog.uuidSearch')}}</span>
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ActionForm 
                        @errors="searchUuidForm.errors = $event"
                        :label="System.lang('buttons.search')"
                        :hint="System.lang('buttons.search')"
                        :icon="mdiMagnify"
                        :showOk="false"
                        :showError="false"
                        :fn="() => searchUuid(true)"
                    >
                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    :label="System.lang('billlog.searchUuidForm.uuid')"
                                    :hint="System.lang('billlog.searchUuidForm.uuidHint')"
                                    v-model="searchUuidForm.values.uuid"
                                    :rules="searchUuidForm.rules.uuid"
                                    :error-messages="searchUuidForm.errors.uuid"
                                    @click="searchUuidForm.errors.uuid = []"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </ActionForm>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span class="d-flex align-center">
                        <v-icon left color="light-blue lighten-1">{{mdiTextBoxSearch}}</v-icon>
                        <span class="text-button grey--text text--darken-1">{{System.lang('billlog.advancedSearch')}}</span>
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <ActionForm 
                        @errors="searchAdvancedForm.errors = $event"
                        :label="System.lang('buttons.search')"
                        :hint="System.lang('buttons.search')"
                        :icon="mdiMagnify"
                        :showOk="false"
                        :showError="false"
                        :fn="() => searchAdvanced(true)"
                    >
                        <v-row dense>
                            <v-col cols="12">
                                <v-radio-group 
                                    v-model="searchAdvancedForm.values.type" 
                                    :label="System.lang('billlog.selectType')" 
                                    mandatory 
                                    :row="!System.isMobile()"
                                >
                                    <v-radio :label="System.lang('billlog.types.1')" :value="BILL_TYPE.DYNAMIC"></v-radio>
                                    <v-radio :label="System.lang('billlog.types.2')" :value="BILL_TYPE.STATIC"></v-radio>
                                    <v-radio :label="System.lang('billlog.types.3')" :value="BILL_TYPE.TIMER"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" sm="4">
                                <v-menu
                                    v-model="menuFromDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="searchAdvancedForm.values.from_date"
                                            :label="System.lang('billlog.searchAdvancedForm.date')"
                                            :prepend-icon="mdiCalendar"
                                            clearable
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="searchAdvancedForm.values.from_date"
                                        first-day-of-week="1"
                                        no-title
                                        scrollable
                                        :max="Dates.today()"
                                        @change="changeDate"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menuFromDate = false"
                                        >
                                            {{System.lang('buttons.ok')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-date-picker>
                                </v-menu>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-menu
                                            v-if="searchAdvancedForm.values.from_date"
                                            ref="menuFromTime"
                                            v-model="menuFromTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    style="max-width: 200px"
                                                    v-model="searchAdvancedForm.values.from_time"
                                                    :label="System.lang('billlog.searchAdvancedForm.fromTime')"
                                                    :prepend-icon="mdiClockTimeTwoOutline"
                                                    readonly
                                                    clearable
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-model="searchAdvancedForm.values.from_time"
                                                format="24hr"
                                                use-seconds
                                                @click:second="$refs.menuFromTime.save(searchAdvancedForm.values.from_time)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-if="searchAdvancedForm.values.from_date"
                                            ref="menuToTime"
                                            v-model="menuToTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    style="max-width: 200px"
                                                    v-model="searchAdvancedForm.values.to_time"
                                                    :label="System.lang('billlog.searchAdvancedForm.toTime')"
                                                    :prepend-icon="mdiClockOutline"
                                                    readonly
                                                    clearable
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-model="searchAdvancedForm.values.to_time"
                                                format="24hr"
                                                use-seconds
                                                @click:second="$refs.menuToTime.save(searchAdvancedForm.values.to_time)"
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-autocomplete v-if="merchants"
                                    :items="merchants"
                                    :label="System.lang('billlog.searchAdvancedForm.merchants')"
                                    v-model="searchAdvancedForm.values.merchant"
                                    item-value="id"
                                    item-text="code"
                                    clearable
                                    @change="changeMerchant"
                                >
                                    <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.code}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                                <v-autocomplete v-if="terminals"
                                    :items="terminals"
                                    :label="System.lang('billlog.searchAdvancedForm.terminals')"
                                    v-model="searchAdvancedForm.values.terminal"
                                    item-value="id"
                                    item-text="code"
                                    clearable
                                    hide-details
                                >
                                    <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.code}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                                <div class="text-center mt-3" v-if="loadingData">
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-row no-gutters>
                                    <v-col cols="6" class="pr-2">
                                        <v-select
                                            :items="statusItems"
                                            :label="System.lang('billlog.searchAdvancedForm.status')"
                                            v-model="searchAdvancedForm.values.status"
                                            :prepend-icon="mdiCheck"
                                            clearable
                                            multiple
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{item.text}}</span>
                                                <span v-if="index === 1" class="grey--text text-caption ml-2">{{`(+${searchAdvancedForm.values.status?.length - 1})`}}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            :items="currencies"
                                            :label="System.lang('billlog.searchAdvancedForm.currency')"
                                            v-model="searchAdvancedForm.values.currency_id"
                                            :prepend-icon="mdiCurrencyUsd"
                                            item-value="id"
                                            item-text="code"
                                            clearable
                                        >
                                            <template v-slot:item="{ item, attrs, on }">
                                                <v-list-item v-on="on" v-bind="attrs">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" class="pr-2">
                                        <v-text-field
                                            v-model="searchAdvancedForm.values.from_amount"
                                            :label="System.lang('billlog.searchAdvancedForm.fromAmount')"
                                            :rules="searchAdvancedForm.rules.from_amount"
                                            :error-messages="searchAdvancedForm.errors.from_amount"
                                            :prepend-icon="mdiCurrencyUsd"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="searchAdvancedForm.values.to_amount"
                                            :label="System.lang('billlog.searchAdvancedForm.toAmount')"
                                            :rules="searchAdvancedForm.rules.to_amount"
                                            :error-messages="searchAdvancedForm.errors.to_amount"
                                            :prepend-icon="mdiCurrencyUsd"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </ActionForm>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-sheet>


    <!-- Search results -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="instId && searchFn">
        <HeaderPanel
            :title="System.lang('billlog.results')"
            :icon="mdiTextSearch"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => searchFn(true)"
            />
        </HeaderPanel>
        <BillsTable
            :rows="rows"
            :loading="loading"
        />
        <div class="text-center mt-3">
            <ActionButton
                v-if="rows?.length"
                text
                color="primary" 
                :disabled="noMore"
                :label="(noMore ? System.lang('billlog.noMore') : System.lang('billlog.loadMore'))"
                :fn="() => searchFn(false)"
                :error="System.lang('messages.REQUEST_FAILED')"
            />
        </div>
    </v-sheet>

</div>
</template>


<script>
import {
    mdiRefresh,
    mdiMagnify,
    mdiTextSearch,
    mdiEye,
    mdiCalendar,
    mdiClockOutline,
    mdiClockTimeTwoOutline,
    mdiTextBoxSearchOutline,
    mdiTextBoxSearch,
    mdiCurrencyUsd,
    mdiCheck,
} from '@mdi/js';

import minBy from 'lodash/minBy'
import System from '@/classes/System';
import Interface from '@/classes/Interface'
import Dates from '@/classes/Dates'
import Api from '@/services/api'
import {Form} from '@/classes/Elements'
import {BILL_TYPE, BILL_STATUS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import InstSelector from '@/components/parts/InstSelector'
import ActionButton from '@/components/system/ActionButton'
import IconButton from '@/components/system/IconButton'
import ActionForm from '@/components/forms/ActionForm'
import BillsTable from '@/components/parts/BillsTable'

export default {
    name: 'BilllogView',
    components: {
        HeaderPanel,
        InstSelector,
        ActionButton,
        IconButton,
        ActionForm,
        BillsTable,
    },
    data () {
        return {
            // Icons
            mdiRefresh,
            mdiMagnify,
            mdiTextSearch,
            mdiEye,
            mdiCalendar,
            mdiClockOutline,
            mdiClockTimeTwoOutline,
            mdiTextBoxSearchOutline,
            mdiTextBoxSearch,
            mdiCurrencyUsd,
            mdiCheck,
            // Globals
            System,
            Dates,
            BILL_TYPE,
            BILL_STATUS,
            // Internal
            openedPanel: undefined,
            searchFn: undefined,
            loading: false,
            loadingData: false,
            noMore: false,
            menuFromDate: false,
            menuFromTime: false,
            menuToTime: false,
            limit: 100,
            lastId: undefined,
            // Data
            instId: undefined,
            rows: [],
            statusItems: [
                {value: BILL_STATUS.PENDING, text: System.lang('billlog.statuses.1')},
                {value: BILL_STATUS.PAID, text: System.lang('billlog.statuses.2')},
                {value: BILL_STATUS.COMPLETED, text: System.lang('billlog.statuses.3')},
                {value: BILL_STATUS.CANCELLED, text: System.lang('billlog.statuses.4')},
                {value: BILL_STATUS.EXPIRED, text: System.lang('billlog.statuses.5')},
            ],
            merchants: undefined,
            terminals: undefined,
            currencies: [],
            // Forms
            searchUuidForm: new Form({
                uuid: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(v) || System.lang('val.uuid'),
                ],
            }),
            searchAdvancedForm: new Form({
                type: [],
                status: [],
                merchant: [],
                terminal: [],
                from_date: [],
                from_time: [],
                to_time: [],
                from: [],
                to: [],
                currency_id: [],
                from_amount: [
                    (v) => (!v || /^\d+(\.\d+)?$/.test(v)) || System.lang('val.amount'),
                ],
                to_amount: [
                    (v) => (!v || /^\d+(\.\d+)?$/.test(v)) || System.lang('val.amount'),
                ],
            }),
            // Tables
            // Dialogs
        }
    },
    computed: {
    },
    methods: {
        init () {
            this.openedPanel = undefined;
            this.searchFn = undefined;
            this.lastId = undefined;
            this.merchants = undefined;
            this.terminals = undefined;
            this.searchUuidForm.reset();
            this.searchAdvancedForm.reset({type: BILL_TYPE.DYNAMIC});
            this.loadMerchants();
            this.loadCurrencies();
            // this.searchAdvanced();
        },
        async searchUuid (reset = true) {
            reset = true;
            this.searchFn = this.searchUuid;
            this.loading = true;

            if (reset) {
                this.lastId = undefined;
                this.rows = [];
            }

            return Api.post(`/support/${this.instId}/search/bills/uuid`, {
                uuid: this.searchUuidForm.values.uuid,
            })
                .then(data => {
                    this.rows.push(...data.rows);
                    return data.rows;
                })
                .catch(error => {
                    if (this.searchUuidForm.catch(error)) {
                        this.openedPanel = 0;
                    } else {
                        Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async searchAdvanced (reset = true) {
            this.searchFn = this.searchAdvanced;
            this.loading = true;

            if (reset) {
                this.lastId = undefined;
                this.rows = [];
            }

            if (this.searchAdvancedForm.values.from_date) {
                if (this.searchAdvancedForm.values.from_time) {
                    this.searchAdvancedForm.values.from = new Date(this.searchAdvancedForm.values.from_date+' '+this.searchAdvancedForm.values.from_time).toJSON();
                } else {
                    this.searchAdvancedForm.values.from = new Date(this.searchAdvancedForm.values.from_date+' 00:00:00').toJSON();
                }

                if (this.searchAdvancedForm.values.to_time) {
                    this.searchAdvancedForm.values.to = new Date(this.searchAdvancedForm.values.from_date+' '+this.searchAdvancedForm.values.to_time).toJSON();
                } else {
                    this.searchAdvancedForm.values.to = new Date(this.searchAdvancedForm.values.from_date+' 23:59:59').toJSON();
                }
            } else {
                this.searchAdvancedForm.values.from = undefined;
                this.searchAdvancedForm.values.to = undefined;
            }

            return Api.post(`/support/${this.instId}/search/bills/advanced`, {
                ...this.searchAdvancedForm.values,
                limit: this.limit,
                last_id: this.lastId,
            })
                .then(data => {
                    if (data.rows.length < this.limit) {
                        this.noMore = true;
                    } else {
                        this.noMore = false;
                    }
                    if (data.rows.length) {
                        this.lastId = minBy(data.rows, 'id').id;
                        this.rows.push(...data.rows);
                    }
                    return data.rows;
                })
                .catch(error => {
                    if (this.searchAdvancedForm.catch(error)) {
                        this.openedPanel = 1;
                    } else {
                        Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async changeMerchant () {
            this.terminals = undefined;
            this.searchAdvancedForm.values.terminal = undefined;

            if (this.searchAdvancedForm.values.merchant) {
                return this.loadTerminals(this.searchAdvancedForm.values.merchant);
            }
        },
        changeDate () {
            this.searchAdvancedForm.values.from_time = undefined;
            this.searchAdvancedForm.values.to_time = undefined;
        },
        async loadMerchants () {
            this.loadingData = true;

            return Api.get(`/support/${this.instId}/list/merchants`)
                .then(data => {
                    this.merchants = data.merchants;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        async loadTerminals (merchant_id) {
            this.loadingData = true;

            return Api.get(`/support/${this.instId}/list/terminals/${merchant_id}`)
                .then(data => {
                    this.terminals = data.terminals;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loadingData = false;
                });
        },
        async loadCurrencies () {
            return Api.get('/list/currencies').then(data => this.currencies = data);
        },
    },
    mounted () {
        System.setTitle('menu.bills');
        // this.init();
    },
}
</script>


<style scoped>
</style>
