<template>
<div>
    <v-form ref="form" @input="v => valid = v" @submit.prevent>
        <div class="mt-3">
            <slot></slot>
        </div>
        <div class="mt-5">
            <ActionButton
                :disabled="!valid"
                text
                color="primary" 
                :label="label || System.lang('buttons.save.label')"
                :icon="icon"
                :hint="hint || System.lang('buttons.save.hint')"
                :fn="save"
                :ok="false"
                :error="false"
                :confirm="confirm"
                :confirmColor="confirmColor"
                :confirmIcon="confirmIcon"
                :confirmHint="confirmHint"  
                :confirmYes="confirmYes"
                :confirmNo="confirmNo"
            />
            <slot name="actions"></slot>
        </div>
    </v-form>
</div>
</template>


<script>
import {
    mdiContentSaveOutline,
} from '@mdi/js';

import Interface from '@/classes/Interface'
import System from '@/classes/System'

import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'ActionForm',
    components: {
        ActionButton,
    },
    props: {
        label: {
            type: [String, Boolean],
            default: false,
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        icon: {
            type: [String, Boolean],
            default: mdiContentSaveOutline,
        },
        fn: {
            type: Function,
            required: true,
        },
        ok: {
            type: [String, Boolean],
            default: false,
        },
        showOk: {
            type: Boolean,
            default: true,
        },
        error: {
            type: [String, Boolean],
            default: false,
        },
        showError: {
            type: Boolean,
            default: true,
        },
        confirm: {
            type: [String, Boolean],
            default: false,
        },
        confirmColor: {
            type: String,
            default: 'primary',
        },
        confirmIcon: {
            type: [String, Boolean],
            default: undefined,
        },
        confirmHint: {
            type: [String, Boolean],
            default: false,
        },
        confirmYes: {
            type: [String, Boolean],
            default: false,
        },
        confirmNo: {
            type: [String, Boolean],
            default: false,
        },
    },
    data () {
        return {
            // Objects
            System,
            // Icons
            mdiContentSaveOutline,
            // Data
            valid: undefined,
        }
    },
    computed: {
    },
    methods: {
        save () {
            if (this.$refs.form.validate()) {
                this.$emit('errors', {});

                return Promise.resolve(this.fn())
                    .then(data => {
                        if (this.showOk) Interface.snackSuccess(this.ok || System.lang('messages.SAVE_OK'));
                        return data;
                    })
                    .catch(error => {
                        if (error.code == '422') {
                            this.$emit('errors', error.details || {});
                            if (this.showError) Interface.snackError(System.lang('messages.SAVE_ERROR'));
                        } else {
                            if (this.showError) Interface.popupError(this.error || System.lang('messages.SAVE_ERROR'), error.message);
                        }
                        throw error;
                    });
            }

            return false;
        },
    },
    mounted () {
        this.$emit('ref', this.$refs.form);
    },
}
</script>


<style scoped>
</style>
