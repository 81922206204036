<template>
<div>
    <v-menu open-on-hover left offset-y transition="scroll-y-transition" :close-on-content-click="true" close-delay="200">
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on"><v-icon>{{mdiAccountOutline}}</v-icon></v-btn>
        </template>

        <v-sheet tile>
            <v-list dense>
                <v-list-item link @click="chpass()">
                    <v-list-item-avatar size="20">
                        <v-icon>{{mdiKeyOutline}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{System.lang('interface.chpass')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="logout()">
                    <v-list-item-avatar size="20">
                        <v-icon>{{mdiExitToApp}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{System.lang('interface.logout')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-sheet>
    </v-menu>
    <ChpassDialog ref="chpassDialog" />
</div>
</template>


<script>
import {
    mdiAccountOutline,
    mdiKeyOutline,
    mdiExitToApp,
} from '@mdi/js';

import User from '@/classes/User'
import System from '@/classes/System'
import ChpassDialog from '@/components/parts/ChpassDialog'

export default {
    name: 'TopMenu',
    components: {
        ChpassDialog,
    },
    data () {
        return {
            // Objects
            System,
            // Icons
            mdiAccountOutline,
            mdiKeyOutline,
            mdiExitToApp,
        }
    },
    methods: {
        logout () {
            User.logout();
        },
        chpass () {
            this.$refs.chpassDialog.open();
        },
    },

}
</script>


<style scoped>
</style>
