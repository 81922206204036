<template>
<div>

    <!-- Search parameters -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('perfview.perfview')"
            :icon="mdiChartLine"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm 
            :label="System.lang('perfview.buttons.view.label')"
            :hint="System.lang('perfview.buttons.view.hint')"
            :icon="mdiChartLine"
            :showOk="false"
            :error="System.lang('messages.REQUEST_FAILED')"
            :fn="fetchStats"
            @errors="errors = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="4">
                    <v-menu
                        v-model="menuFromDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="perfForm.values.from_date"
                                :label="System.lang('perfview.perfForm.date')"
                                :prepend-icon="mdiCalendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="perfForm.rules.from_date"
                                :error-messages="perfForm.errors.from_date"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="perfForm.values.from_date"
                            first-day-of-week="1"
                            no-title
                            scrollable
                            :max="Dates.today()"
                            @change="changeDate"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menuFromDate = false"
                            >
                                {{System.lang('buttons.ok')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-date-picker>
                    </v-menu>
                    <v-row dense>
                        <v-col cols="6">
                            <v-menu
                                v-if="perfForm.values.from_date"
                                ref="menuFromTime"
                                v-model="menuFromTime"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        style="max-width: 200px"
                                        v-model="perfForm.values.from_time"
                                        :label="System.lang('perfview.perfForm.fromTime')"
                                        :prepend-icon="mdiClockOutline"
                                        readonly
                                        clearable
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="perfForm.values.from_time"
                                    format="24hr"
                                    use-seconds
                                    @click:second="$refs.menuFromTime.save(perfForm.values.from_time)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                v-if="perfForm.values.from_date"
                                ref="menuToTime"
                                v-model="menuToTime"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        style="max-width: 200px"
                                        v-model="perfForm.values.to_time"
                                        :label="System.lang('perfview.perfForm.toTime')"
                                        readonly
                                        clearable
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="perfForm.values.to_time"
                                    format="24hr"
                                    use-seconds
                                    @click:second="$refs.menuToTime.save(perfForm.values.to_time)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-select
                        :items="granulaItems"
                        :label="System.lang('perfview.perfForm.granula')"
                        v-model="perfForm.values.granula"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-select
                        :items="apiItems"
                        :label="System.lang('perfview.perfForm.api')"
                        v-model="perfForm.values.api"
                        clearable
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="API[item.value].color">{{API[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left :color="API[item.value].color">{{API[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                    <v-checkbox
                        v-model="stacked"
                        :label="System.lang('perfview.perfForm.stacked')"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-alert :icon="mdiAlert" type="error" v-if="perfForm.values.granula == 1">{{System.lang('perfview.perfAlert')}}</v-alert>
            <v-alert :icon="mdiAlert" type="warning" v-if="perfForm.values.granula == 2">{{System.lang('perfview.perfWarn')}}</v-alert>
        </ActionForm>
    </v-sheet>

    <!-- TPS chart -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-show="rootTps">
        <HeaderPanel
            :title="System.lang('perfview.chartTps')"
            :icon="mdiChartLine"
            color="success"
        />
        <v-row dense>
            <v-col cols="12" sm="4" offset-sm="8">
                <v-select
                    :items="routeItems"
                    :label="System.lang('perfview.routes')"
                    v-model="selectedRoute"
                    hide-details
                    @change="changeRoute"
                >
                    <template v-slot:item="{ item }">
                        {{(item == 'total' ? System.lang('perfview.allRoutes') : item)}}
                    </template>

                    <template v-slot:selection="{ item }">
                        {{(item == 'total' ? System.lang('perfview.allRoutes') : item)}}
                    </template>
                </v-select>
                <p class="text-right mb-0 mt-2 text-caption grey--text">{{System.lang('perfview.totalReqs')}} {{stats?.tot[selectedRoute].count}}</p>
            </v-col>
        </v-row>
        <div id="chartTps" ref="chartTps" :style="{height: `${350}px`}"></div>
    </v-sheet>

    <!-- Response time chart -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-show="rootRsp">
        <HeaderPanel
            :title="System.lang('perfview.chartRsp')"
            :icon="mdiChartLine"
            color="success"
        />
        <v-row dense>
            <v-col cols="12" sm="4" offset-sm="8">
                <v-select
                    :items="routeItems"
                    :label="System.lang('perfview.routes')"
                    v-model="selectedRoute"
                    hide-details
                    @change="changeRoute"
                >
                    <template v-slot:item="{ item }">
                        {{(item == 'total' ? System.lang('perfview.allRoutes') : item)}}
                    </template>

                    <template v-slot:selection="{ item }">
                        {{(item == 'total' ? System.lang('perfview.allRoutes') : item)}}
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <div id="chartRsp" ref="chartRsp" :style="{height: `${350}px`}"></div>
    </v-sheet>

</div>
</template>


<script>
import {
    mdiChartLine,
    mdiCalendar,
    mdiClockOutline,
    mdiAlert,
} from '@mdi/js';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import System from '@/classes/System'
import Dates from '@/classes/Dates'
import {Form} from '@/classes/Elements'
import Api from '@/services/api'
import {API} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import ActionForm from '@/components/forms/ActionForm'

export default {
    name: 'PerfView',
    components: {
        HeaderPanel,
        ActionForm,
    },
    data () {
        return {
            // Icons
            mdiChartLine,
            mdiCalendar,
            mdiClockOutline,
            mdiAlert,
            // Globals
            System,
            Dates,
            API,
            // Internal
            menuFromDate: false,
            menuFromTime: false,
            menuToTime: false,
            rootTps: undefined,
            rootRsp: undefined,
            seriesTps: undefined,
            seriesMin: undefined,
            seriesMax: undefined,
            seriesAvg: undefined,
            // Data
            stats: undefined,
            selectedRoute: undefined,
            stacked: false,
            apiItems: [
                {value: 1, text: System.lang('apilog.api.1')},
                {value: 2, text: System.lang('apilog.api.2')},
                {value: 3, text: System.lang('apilog.api.3')},
            ],
            granulaItems: [
                {value: 1, text: System.lang('perfview.granula.1')},
                {value: 2, text: System.lang('perfview.granula.2')},
                {value: 3, text: System.lang('perfview.granula.3')},
            ],
            routeItems: [],
            // Forms
            perfForm: new Form({
                from_date: [
                    (v) => !!v || System.lang('val.required'),
                ],
                from_time: [],
                to_time: [],
                api: [],
                from: [],
                to: [],
                granula: [
                    (v) => !!v || System.lang('val.required'),
                ],
            }),
        }
    },
    computed: {
        chartTimeUnit () {
            switch (this.perfForm.values.granula) {
                case 1: return 'second';
                case 2: return 'minute';
                case 3: return 'hour';
                default: return 'second';
            }
        },
    },
    methods: {
        async fetchStats () {
            if (this.perfForm.values.from_time) {
                this.perfForm.values.from = new Date(this.perfForm.values.from_date+' '+this.perfForm.values.from_time).toJSON();
            } else {
                this.perfForm.values.from = new Date(this.perfForm.values.from_date+' 00:00:00').toJSON();
            }

            if (this.perfForm.values.to_time) {
                this.perfForm.values.to = new Date(this.perfForm.values.from_date+' '+this.perfForm.values.to_time).toJSON();
            } else {
                this.perfForm.values.to = new Date(this.perfForm.values.from_date+' 23:59:59').toJSON();
            }

            return Api.post('/super/monitor/perf', this.perfForm.values)
                .then(data => {
                    this.stats = data;
                    this.routeItems = data.routes;
                    this.selectedRoute = this.routeItems[0];
                    this.createChartTps();
                    this.createChartRsp();
                    return data;
                });
        },
        changeDate () {
            this.perfForm.values.from_time = undefined;
            this.perfForm.values.to_time = undefined;
        },
        createChartTps () {
            if (this.rootTps) {
                this.rootTps.dispose();
            }

            let root = am5.Root.new(this.$refs.chartTps);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                maxTooltipDistance: 0,
                pinchZoomX: true,
            }));

            chart.set("cursor", am5xy.XYCursor.new(root, {}));

            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0,
                baseInterval: {
                    timeUnit: this.chartTimeUnit,
                    count: 1,
                },
                min: new Date(this.perfForm.values.from).getTime(),
                max: new Date(this.perfForm.values.to).getTime(),
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                maxPrecision: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: 'TPS',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "count",
                valueXField: "when",
                strokeOpacity: 0,
                fill: am5.color(0x1E88E5),
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }),
            }));

            series.data.setAll(this.stats.tps[this.selectedRoute]);
            series.appear();

            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));

            chart.appear(1000, 100);

            this.rootTps = root;
            this.seriesTps = series;
        },
        createChartRsp () {
            if (this.rootRsp) {
                this.rootRsp.dispose();
            }

            let root = am5.Root.new(this.$refs.chartRsp);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                maxTooltipDistance: 0,
                pinchZoomX: true,
            }));

            chart.set("cursor", am5xy.XYCursor.new(root, {}));

            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0,
                baseInterval: {
                    timeUnit: this.chartTimeUnit,
                    count: 1,
                },
                min: new Date(this.perfForm.values.from).getTime(),
                max: new Date(this.perfForm.values.to).getTime(),
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                maxPrecision: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));

            let seriesMin = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: 'Min',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "duration_min",
                valueXField: "when",
                strokeOpacity: 0,
                fill: am5.color(0x4CAF50),
                stacked: this.stacked,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }),
            }));
            
            let seriesAvg = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: 'Avg',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "duration_avg",
                valueXField: "when",
                strokeOpacity: 0,
                fill: am5.color(0x1E88E5),
                stacked: this.stacked,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }),
            }));

            let seriesMax = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: 'Max',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "duration_max",
                valueXField: "when",
                strokeOpacity: 0,
                fill: am5.color(0xD50000),
                stacked: this.stacked,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }),
            }));

            seriesMin.data.setAll(this.stats.tps[this.selectedRoute]);
            seriesAvg.data.setAll(this.stats.tps[this.selectedRoute]);
            seriesMax.data.setAll(this.stats.tps[this.selectedRoute]);
            
            let legend = chart.children.push(am5.Legend.new(root, {
                x: am5.p50,
                centerX: am5.p50,
                y: am5.p0,
                dy: 40,
            }));

            legend.data.push(seriesMin);
            legend.data.push(seriesAvg);
            legend.data.push(seriesMax);

            seriesMin.appear();
            seriesAvg.appear();
            seriesMax.appear();

            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));

            chart.appear(1000, 100);

            this.rootRsp = root;
            this.seriesMin = seriesMin;
            this.seriesMax = seriesMax;
            this.seriesAvg = seriesAvg;
        },
        changeRoute () {
            if (this.selectedRoute) {
                this.seriesTps.data.setAll(this.stats.tps[this.selectedRoute]); 
                this.seriesMin.data.setAll(this.stats.tps[this.selectedRoute]); 
                this.seriesAvg.data.setAll(this.stats.tps[this.selectedRoute]); 
                this.seriesMax.data.setAll(this.stats.tps[this.selectedRoute]); 
            }
        },
    },
    mounted () {
        System.setTitle('perfview.perfview');
        this.perfForm.values.from_date = Dates.today();
        this.perfForm.values.granula = 3;
    },
    beforeDestroy() {
        if (this.rootTps) {
            this.rootTps.dispose();
        }
        if (this.rootRsp) {
            this.rootRsp.dispose();
        }
    },
}
</script>


<style scoped>
</style>
