<template>
    <v-data-table
        :ref="table.name"
        class="data-table"
        :headers="table.headers"
        :items="table.data"
        :loading="table.loading"
        :footer-props="{'items-per-page-options': TABLES_PP}"
        :items-per-page="items_pp"
        :mobile-breakpoint="mb"
        :search="search"
        v-on="$listeners"
        v-bind="$attrs"
        @update:items-per-page="System.saveSetting('tables.' +  table.name + '.items_pp', $event)"
    >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="{item}">
            <slot :name="name" v-bind:item="item" />
        </template>
    </v-data-table>
</template>


<script>
// import {
//     mdiExitToApp,
// } from '@mdi/js';

import { TABLES_PP } from "@/constants/interface";
import System from '@/classes/System'

export default {
    name: 'DataTable',
    props: {
        table: {
            type: Object,
            required: true,
        },
        mb: {
            type: Number,
            required: false,
            default: 0,
        },
        search: {
            type: String,
            required: false,
        },
    },
    data () {
        return {
            // Globals
            TABLES_PP,
            System,
            // Icons
            // mdiExitToApp,
            // Data
            items_pp: undefined,
        }
    },
    methods: {
    },
    mounted () {
        this.items_pp = System.loadSetting('tables.' +  this.table.name + '.items_pp', 10);
    },

}
</script>


<style scoped>
.row-pointer >>> tbody tr:not(.default-pointer) :hover {
    cursor: pointer;
}

.data-table >>> table thead tr th {
    white-space: nowrap;
}

* >>> .no-wrap {
    white-space: nowrap;
}
</style>
