import BilllogView from '@/components/views/support/BilllogView'
import MerchantsView from '@/components/views/support/MerchantsView'
import ReportsView from '@/components/views/support/ReportsView'

let prefix = '/support';

const routes = [

    // Search Bills
    {
        path: prefix + '/bills',
        name: 'support-bills',
        component: BilllogView,
    },

    // Merchants
    {
        path: prefix + '/merchants',
        name: 'support-merchants',
        component: MerchantsView,
    },

    // Reports
    {
        path: prefix + '/reports',
        name: 'support-reports',
        component: ReportsView,
    },

]

export default routes
