<template>
    <v-dialog v-model="show" persistent scrollable max-width="550">
        <v-card>
            <v-card-title class="text-center mt-5" v-if="icon">
                <v-spacer></v-spacer>
                <v-icon x-large :color="color">{{icon}}</v-icon>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="text-center mt-5 pb-0 text-body-1">
                <div v-html="text"></div>
            </v-card-text>
            <v-card-text class="text-center mt-2 pb-1 text-caption grey--text" v-if="hint">
                <div v-html="hint"></div>
            </v-card-text>
            <v-card-actions class="mt-4">
                <v-spacer></v-spacer>
                <v-btn :color="color" text @click="$emit('yes')" min-width="150">
                    {{buttonYes || System.lang('buttons.ok')}}
                </v-btn>
                <v-btn color="grey" text @click="$emit('no')" min-width="150">
                    {{buttonNo || System.lang('buttons.cancel')}}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import {
    mdiHelpCircleOutline,
} from '@mdi/js';

import System from '@/classes/System'

export default {
    name: 'PopupConfirm',
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
        icon: {
            type: [String, Boolean],
            default: mdiHelpCircleOutline,
        },
        buttonYes: {
            type: [String, Boolean],
            default: false,
        },
        buttonNo: {
            type: [String, Boolean],
            default: false,
        },
    },
    data () {
        return {
            // Objects
            System,
            // Icons
            mdiHelpCircleOutline,
            // Data
            show: false,
        }
    },
    computed: {
    },
    methods: {
    },
    watch: {
        open (v) {
            this.show = v;
        },
    },
}
</script>


<style scoped>
</style>
