<template>
    <!-- Main Menu -->
    <v-list dense>
        <template v-for="item in menu">
            <!-- Header -->
            <v-subheader v-if="item.type === MT.HEAD" :key="item.id">{{System.lang(item.title)}}</v-subheader>
            <!-- Divider -->
            <v-divider v-else-if="item.type === MT.SEPA" :key="item.id"></v-divider>
            <!-- Menu link -->
            <v-list-item v-else-if="item.type === MT.LINK" :key="item.id" :to="item.to" color="primary" link>
                <v-list-item-icon v-if="item.icon"><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
                <v-list-item-content :class="item.icon?'':'pl-14'">
                    <v-list-item-title>{{System.lang(item.title)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- Group -->
            <v-list-group v-else-if="item.type === MT.GRP" :prepend-icon="item.icon" :key="item.id" :value="item.opened">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>{{System.lang(item.title)}}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <template v-for="grpitem in item.items">
                    <!-- Group link -->
                    <v-list-item v-if="grpitem.type === MT.LINK" :key="grpitem.id" :to="grpitem.to" link>
                        <v-list-item-icon v-if="grpitem.icon"><v-icon>{{grpitem.icon}}</v-icon></v-list-item-icon>
                        <v-list-item-content :class="grpitem.icon?'':'pl-14'">
                            <v-list-item-title>{{System.lang(grpitem.title)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- Subgroup -->
                    <v-list-group v-else-if="grpitem.type === MT.GRP" no-action sub-group :key="grpitem.id" :value="grpitem.opened">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{System.lang(grpitem.title)}}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <!-- Subgroup link -->
                        <template v-for="sgrpitem in grpitem.items">
                            <v-list-item v-if="sgrpitem.type === MT.LINK" :key="sgrpitem.id" :to="sgrpitem.to" link>
                                <v-list-item-title>{{System.lang(sgrpitem.title)}}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list-group>
                </template>
            </v-list-group>
        </template>
    </v-list>
</template>


<script>
import {mapState} from 'vuex'

import {MT, MAIN_MENU_SUPER, MAIN_MENU_ADMIN} from '@/constants/interface';
import {USER_ROLE} from '@/constants/system';

import System from '@/classes/System'

export default {
    name: 'MainMenu',
    data () {
        return {
            MT,
            System,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.profile,
        }),
        menu () {
            switch (this.user.role) {
                case USER_ROLE.SUPER: return MAIN_MENU_SUPER;
                case USER_ROLE.ADMIN: return MAIN_MENU_ADMIN;
                default: return [];
            }
        }
    },
}
</script>


<style scoped>
</style>
