<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('currencies.currencies')"
            :icon="mdiCurrencyUsd"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiPlus"
                :hint="System.lang('currencies.addNewHint')"
                :fn="() => {newDialog.open()}"
            />
            <IconButton
                v-if="currenciesTable.hasData()"
                :color="showSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('currencies.searchHint')"
                :fn="() => {showSearch = !showSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <DataTable 
            :table="currenciesTable" 
            :search="search"
            :no-data-text="System.lang('currencies.table.noData')" 
        >
            <template v-slot:[`body.prepend`] v-if="currenciesTable.hasData() && showSearch">
                <tr>
                    <td colspan="4" class="px-1">
                        <v-text-field
                            v-model="search"
                            :label="System.lang('currencies.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-currency', params: {id: item.id}}"
                />
            </template>
        </DataTable>

    </v-sheet>

    <SimpleDialog
        :dialog="newDialog"
        :title="System.lang('currencies.newCurrency')"
        :icon="mdiPlus"
        @close="newDialog.close()"
    >
        <SimpleForm 
            @ref="newForm.ref = $event"
            @input="newForm.valid = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="3">
                    <v-text-field
                        :label="System.lang('currencies.newForm.code')"
                        :hint="System.lang('currencies.newForm.codeHint')"
                        counter="25"
                        v-model="newForm.values.code"
                        :rules="newForm.rules.code"
                        :error-messages="newForm.errors.code"
                        @input="newForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('currencies.newForm.name')"
                        :hint="System.lang('currencies.newForm.nameHint')"
                        counter="50"
                        v-model="newForm.values.name"
                        :rules="newForm.rules.name"
                        :error-messages="newForm.errors.name"
                        @input="newForm.resetError('name')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                    <v-select
                        :items="decimalsItems"
                        :label="System.lang('currencies.newForm.decimals')"
                        v-model="newForm.values.decimals"
                    ></v-select>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addCurrency"
                :disabled="!newForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiMagnify,
    mdiRefresh,
    mdiCurrencyUsd,
    mdiPlus,
    mdiCogOutline,
    mdiStorefrontOutline,
    mdiPrinterPosOutline,
    mdiCreditCardOutline,
} from '@mdi/js';

// import { mapState } from 'vuex'

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import IconLink from '@/components/system/IconLink'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'CurrenciesView',
    components: {
        HeaderPanel,
        IconButton,
        IconLink,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiMagnify,
            mdiRefresh,
            mdiCurrencyUsd,
            mdiPlus,
            mdiCogOutline,
            mdiStorefrontOutline,
            mdiPrinterPosOutline,
            mdiCreditCardOutline,
            // Globals
            System,
            // Internal
            showSearch: false,
            search: '',
            // Data
            decimalsItems: [0,1,2,3,4,5,6,7,8],
            // Forms
            newForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                decimals: [
                    (v) => !!v || System.lang('val.required'),
                ],
            }),
            // Tables
            currenciesTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: false,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('currencies.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('currencies.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('currencies.table.decimals'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'decimals',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('currencies.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'currencies-table', this.fetchCurrencies),
            // Dialogs
            newDialog: new Dialog(() => {this.newForm.reset({decimals: 2})}),
        }
    },
    computed: {
    },
    methods: {
        init () {
            return this.currenciesTable.load();    
        },
        async fetchCurrencies () {
            return Api.get('/super/currencies')
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addCurrency () {
            if (!this.newForm.validate()) return false;

            return Api.post('/super/currencies', this.newForm.values)
                .then(data => {
                    this.newDialog.close();
                    this.currenciesTable.add(data);
                    System.redirectTo('admin-currency', {params: {id: data.id}});
                    Interface.snackSuccess(System.lang('currencies.messages.CURRENCY_ADDED'));
                })
                .catch(error => {
                    if (!this.newForm.catch(error)) {
                        Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                    }
                });
        },
    },
    mounted () {
        System.setTitle('currencies.currencies');
        this.init();
    },
}
</script>


<style scoped>
</style>
