<template>
    <v-data-table 
        :items="rows"
        :headers="headers"
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
        :no-data-text="System.lang('cblog.noResults')" 
        dense
        mobile-breakpoint="0"
    >
        <template v-slot:[`item.created_at`]="{item}">
            {{Dates.human(item.created_at, 2)}}
        </template>

        <template v-slot:[`item.bill_type`]="{item}">
            {{System.lang(`billlog.types.${item.bill_type}`)}}
        </template>

        <template v-slot:[`item.type`]="{item}">
            {{item.type.code}}
        </template>

        <template v-slot:[`item.merchant`]="{item}">
            {{item.merchant.code}}
        </template>

        <template v-slot:[`item.terminal`]="{item}">
            {{item.terminal.code}}
        </template>

        <template v-slot:[`item.amount`]="{item}">
            <span v-if="item.bill_type == BILL_TYPE.DYNAMIC">{{item.amount_initial}}{{(item.amount_final ? ` / ${item.amount_final}` : '')}} {{item.currency.code}}</span>
            <span v-if="item.bill_type == BILL_TYPE.STATIC">{{item.amount_paid}}{{(item.amount_final ? ` / ${item.amount_final}` : '')}} {{item.currency.code}}</span>
            <span v-if="item.bill_type == BILL_TYPE.TIMER">{{item.amount_calc}}{{(item.amount_final ? ` / ${item.amount_final}` : '')}} {{item.currency.code}}</span>
        </template>

        <template v-slot:[`item.status`]="{item}">
            <span :style="{color: BILL_STATUSES[item.status].color}">{{System.lang(`billlog.statuses.${item.status}`)}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <BillDialog ref="billDialog" />
            <IconButton
                :icon="mdiEye"
                color="primary"
                :hint="System.lang('buttons.view')"
                :fn="() => $refs.billDialog.show(item.bill_type, item.id)"
            />
        </template>

        <template v-slot:[`footer`]>
            <v-divider></v-divider>
        </template>
    </v-data-table>
</template>


<script>
import {
    mdiEye,
} from '@mdi/js';

import System from '@/classes/System'
import Dates from '@/classes/Dates'
import {BILL_TYPE, BILL_STATUSES} from '@/constants/system'

// import IconHint from '@/components/system/IconHint'
import IconButton from '@/components/system/IconButton'

export default {
    name: 'BillsTable',
    components: {
        // IconHint,
        IconButton,
        BillDialog: () => import('./BillDialog.vue'),
    },
    props: {
        rows: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            Dates,
            BILL_TYPE,
            BILL_STATUSES,
            // Icons
            mdiEye,
            // Internal
            // Data
            // Tables
            headers: [
                {
                    text: System.lang('billlog.when'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'created_at',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.type'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'bill_type',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.operation'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'type',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.merchant'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'merchant',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.terminal'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'terminal',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.amount'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'amount',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.status'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'status',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('billlog.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    cellClass: "no-wrap",
                },
            ],
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
    },

}
</script>


<style scoped>
.data-table >>> table thead tr th {
    white-space: nowrap;
}

* >>> .no-wrap {
    white-space: nowrap;
}
</style>
