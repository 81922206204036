<template>
    <v-data-table 
        :items="rows"
        :headers="headers"
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
        :no-data-text="System.lang('apilog.noResults')" 
        dense
        mobile-breakpoint="0"
    >
        <template v-slot:[`item.start_at`]="{item}">
            {{Dates.human(item.start_at, 2)}}
        </template>

        <template v-slot:[`item.http_status`]="{item}">
            <span class="success--text" v-if="item.http_status == 200">{{item.http_status}}</span>
            <span class="error--text" v-else>{{item.http_status}}</span>
        </template>

        <template v-slot:[`item.api`]="{item}">
            <IconHint
                :icon="API[item.api].icon"
                :hint="System.lang(`apilog.api.${item.api}`)"
                :color="API[item.api].color"
            />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <ApiLogDialog ref="apiDialog" />
            <IconButton
                :icon="mdiEye"
                color="primary"
                :hint="System.lang('buttons.view')"
                :fn="() => $refs.apiDialog.show(item.id)"
            />
        </template>

        <template v-slot:[`footer`]>
            <v-divider></v-divider>
        </template>
    </v-data-table>
</template>


<script>
import {
    mdiEye,
} from '@mdi/js';

import System from '@/classes/System'
import Dates from '@/classes/Dates'
import {API} from '@/constants/system'

import IconHint from '@/components/system/IconHint'
import IconButton from '@/components/system/IconButton'
import ApiLogDialog from '@/components/parts/ApiLogDialog'

export default {
    name: 'ApiLogTable',
    components: {
        IconHint,
        IconButton,
        ApiLogDialog,
    },
    props: {
        rows: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            Dates,
            API,
            // Icons
            mdiEye,
            // Internal
            // Data
            // Tables
            headers: [
                {
                    text: System.lang('apilog.when'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'start_at',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('apilog.msec'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'duration',
                    cellClass: "no-wrap",
                },
                {
                    text: 'API',
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'api',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('apilog.route'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'route',
                    cellClass: "no-wrap",
                },
                {
                    text: 'IP',
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'ip',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('apilog.httpStatus'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'http_status',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('apilog.rejectCode'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'reject_code',
                    cellClass: "no-wrap error--text",
                },
                {
                    text: System.lang('apilog.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    cellClass: "no-wrap",
                },
            ],
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
    },

}
</script>


<style scoped>
.data-table >>> table thead tr th {
    white-space: nowrap;
}

* >>> .no-wrap {
    white-space: nowrap;
}
</style>
