<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('merchant.merchant')"
            :icon="mdiStorefrontOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiBackburger"
                :hint="System.lang('merchant.backHint')"
                :fn="() => {System.redirectTo('admin-merchants')}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="merchant" 
            :fn="updateMerchant"
            @errors="merchantForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('merchant.merchantForm.code')"
                        :hint="System.lang('merchant.merchantForm.codeHint')"
                        counter="25"
                        v-model="merchant.code"
                        :rules="merchantForm.rules.code"
                        :error-messages="merchantForm.errors.code"
                        @click="merchantForm.errors.code = []"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('merchant.merchantForm.name')"
                        :hint="System.lang('merchant.merchantForm.nameHint')"
                        counter="50"
                        v-model="merchant.name"
                        :rules="merchantForm.rules.name"
                        :error-messages="merchantForm.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('merchant.merchantForm.status')"
                        :hint="System.lang('merchant.merchantForm.statusHint')"
                        v-model="merchant.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == MERCHANT_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <div class="pt-3">
                <a href="#" @click.prevent="showMore = !showMore" class="text-caption">{{(showMore ? System.lang('interface.less') : System.lang('interface.more'))}}</a>
            </div>
            <v-expand-transition>
                <div v-show="showMore" class="pt-3">
                    <v-row dense>
                        <v-col cols="6" sm="4">
                            <v-text-field
                                :label="System.lang('merchant.merchantForm.regno')"
                                :hint="System.lang('merchant.merchantForm.regnoHint')"
                                counter="50"
                                v-model="merchant.reg_no"
                                :rules="merchantForm.rules.reg_no"
                                :error-messages="merchantForm.errors.reg_no"
                                @click="merchantForm.errors.reg_no = []"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-text-field
                                :label="System.lang('merchant.merchantForm.vatno')"
                                :hint="System.lang('merchant.merchantForm.vatnoHint')"
                                counter="50"
                                v-model="merchant.vat_no"
                                :rules="merchantForm.rules.vat_no"
                                :error-messages="merchantForm.errors.vat_no"
                                @click="merchantForm.errors.vat_no = []"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('merchant.merchantForm.address')"
                                :hint="System.lang('merchant.merchantForm.addressHint')"
                                counter="250"
                                rows="4"
                                v-model="merchant.address"
                                :rules="merchantForm.rules.address"
                                :error-messages="merchantForm.errors.address"
                                @click="merchantForm.errors.address = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('merchant.merchantForm.publicContact')"
                                :hint="System.lang('merchant.merchantForm.publicContactHint')"
                                counter="250"
                                rows="4"
                                v-model="merchant.public_contact"
                                :rules="merchantForm.rules.public_contact"
                                :error-messages="merchantForm.errors.public_contact"
                                @click="merchantForm.errors.public_contact = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('merchant.merchantForm.adminContact')"
                                :hint="System.lang('merchant.merchantForm.adminContactHint')"
                                counter="250"
                                rows="4"
                                v-model="merchant.admin_contact"
                                :rules="merchantForm.rules.admin_contact"
                                :error-messages="merchantForm.errors.admin_contact"
                                @click="merchantForm.errors.admin_contact = []"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-expand-transition>

        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>

    <!-- Currencies -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.currencies')"
            :icon="mdiCurrencyUsd"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateCurrencies"
            @errors="currenciesForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-autocomplete
                        :items="currencies"
                        :label="System.lang('merchant.currenciesForm.currencies')"
                        v-model="currenciesForm.values.currencies"
                        item-value="id"
                        item-text="name"
                        multiple
                        chips
                        :rules="currenciesForm.rules.currencies"
                        :error-messages="currenciesForm.errors.currencies"
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Terminals -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.terminals')"
            :icon="mdiPrinterPosOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiPrinterPosPlusOutline"
                :hint="System.lang('merchant.addTerminal')"
                :fn="() => {newTerminalDialog.open()}"
            />
            <IconButton
                v-if="terminalsTable.hasData()"
                :color="showTerminalSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('merchant.searchTerminals')"
                :fn="() => {showTerminalSearch = !showTerminalSearch}"
            />
        </HeaderPanel>
        <DataTable 
            :table="terminalsTable" 
            :search="terminalSearch"
            :no-data-text="System.lang('merchant.termtable.noTerminals')" 
        >
            <template v-slot:[`body.prepend`] v-if="terminalsTable.hasData() && showTerminalSearch">
                <tr>
                    <td colspan="4" class="px-1">
                        <v-text-field
                            v-model="terminalSearch"
                            :label="System.lang('merchant.termtable.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="terminalSearchStatus"
                            :items="terminalStatusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.driver`]="{ item }">
                <span class="grey--text">{{ item.driver.name }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == TERMINAL_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('terminal.status.' + item['status'])"
                    :color="item['status'] == TERMINAL_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-terminal', params: {id: item.id}}"
                />
            </template>
        </DataTable>
    </v-sheet>


    <!-- Dynamic Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.dynamicBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiTextBoxPlusOutline"
                :hint="System.lang('merchant.addNewDynType')"
                :fn="() => {newDynamicDialog.open()}"
            />
            <IconButton
                v-if="dynamicTypesTable.hasData()"
                :color="showDynamicTypeSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('merchant.searchDynTypes')"
                :fn="() => {showDynamicTypeSearch = !showDynamicTypeSearch}"
            />
        </HeaderPanel>
        <DataTable 
            :table="dynamicTypesTable" 
            :search="dynamicTypeSearch"
            :no-data-text="System.lang('merchant.dbtable.noBills')" 
        >
            <template v-slot:[`body.prepend`] v-if="dynamicTypesTable.hasData() && showDynamicTypeSearch">
                <tr>
                    <td colspan="4" class="px-1">
                        <v-text-field
                            v-model="dynamicTypeSearch"
                            :label="System.lang('merchant.dbtable.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="dynamicTypeSearchStatus"
                            :items="dynamicTypeStatusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.driver`]="{ item }">
                <span class="grey--text">{{ item.driver.name }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == DYNAMIC_TYPE_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('dynamicType.status.' + item['status'])"
                    :color="item['status'] == DYNAMIC_TYPE_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-dynamic-type', params: {id: item.id}}"
                />
            </template>
        </DataTable>
    </v-sheet>

    <!-- Static Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.statBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiTextBoxPlusOutline"
                :hint="System.lang('merchant.addNewStatType')"
                :fn="() => {newStaticDialog.open()}"
            />
            <IconButton
                v-if="staticTypesTable.hasData()"
                :color="showStaticTypeSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('merchant.searchStatTypes')"
                :fn="() => {showStaticTypeSearch = !showStaticTypeSearch}"
            />
        </HeaderPanel>
        <DataTable 
            :table="staticTypesTable" 
            :search="staticTypeSearch"
            :no-data-text="System.lang('merchant.sbtable.noBills')" 
        >
            <template v-slot:[`body.prepend`] v-if="staticTypesTable.hasData() && showStaticTypeSearch">
                <tr>
                    <td colspan="5" class="px-1">
                        <v-text-field
                            v-model="staticTypeSearch"
                            :label="System.lang('merchant.sbtable.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="staticTypeSearchStatus"
                            :items="staticTypeStatusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.driver`]="{ item }">
                <span class="grey--text">{{ item.driver.name }}</span>
            </template>

            <template v-slot:[`item.currency`]="{ item }">
                <span class="grey--text">{{ item.currency.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == STATIC_TYPE_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('staticType.status.' + item['status'])"
                    :color="item['status'] == STATIC_TYPE_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-static-type', params: {id: item.id}}"
                />
            </template>
        </DataTable>
    </v-sheet>

    <!-- Timer Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.timerBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiTextBoxPlusOutline"
                :hint="System.lang('merchant.addNewTimerType')"
                :fn="() => {newTimerDialog.open()}"
            />
            <IconButton
                v-if="timerTypesTable.hasData()"
                :color="showTimerTypeSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('merchant.searchDynTypes')"
                :fn="() => {showTimerTypeSearch = !showTimerTypeSearch}"
            />
        </HeaderPanel>
        <DataTable 
            :table="timerTypesTable" 
            :search="timerTypeSearch"
            :no-data-text="System.lang('merchant.tbtable.noBills')" 
        >
            <template v-slot:[`body.prepend`] v-if="timerTypesTable.hasData() && showTimerTypeSearch">
                <tr>
                    <td colspan="5" class="px-1">
                        <v-text-field
                            v-model="timerTypeSearch"
                            :label="System.lang('merchant.tbtable.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="timerTypeSearchStatus"
                            :items="timerTypeStatusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.driver`]="{ item }">
                <span class="grey--text">{{ item.driver.name }}</span>
            </template>

            <template v-slot:[`item.currency`]="{ item }">
                <span class="grey--text">{{ item.currency.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == TIMER_TYPE_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('timerType.status.' + item['status'])"
                    :color="item['status'] == TIMER_TYPE_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-timer-type', params: {id: item.id}}"
                />
            </template>
        </DataTable>
    </v-sheet>

    <!-- Delete  -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="merchant">
        <HeaderPanel
            :title="System.lang('merchant.delete')"
            :hint="System.lang('merchant.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('merchant.delete')"
            :ok="System.lang('merchant.messages.MERCHANT_DELETED')"
            :confirm="System.lang('merchant.confirmDelete')"
            :confirmHint="System.lang('merchant.confirmDeleteHint')"
            :fn="deleteMerchant"
        />
    </v-sheet>


    <!-- Add Terminal Dialog -->
    <SimpleDialog
        :dialog="newTerminalDialog"
        :title="System.lang('merchant.newTerminal')"
        :icon="mdiPrinterPosPlusOutline"
        @close="newTerminalDialog.close()"
    >
        <SimpleForm 
            @ref="newTerminalForm.ref = $event"
            @input="newTerminalForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('merchant.newTerminalForm.code')"
                        :hint="System.lang('merchant.newTerminalForm.codeHint')"
                        counter="25"
                        v-model="newTerminalForm.values.code"
                        :rules="newTerminalForm.rules.code"
                        :error-messages="newTerminalForm.errors.code"
                        @input="newTerminalForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('merchant.newTerminalForm.name')"
                        :hint="System.lang('merchant.newTerminalForm.nameHint')"
                        counter="50"
                        v-model="newTerminalForm.values.name"
                        :rules="newTerminalForm.rules.name"
                        :error-messages="newTerminalForm.errors.name"
                        @input="newTerminalForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addTerminal"
                :disabled="!newTerminalForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newTerminalDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- Add Dynamic Bill Dialog -->
    <SimpleDialog
        :dialog="newDynamicDialog"
        :title="System.lang('merchant.newDynamic')"
        :icon="mdiTextBoxPlusOutline"
        @close="newDynamicDialog.close()"
    >
        <SimpleForm 
            @ref="newDynamicForm.ref = $event"
            @input="newDynamicForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('merchant.newDynamicForm.code')"
                        :hint="System.lang('merchant.newDynamicForm.codeHint')"
                        counter="25"
                        v-model="newDynamicForm.values.code"
                        :rules="newDynamicForm.rules.code"
                        :error-messages="newDynamicForm.errors.code"
                        @input="newDynamicForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('merchant.newDynamicForm.name')"
                        :hint="System.lang('merchant.newDynamicForm.nameHint')"
                        counter="50"
                        v-model="newDynamicForm.values.name"
                        :rules="newDynamicForm.rules.name"
                        :error-messages="newDynamicForm.errors.name"
                        @input="newDynamicForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addDynamicType"
                :disabled="!newDynamicForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDynamicDialog.close()}"
            />
        </template>
    </SimpleDialog>

    <!-- Add Static Bill Dialog -->
    <SimpleDialog
        :dialog="newStaticDialog"
        :title="System.lang('merchant.newStatic')"
        :icon="mdiTextBoxPlusOutline"
        @close="newStaticDialog.close()"
    >
        <SimpleForm 
            @ref="newStaticForm.ref = $event"
            @input="newStaticForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('merchant.newStaticForm.code')"
                        :hint="System.lang('merchant.newStaticForm.codeHint')"
                        counter="25"
                        v-model="newStaticForm.values.code"
                        :rules="newStaticForm.rules.code"
                        :error-messages="newStaticForm.errors.code"
                        @input="newStaticForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('merchant.newStaticForm.name')"
                        :hint="System.lang('merchant.newStaticForm.nameHint')"
                        counter="50"
                        v-model="newStaticForm.values.name"
                        :rules="newStaticForm.rules.name"
                        :error-messages="newStaticForm.errors.name"
                        @input="newStaticForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addStaticType"
                :disabled="!newStaticForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newStaticDialog.close()}"
            />
        </template>
    </SimpleDialog>

    <!-- Add Timer Bill Dialog -->
    <SimpleDialog
        :dialog="newTimerDialog"
        :title="System.lang('merchant.newTimer')"
        :icon="mdiTextBoxPlusOutline"
        @close="newTimerDialog.close()"
    >
        <SimpleForm 
            @ref="newTimerForm.ref = $event"
            @input="newTimerForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('merchant.newTimerForm.code')"
                        :hint="System.lang('merchant.newTimerForm.codeHint')"
                        counter="25"
                        v-model="newTimerForm.values.code"
                        :rules="newTimerForm.rules.code"
                        :error-messages="newTimerForm.errors.code"
                        @input="newTimerForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('merchant.newTimerForm.name')"
                        :hint="System.lang('merchant.newTimerForm.nameHint')"
                        counter="50"
                        v-model="newTimerForm.values.name"
                        :rules="newTimerForm.rules.name"
                        :error-messages="newTimerForm.errors.name"
                        @input="newTimerForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addTimerType"
                :disabled="!newTimerForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newTimerDialog.close()}"
            />
        </template>
    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiRefresh,
    mdiStorefrontOutline,
    mdiDatabase,
    mdiDeleteForever,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiTextBoxOutline,
    mdiTextBoxPlusOutline,
    mdiCogOutline,
    mdiMagnify,
    mdiPrinterPosOutline,
    mdiPrinterPosPlusOutline,
    mdiCurrencyUsd,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import {MERCHANT_STATUS, DYNAMIC_TYPE_STATUS, STATIC_TYPE_STATUS, TIMER_TYPE_STATUS, TERMINAL_STATUS} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import DataTable from '@/components/system/DataTable'
import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import ActionForm from '@/components/forms/ActionForm'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'MerchantView',
    components: {
        HeaderPanel,
        IconButton,
        DeleteActionButton,
        DataTable,
        IconLink,
        IconHint,
        SimpleDialog,
        ActionButton,
        ActionForm,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiRefresh,
            mdiDatabase,
            mdiStorefrontOutline,
            mdiDeleteForever,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiTextBoxOutline,
            mdiTextBoxPlusOutline,
            mdiCogOutline,
            mdiMagnify,
            mdiPrinterPosOutline,
            mdiPrinterPosPlusOutline,
            mdiCurrencyUsd,
            // Globals
            System,
            MERCHANT_STATUS,
            DYNAMIC_TYPE_STATUS,
            STATIC_TYPE_STATUS,
            TIMER_TYPE_STATUS,
            TERMINAL_STATUS,
            // Internal
            loading: false,
            showMore: false,
            // Data
            merchantId: undefined,
            merchant: undefined,
            statusItems: [
                {value: 1, text: System.lang('merchants.status.1')},
                {value: 2, text: System.lang('merchants.status.2')},
            ],
            showDynamicTypeSearch: false,
            dynamicTypeSearch: '',
            dynamicTypeSearchStatus: 0,
            dynamicTypeStatusItems: [
                {value: 0, text: System.lang('dynamicType.status.0')},
                {value: 1, text: System.lang('dynamicType.status.1')},
                {value: 2, text: System.lang('dynamicType.status.2')},
            ],
            showStaticTypeSearch: false,
            staticTypeSearch: '',
            staticTypeSearchStatus: 0,
            staticTypeStatusItems: [
                {value: 0, text: System.lang('staticType.status.0')},
                {value: 1, text: System.lang('staticType.status.1')},
                {value: 2, text: System.lang('staticType.status.2')},
            ],
            showTimerTypeSearch: false,
            timerTypeSearch: '',
            timerTypeSearchStatus: 0,
            timerTypeStatusItems: [
                {value: 0, text: System.lang('timerType.status.0')},
                {value: 1, text: System.lang('timerType.status.1')},
                {value: 2, text: System.lang('timerType.status.2')},
            ],
            showTerminalSearch: false,
            terminalSearch: '',
            terminalSearchStatus: 0,
            terminalStatusItems: [
                {value: 0, text: System.lang('terminal.status.0')},
                {value: 1, text: System.lang('terminal.status.1')},
                {value: 2, text: System.lang('terminal.status.2')},
            ],
            currencies: [],
            // Forms
            merchantForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                status: [
                ],
                reg_no: [
                    (v) => /^[a-zA-Z0-9]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 50) || System.lang('val.shorter'),
                ],
                vat_no: [
                    (v) => /^[a-zA-Z0-9]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 50) || System.lang('val.shorter'),
                ],
                address: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                admin_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                public_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
            }),
            newTerminalForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            newDynamicForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            newStaticForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            newTimerForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            currenciesForm: new Form({
                currencies: [
                    (v) => (!!v && !!v.length) || System.lang('val.required'),
                ],
            }),
            // Tables
            dynamicTypesTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('merchant.dbtable.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.dbtable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.dbtable.driver'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'driver',
                    width: '20%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.dbtable.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterDynamicTypeStatus,
                },
                {
                    text: System.lang('merchant.dbtable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'dynamic-types-table'),
            staticTypesTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('merchant.sbtable.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.sbtable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.sbtable.currency'),
                    sortable: true,
                    filterable: false,
                    align: 'center',
                    width: '50px',
                    value: 'currency',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.sbtable.driver'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'driver',
                    width: '20%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.sbtable.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterStaticTypeStatus,
                },
                {
                    text: System.lang('merchant.sbtable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'static-types-table'),
            timerTypesTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('merchant.tbtable.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.tbtable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.tbtable.currency'),
                    sortable: true,
                    filterable: false,
                    align: 'center',
                    width: '50px',
                    value: 'currency',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.tbtable.driver'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'driver',
                    width: '20%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.tbtable.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterDynamicTypeStatus,
                },
                {
                    text: System.lang('merchant.tbtable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'timer-types-table'),
            terminalsTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('merchant.termtable.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.termtable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.termtable.driver'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'driver',
                    width: '20%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchant.termtable.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterTerminalStatus,
                },
                {
                    text: System.lang('merchant.termtable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'terminals-table'),
            // Dialogs
            newDynamicDialog: new Dialog(() => {this.newDynamicForm.reset()}),
            newStaticDialog: new Dialog(() => {this.newStaticForm.reset()}),
            newTimerDialog: new Dialog(() => {this.newTimerForm.reset()}),
            newTerminalDialog: new Dialog(() => {this.newTerminalForm.reset()}),
        }
    },
    computed: {
    },
    methods: {
        init () {
            this.merchantId = this.$route.params.id;
            this.fetchData();
        },
        async fetchData () {
            this.merchant = undefined;
            this.merchantForm.reset();
            this.loading = true;

            return Api.get(`/admin/merchants/${this.merchantId}`)
                .then(data => {
                    this.merchant = data.merchant;
                    this.currencies = data.currencies;
                    this.currenciesForm.values.currencies = data.merchant_cur;
                    this.terminalsTable.load(data.terminals);
                    this.dynamicTypesTable.load(data.dynamicTypes);
                    this.staticTypesTable.load(data.staticTypes);
                    this.timerTypesTable.load(data.timerTypes);
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateMerchant () {
            return Api.patch(`/admin/merchants/${this.merchantId}`, this.merchant);
        },
        async updateCurrencies () {
            return Api.patch(`/admin/merchants/${this.merchantId}/currencies`, this.currenciesForm.values);
        },
        async deleteMerchant () {
            return Api.delete(`/admin/merchants/${this.merchantId}`, {})
                .then(() => {
                    System.redirectTo('admin-merchants');
                });
        },
        async addDynamicType () {
            if (this.newDynamicForm.validate()) {
                return Api.post(`/admin/merchants/${this.merchantId}/dynamic-types`, this.newDynamicForm.values)
                    .then(data => {
                        this.newDynamicDialog.close();
                        this.dynamicTypesTable.add(data);
                        System.redirectTo('admin-dynamic-type', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('merchant.messages.DYNAMIC_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newDynamicForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        async addStaticType () {
            if (this.newStaticForm.validate()) {
                return Api.post(`/admin/merchants/${this.merchantId}/static-types`, this.newStaticForm.values)
                    .then(data => {
                        this.newStaticDialog.close();
                        this.staticTypesTable.add(data);
                        System.redirectTo('admin-static-type', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('merchant.messages.STATIC_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newStaticForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        async addTimerType () {
            if (this.newTimerForm.validate()) {
                return Api.post(`/admin/merchants/${this.merchantId}/timer-types`, this.newTimerForm.values)
                    .then(data => {
                        this.newTimerDialog.close();
                        this.timerTypesTable.add(data);
                        System.redirectTo('admin-timer-type', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('merchant.messages.TIMER_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newTimerForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        async addTerminal () {
            if (this.newTerminalForm.validate()) {
                return Api.post(`/admin/merchants/${this.merchantId}/terminals`, this.newTerminalForm.values)
                    .then(data => {
                        this.newTerminalDialog.close();
                        this.terminalsTable.add(data);
                        System.redirectTo('admin-terminal', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('merchant.messages.TERMINAL_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newDynamicForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        filterDynamicTypeStatus (value) {
            return !this.dynamicTypeSearchStatus || value == this.dynamicTypeSearchStatus;
        },
        filterStaticTypeStatus (value) {
            return !this.staticTypeSearchStatus || value == this.staticTypeSearchStatus;
        },
        filterTerminalStatus (value) {
            return !this.terminalSearchStatus || value == this.terminalSearchStatus;
        },
    },
    mounted () {
        System.setTitle('merchant.merchant');
        this.init();
    },
}
</script>


<style scoped>
</style>
