<template>
<v-sheet color="grey lighten-4" class="pa-3">
    <div class="overflow-hidden d-flex">
        <v-avatar class="mr-3" :color="USER_ROLES[profile.role].color" size="45">
            <span class="white--text ">{{ initials }}</span>
        </v-avatar>
        <div>
            <p class="text-subtitle-2 mb-0 primary--text text-truncate">{{ profile.name }}</p>
            <p class="text-caption mb-0 grey--text text-truncate">{{ profile.email }}</p>
        </div>
    </div>
</v-sheet>
</template>


<script>
import { mapState } from 'vuex'

import User from '@/classes/User'
import {USER_ROLES} from '@/constants/system'

export default {
    name: 'UserHead',
    data () {
        return {
            USER_ROLES,
        }
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile,
        }),
        initials () {
            return User.initials(this.profile.name);
        }
    },

}
</script>


<style scoped>
</style>
