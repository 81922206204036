<template>
    <v-dialog v-model="show" scrollable max-width="550">
        <v-card>
            <v-card-title class="text-center mt-5">
                <v-spacer></v-spacer>
                <v-icon x-large :color="color">{{icon}}</v-icon>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="text-center mt-5 pb-0 text-body-1">
                <div v-html="popup.text"></div>
            </v-card-text>
            <v-card-text class="text-center mt-2 pb-1 text-caption grey--text" v-if="popup.descr">
                <div v-html="popup.descr"></div>
            </v-card-text>
            <v-card-text class="text-center mt-1 pb-1 text-caption grey--text" v-if="popup.type == MSG.ERROR">
                <IconButton
                    :icon="mdiDotsHorizontal"
                    :hint="'Support Functions'"
                    :fn="toggleButtons"
                />
            </v-card-text>
            <v-card-actions class="mt-4">
                <v-spacer></v-spacer>
                <v-btn :color="color" text @click="show = false" min-width="150">
                    {{System.lang('buttons.close')}}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import {
    mdiDotsHorizontal,
} from '@mdi/js';

import Interface from '@/classes/Interface'
import System from '@/classes/System'
import IconButton from '@/components/system/IconButton'
import {MSG} from '@/constants/interface'

export default {
    name: 'PopupMessage',
    components: {
        IconButton,
    },
    data () {
        return {
            // Objects
            System,
            MSG,
            // Icons
            mdiDotsHorizontal,
            // Data
            showButtons: false,
        }
    },
    computed: {
        popup () {
            return this.$store.state.system.popup;
        },
        show: {
            get () {
                return this.popup.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/popupOff')
                }
            }
        },
        color () {
            return Interface.messageColor(this.popup.type);
        },
        icon () {
            return Interface.messageIcon(this.popup.type);
        },
    },
    methods: {
        toggleButtons () {
            this.showButtons = !this.showButtons;
        },
    },
}
</script>


<style scoped>
</style>
