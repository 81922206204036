<template>
<div>
    <!-- Dashboard -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('dashboard.dashboard')"
            :icon="mdiViewDashboard"
            color="success"
            class="mb-6"
        ></HeaderPanel>
        <div>
            <p class="text-caption grey--text mb-1">{{System.lang('dashboard.system')}} <span class="info--text">{{systemData.name}}</span></p>
            <p class="text-caption grey--text mb-1" v-if="isSuper">{{System.lang('dashboard.frontVer')}} <span class="info--text">{{Settings.appVersion}}</span></p>
            <p class="text-caption grey--text mb-1" v-if="isSuper">{{System.lang('dashboard.backVer')}} <span class="info--text">{{systemData.version}}</span></p>
        </div>
    </v-sheet>

    <!-- Support -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3">
        <HeaderPanel
            :title="System.lang('dashboard.support')"
            :icon="mdiFaceAgent"
            color="success"
            class="mb-6"
        ></HeaderPanel>
        <div class="d-flex flex-wrap">
            <DashIcon :icon="mdiInvoiceTextOutline" :text="System.lang('menu.bills')" :fn="() => {System.redirectTo('support-bills')}"/>
            <DashIcon :icon="mdiStorefrontOutline" :text="System.lang('menu.merchants')" :fn="() => {System.redirectTo('support-merchants')}"/>
            <DashIcon :icon="mdiFileDocumentOutline" :text="System.lang('menu.reports')" :fn="() => {System.redirectTo('support-reports')}"/>
        </div>
    </v-sheet>

    <!-- Monitoring -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3">
        <HeaderPanel
            :title="System.lang('dashboard.monitor')"
            :icon="mdiDatabaseEyeOutline"
            color="success"
            class="mb-6"
        ></HeaderPanel>
        <div class="d-flex flex-wrap">
            <DashIcon :icon="mdiLoginVariant" :text="System.lang('dashboard.apilog')" color="teal" :fn="() => {System.redirectTo('monitor-apilog')}"/>
            <DashIcon :icon="mdiLogoutVariant" :text="System.lang('dashboard.cblog')" color="teal" :fn="() => {System.redirectTo('monitor-cblog')}"/>
        </div>
    </v-sheet>

    <!-- Configuration -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3">
        <HeaderPanel
            :title="System.lang('dashboard.config')"
            :icon="mdiCogOutline"
            color="success"
            class="mb-6"
        ></HeaderPanel>
        <div class="d-flex flex-wrap">
            <DashIcon :icon="mdiDomain"  :text="System.lang('menu.insts')" color="secondary" :fn="() => {System.redirectTo('admin-insts')}" v-if="isSuper"/>
            <DashIcon :icon="mdiDomain"  :text="System.lang('menu.inst')" color="secondary" :fn="() => {System.redirectTo('admin-inst')}" v-else/>
            <DashIcon :icon="mdiStorefrontOutline" :text="System.lang('menu.merchants')" color="secondary" :fn="() => {System.redirectTo('admin-merchants')}" />
            <DashIcon :icon="mdiCreditCardOutline" :text="System.lang('dashboard.channels')" color="secondary" :fn="() => {System.redirectTo('admin-paychannels')}" />
        </div>
    </v-sheet>
</div>
</template>


<script>
import {
    mdiViewDashboard,
    mdiFaceAgent,
    mdiInvoiceTextOutline,
    mdiStorefrontOutline,
    mdiFileDocumentOutline,
    mdiDatabaseEyeOutline,
    mdiLoginVariant,
    mdiLogoutVariant,
    mdiCogOutline,
    mdiDomain,
    mdiCreditCardOutline,
} from '@mdi/js';

import { mapState } from 'vuex'

import System from '@/classes/System';
import Settings from '@/constants/settings';
import {USER_ROLE} from "@/constants/system";
// import Interface from '@/classes/Interface'
// import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import DashIcon from '@/components/system/DashIcon'
// import ActionForm from '@/components/forms/ActionForm'
// import IconButton from '@/components/system/IconButton'

export default {
    name: 'HomeView',
    components: {
        HeaderPanel,
        DashIcon,
        // ActionForm,
        // IconButton,
    },
    data () {
        return {
            // Icons
            mdiViewDashboard,
            mdiFaceAgent,
            mdiInvoiceTextOutline,
            mdiStorefrontOutline,
            mdiFileDocumentOutline,
            mdiDatabaseEyeOutline,
            mdiLoginVariant,
            mdiLogoutVariant,
            mdiCogOutline,
            mdiDomain,
            mdiCreditCardOutline,
            // Globals
            System,
            Settings,
            // Forms
            // Data
        }
    },
    computed: {
        ...mapState({
            systemData: state => state.system.data,
            profile: state => state.user.profile,
        }),
        isSuper () {
            return this.profile.role == USER_ROLE.SUPER;
        },
    },
    methods: {
    },
    mounted () {
        System.setTitle('menu.dashboard');
    },
}
</script>


<style scoped>
</style>
