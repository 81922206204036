<template>
<div style="width: 100%">
    <div class="d-flex justify-space-between align-center">
        <!-- Icon and title -->
        <div class="overflow-hidden text-no-wrap">
            <v-icon left v-if="icon" :color="color">{{icon}}</v-icon>
            <span v-if="title" :class="'text-button '+titleColor">{{title}}</span>
        </div>
        <!-- Mobile right bar -->
        <div class="text-no-wrap d-sm-none" v-if="!hasButtons && help">
            <IconButton
                v-if="help"
                :icon="mdiHelpCircleOutline"
                :hint="System.lang('buttons.help.hint')"
                :fn="showHelp"
            />
        </div>
        <div class="text-no-wrap d-sm-none" v-else-if="hasButtons">
            <IconButton
                :icon="mdiDotsHorizontal"
                :fn="toggleButtons"
            />
        </div>
        <!-- Desktop right bar -->
        <div class="text-no-wrap d-none d-sm-flex">
            <slot></slot>
            <IconButton
                v-if="help"
                :icon="mdiHelpCircleOutline"
                :hint="System.lang('buttons.help.hint')"
                :fn="showHelp"
            />
        </div>
    </div>
    <!-- Mobile buttons -->
    <div class="text-right grey lighten-5 rounded-lg mt-1 pa-1 text-no-wrap d-sm-none" v-if="showButtons">
        <slot></slot>
        <IconButton
            v-if="help"
            :icon="mdiHelpCircleOutline"
            :hint="System.lang('buttons.help.hint')"
            :fn="showHelp"
        />
    </div>
    <!-- Hint message -->
    <p class="mt-2 mt-sm-0 grey--text text-caption" v-if="hint">{{hint}}</p>
</div>
</template>


<script>
import {
    mdiHelpCircleOutline,
    mdiDotsHorizontal,
} from '@mdi/js';

import System from '@/classes/System'

import IconButton from '@/components/system/IconButton'

export default {
    name: 'HeaderPanel',
    components: {
        IconButton,
    },
    props: {
        title: {
            type: [String, Boolean],
            default: false,
        },
        titleColor: {
            type: String,
            default: 'grey--text text--darken-1',
        },
        icon: {
            type: [String, Boolean],
            default: false,
        },
        color: {
            type: String,
            default: 'primary',
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        help: {
            type: [String, Boolean],
            default: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiHelpCircleOutline,
            mdiDotsHorizontal,
            // Data
            showButtons: false,
        }
    },
    computed: {
        hasButtons () {
            return !!this.$slots.default;
        },
    },
    methods: {
        showHelp () {
            console.log('Help: ' + this.help);
        },
        toggleButtons () {
            this.showButtons = !this.showButtons;
        },
    },
}
</script>


<style scoped>
</style>
