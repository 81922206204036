<template>
    <div class="icon-container text-center pa-1">
        <v-sheet :color="color" elevation="3" rounded height="120" class="icon-sheet text-center pa-3" @click="fn">
            <v-icon :color="textColor" size="40" class="ma-1">{{icon}}</v-icon>
            <p :class="`mb-0 mt-3 ${textColor}--text text-overline` ">{{text}}</p>
        </v-sheet>
    </div>
</template>


<script>
export default {
    name: 'DashIcon',
    props: {
        color: {
            type: String,
            required: false,
            default: 'primary'
        },
        textColor: {
            type: String,
            required: false,
            default: 'white'
        },
        icon: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        fn: {
            type: Function,
            required: true,
        },
    },

    data () {
        return {
        }
    },
    computed: {
    },

}
</script>


<style scoped>
.icon-container {
    width: 150px;
}
.icon-sheet {
    cursor: pointer;
}
</style>
