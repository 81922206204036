// Interface constants

const MT = {
    LINK: 1,
    SEPA: 2,
    HEAD: 3,
    GRP:  4,
}

const MSG = {
    INFO:    1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR:   4,
}

// Table settings
const TABLES_PP = [1, 10, 25, 50, -1];

// Languages
// Default language must be first
// If you need to add new language then it also must be added in:
// - src/languages (new translation file named by locale)
// - src/plugins/vuetify.js (import statement and lang.locales object)
// - public/images/languages (flag image)

const LANGS = [
    { locale: 'en', title: 'English',  image: 'en.svg' },
    // { locale: 'ru', title: 'Русский',  image: 'ru.svg' },
];


// Main menu configuration
// Following types are suported:
// - MT.HEAD - Small subheader in menu
// - MT.LINK - Regular menu item link
// - MT.SEPA - Separator line
// - MT.GRP  - Collapseable group
// Before adding new menu items you have to load corresponding icons below

import {
    mdiDomain,
    mdiStorefrontOutline,
    mdiCreditCardOutline,
    mdiLoginVariant,
    mdiLogoutVariant,
    mdiChartLine,
    mdiInvoiceTextOutline,
    mdiFileDocumentOutline,
    mdiViewDashboard,
    mdiAccountMultipleOutline,
    mdiCurrencyUsd,
} from '@mdi/js';

const MAIN_MENU_SUPER = [
    {id:  1, type: MT.LINK, title: 'menu.dashboard', icon: mdiViewDashboard, to: {name: 'home'}},
    {id:  2, type: MT.HEAD, title: 'menu.support'},
    {id:  3, type: MT.LINK, title: 'menu.bills', icon: mdiInvoiceTextOutline, to: {name: 'support-bills'}},
    {id:  4, type: MT.LINK, title: 'menu.merchants', icon: mdiStorefrontOutline, to: {name: 'support-merchants'}},
    {id:  5, type: MT.LINK, title: 'menu.reports', icon: mdiFileDocumentOutline, to: {name: 'support-reports'}},
    {id:  6, type: MT.HEAD, title: 'menu.monitor'},
    {id:  7, type: MT.LINK, title: 'menu.apilog', icon: mdiLoginVariant, to: {name: 'monitor-apilog'}},
    {id:  8, type: MT.LINK, title: 'menu.cblog', icon: mdiLogoutVariant, to: {name: 'monitor-cblog'}},
    {id:  9, type: MT.LINK, title: 'menu.perf', icon: mdiChartLine, to: {name: 'monitor-perf'}},
    {id: 10, type: MT.HEAD, title: 'menu.config'},
    {id: 12, type: MT.LINK, title: 'menu.insts', icon: mdiDomain, to: {name: 'admin-insts'}},
    {id: 13, type: MT.LINK, title: 'menu.merchants', icon: mdiStorefrontOutline, to: {name: 'admin-merchants'}},
    {id: 14, type: MT.LINK, title: 'menu.paychannels', icon: mdiCreditCardOutline, to: {name: 'admin-paychannels'}},
    {id: 15, type: MT.LINK, title: 'menu.currencies', icon: mdiCurrencyUsd, to: {name: 'admin-currencies'}},
    {id: 16, type: MT.LINK, title: 'menu.users', icon: mdiAccountMultipleOutline, to: {name: 'admin-users'}},
]

const MAIN_MENU_ADMIN = [
    {id:  1, type: MT.LINK, title: 'menu.dashboard', icon: mdiViewDashboard, to: {name: 'home'}},
    {id:  2, type: MT.HEAD, title: 'menu.support'},
    {id:  3, type: MT.LINK, title: 'menu.bills', icon: mdiInvoiceTextOutline, to: {name: 'support-bills'}},
    {id:  4, type: MT.LINK, title: 'menu.merchants', icon: mdiStorefrontOutline, to: {name: 'support-merchants'}},
    {id:  5, type: MT.LINK, title: 'menu.reports', icon: mdiFileDocumentOutline, to: {name: 'support-reports'}},
    {id:  6, type: MT.HEAD, title: 'menu.monitor'},
    {id:  7, type: MT.LINK, title: 'menu.apilog', icon: mdiLoginVariant, to: {name: 'monitor-apilog'}},
    {id:  8, type: MT.LINK, title: 'menu.cblog', icon: mdiLogoutVariant, to: {name: 'monitor-cblog'}},
    {id:  9, type: MT.HEAD, title: 'menu.config'},
    {id: 10, type: MT.LINK, title: 'menu.inst', icon: mdiDomain, to: {name: 'admin-inst'}},
    {id: 11, type: MT.LINK, title: 'menu.merchants', icon: mdiStorefrontOutline, to: {name: 'admin-merchants'}},
    {id: 12, type: MT.LINK, title: 'menu.paychannels', icon: mdiCreditCardOutline, to: {name: 'admin-paychannels'}},
]



export {MT, MSG, MAIN_MENU_SUPER, MAIN_MENU_ADMIN, LANGS, TABLES_PP};

