import InstView from '@/components/views/admin/InstView'
import InstsView from '@/components/views/admin/InstsView'
import MerchantsView from '@/components/views/admin/MerchantsView'
import MerchantView from '@/components/views/admin/MerchantView'
import UsersView from '@/components/views/admin/UsersView'
import UserView from '@/components/views/admin/UserView'
import DynamicTypeView from '@/components/views/admin/DynamicTypeView'
import StaticTypeView from '@/components/views/admin/StaticTypeView'
import TimerTypeView from '@/components/views/admin/TimerTypeView'
import TerminalView from '@/components/views/admin/TerminalView'
import PaychannelsView from '@/components/views/admin/PaychannelsView'
import PaychannelView from '@/components/views/admin/PaychannelView'
import SharedPaychannelView from '@/components/views/admin/SharedPaychannelView'
import CurrenciesView from '@/components/views/admin/CurrenciesView'
import CurrencyView from '@/components/views/admin/CurrencyView'

let prefix = '/admin';

const routes = [

    // Institutions
    {
        path: prefix + '/insts',
        name: 'admin-insts',
        component: InstsView,
    },
    {
        path: prefix + '/insts/inst',
        name: 'admin-inst',
        component: InstView,
    },

    // Merchants
    {
        path: prefix + '/merchants',
        name: 'admin-merchants',
        component: MerchantsView,
    },
    {
        path: prefix + '/merchants/:id',
        name: 'admin-merchant',
        component: MerchantView,
    },

    // Bills
    {
        path: prefix + '/merchants/dynamic-types/:id',
        name: 'admin-dynamic-type',
        component: DynamicTypeView,
    },
    {
        path: prefix + '/merchants/static-types/:id',
        name: 'admin-static-type',
        component: StaticTypeView,
    },
    {
        path: prefix + '/merchants/timer-types/:id',
        name: 'admin-timer-type',
        component: TimerTypeView,
    },

    // Terminals
    {
        path: prefix + '/merchants/terminals/:id',
        name: 'admin-terminal',
        component: TerminalView,
    },

    // Pay Channels
    {
        path: prefix + '/paychannels',
        name: 'admin-paychannels',
        component: PaychannelsView,
    },
    {
        path: prefix + '/paychannels/shared/:id/:instId',
        name: 'admin-sharedpaychannel',
        component: SharedPaychannelView,
    },
    {
        path: prefix + '/paychannels/:id/:instId',
        name: 'admin-paychannel',
        component: PaychannelView,
    },

    // Users
    {
        path: prefix + '/users',
        name: 'admin-users',
        component: UsersView,
    },

    {
        path: prefix + '/users/:id',
        name: 'admin-user',
        component: UserView,
    },
    
    // Currencies
    {
        path: prefix + '/currencies',
        name: 'admin-currencies',
        component: CurrenciesView,
    },

    {
        path: prefix + '/currencies/:id',
        name: 'admin-currency',
        component: CurrencyView,
    },

    
]

export default routes
