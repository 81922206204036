import {
    // mdiDomain,
    // mdiStorefrontOutline,
    mdiPrinterPosOutline,
    mdiCreditCardOutline,
    mdiAccountOutline,
    mdiAlert,
    mdiAlertOutline,
    mdiInformation,
    mdiClose,
    mdiCheck,
    mdiClockOutline,
    mdiProgressAlert,
    mdiAccount,
    mdiProgressQuestion,
    mdiCancel,
} from '@mdi/js';


const STATUSES = {
    1: {color: '#7CB342', icon: mdiCheck},
    2: {color: '#F44336', icon: mdiClose},
}

const USER_ROLE = {
    SUPER: 1,
    ADMIN: 2,
}

const USER_ROLES = {
    1: {color: '#2196F3', icon: mdiAccount},
    2: {color: '#4CAF50', icon: mdiAccountOutline},
}

const USER_STATUS = {
    OK: 1,
    DISABLED: 2,
}

const API_STATUS = {
    ON: 1,
    OFF: 2,
    DISABLED: 3,
}

const API_STATUSES = {
    1: {color: '#7CB342', icon: mdiCheck},
    2: {color: '#F44336', icon: mdiClose},
    3: {color: '#F44336', icon: mdiCancel},
}


const API_TYPE = {
    TERMINAL: 1,
    PAYCHANNEL: 2,
    USER: 3,
}

const API = {
    1: {icon: mdiPrinterPosOutline, color: '#7986CB'},
    2: {icon: mdiCreditCardOutline, color: '#689F38'},
    3: {icon: mdiAccountOutline, color: '#EF6C00'},
}

const CALLBACK_TYPE = {
    TERMINAL: 1,
    PAYCHANNEL: 2,
}

const MERCHANT_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const DYNAMIC_TYPE_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const STATIC_TYPE_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const TIMER_TYPE_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const BILL_TYPE = {
    DYNAMIC: 1,
    STATIC: 2,
    TIMER: 3,
}

const BILL_STATUS = {
    PENDING: 1,
    PAID: 2,
    COMPLETED: 3,
    CANCELLED: 4,
    EXPIRED: 5,
}

const BILL_STATUSES = {
    1: {color: '#90A4AE', icon: mdiClockOutline},
    2: {color: '#2196F3', icon: mdiCheck},
    3: {color: '#4CAF50', icon: mdiCheck},
    4: {color: '#F44336', icon: mdiClose},
    5: {color: '#F44336', icon: mdiClose},
}

const PAYMENT_STATUS = {
    SUCCESS: 1,
    FAILED: 2,
    REVERSED: 3,
}

const PAYMENT_STATUSES = {
    1: {color: '#4CAF50', icon: mdiCheck},
    2: {color: '#F44336', icon: mdiClose},
    3: {color: '#2196F3', icon: mdiCheck},
}

const REVERSAL_STATUS = {
    SUCCESS: 1,
    MANUAL: 2,
    FAILED: 3,
    SUSPECT: 4,
}

const REVERSAL_STATUSES = {
    1: {color: '#4CAF50', icon: mdiCheck},
    2: {color: '#4CAF50', icon: mdiProgressAlert},
    3: {color: '#F44336', icon: mdiClose},
    4: {color: '#F44336', icon: mdiAlertOutline},
}

const REFUND_STATUS = {
    SUCCESS: 1,
    MANUAL: 2,
    FAILED: 3,
    SUSPECT: 4,
}

const REFUND_STATUSES = {
    1: {color: '#4CAF50', icon: mdiCheck},
    2: {color: '#4CAF50', icon: mdiProgressAlert},
    3: {color: '#F44336', icon: mdiClose},
    4: {color: '#F44336', icon: mdiAlertOutline},
}

const TERMINAL_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const PAYCHANNEL_STATUS = {
    ENABLED: 1,
    DISABLED: 2,
}

const CALLBACK_STATUS = {
    DISABLED: 1,
    INSTANT: 2,
    QUEUE: 3,
}

const CALLBACK_RESULT = {
    UNKNOWN: 0,
    DISABLED: 1,
    SENT: 2,
    REJECT: 3,
    FAILED: 4,
    TIMEOUT: 5,
    QUEUED: 6,
}

const CALLBACK_RESULTS = {
    0: {color: '#9E9E9E', icon: mdiProgressQuestion},
    1: {color: '#F44336', icon: mdiCancel},
    2: {color: '#7CB342', icon: mdiCheck},
    3: {color: '#F44336', icon: mdiClose},
    4: {color: '#F44336', icon: mdiClose},
    5: {color: '#F44336', icon: mdiClose},
    6: {color: '#2196F3', icon: mdiCheck},
}

const STATIC_BILL_AMOUNT = {
    FIXED: 1,
    VARIABLE: 2,
}

const TIMER_BILL_PERIOD = {
    INFINITE: 1,
    LIMITED: 2,
}

const TIME_UNITS = {
    SECONDS: 1,
    MINUTES: 2,
    HOURS: 3,
    DAYS: 4,
    MONTHS: 5,
    YEARS: 6,
}

const IP_FILTER = {
    ADDRESS: 1,
    RANGE: 2,
}

const CALLBACK = {
    1: {icon: mdiPrinterPosOutline, color: '#7986CB'},
    2: {icon: mdiCreditCardOutline, color: '#689F38'},
}

const LOG_SEVERITY = {
    1: {icon: mdiInformation, color: 'success'},
    2: {icon: mdiAlertOutline, color: '#FFB74D'},
    3: {icon: mdiAlert, color: 'error'},
}

const LOG_MAP_ITEM = {
    INST: 1,
    MERCHANT: 2,
    TERMINAL: 3,
    PAYCHANNEL: 4,
    USER: 5,
    DYNAMIC_BILL: 10,
    STATIC_BILL: 11,
    TIMER_BILL: 12,
    PAYMENT: 20,
    REVERSAL: 21,
    REFUND: 22,
    CBCALL: 98,
    APICALL: 99,
}


export {
    STATUSES,
    USER_ROLE, 
    USER_ROLES,
    USER_STATUS,
    API_STATUS, 
    API_STATUSES,
    API_TYPE, 
    API, 
    BILL_TYPE, 
    BILL_STATUS, 
    BILL_STATUSES, 
    CALLBACK_TYPE, 
    CALLBACK_STATUS, 
    CALLBACK_RESULT,
    CALLBACK_RESULTS,
    MERCHANT_STATUS, 
    DYNAMIC_TYPE_STATUS, 
    STATIC_TYPE_STATUS, 
    TIMER_TYPE_STATUS, 
    TERMINAL_STATUS, 
    PAYCHANNEL_STATUS, 
    STATIC_BILL_AMOUNT, 
    TIMER_BILL_PERIOD, 
    TIME_UNITS, 
    IP_FILTER, 
    CALLBACK, 
    LOG_SEVERITY, 
    LOG_MAP_ITEM,
    PAYMENT_STATUS,
    PAYMENT_STATUSES,
    REVERSAL_STATUS,
    REVERSAL_STATUSES,
    REFUND_STATUS,
    REFUND_STATUSES,
};

