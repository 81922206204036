<template>
    <v-tooltip bottom :disabled="!hint">
        <template v-slot:activator="{on, attrs}">
            <v-btn
                class="mr-1 mb-1"
                @click="confirm ? confirming = true : action()"
                :loading="loading"
                :block="isMobile && block"
                :min-width="isMobile ? 0 : width"
                v-bind="{...attrs, ...$attrs}"
                v-on="on"
            >
                <v-icon left v-if="icon">{{icon}}</v-icon>
                {{label}}
            </v-btn>
            <PopupConfirm 
                v-if="confirm"
                :open="confirming"
                :text="confirm"
                :color="confirmColor"
                :icon="confirmIcon"
                :hint="confirmHint"
                :buttonYes="confirmYes"
                :buttonNo="confirmNo"
                @yes="confirming = false; action()"
                @no="confirming = false"
            />
        </template>
        <span>{{hint}}</span>
    </v-tooltip>
</template>


<script>
import System from "@/classes/System";
import Interface from "@/classes/Interface";

import PopupConfirm from '@/components/system/PopupConfirm'

export default {
    name: 'ActionButton',
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: [String, Boolean],
            default: false,
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        block: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 150,
        },
        fn: {
            type: Function,
            required: true,
        },
        ok: {
            type: [String, Boolean],
            default: false,
        },
        error: {
            type: [String, Boolean],
            default: false,
        },
        confirm: {
            type: [String, Boolean],
            default: false,
        },
        confirmColor: {
            type: String,
            default: 'primary',
        },
        confirmIcon: {
            type: [String, Boolean],
            default: undefined,
        },
        confirmHint: {
            type: [String, Boolean],
            default: false,
        },
        confirmYes: {
            type: [String, Boolean],
            default: false,
        },
        confirmNo: {
            type: [String, Boolean],
            default: false,
        },
    },
    components: {
        PopupConfirm,
    },
    data () {
        return {
            loading: false,
            confirming: false,
        }
    },
    computed: {
        isMobile () {
            return System.isMobile();
        },
    },
    methods: {
        action () {
            this.loading = true;
            
            return Promise.resolve(this.fn())
                .then(data => {
                    if (this.ok) Interface.snackSuccess(this.ok);
                    return data;
                })
                .catch(error => {
                    if (this.error) Interface.popupError(this.error, error.message);
                })
                .finally(() => {
                    this.loading = false
                });
        },
    },
}
</script>


<style scoped>
</style>
