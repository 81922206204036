<template>
    <v-dialog
        v-model="showDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title class="text-button">
                    <v-icon left>{{mdiTextSearch}}</v-icon>
                    {{System.lang('apilog.dialogTitle')}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="refresh"><v-icon>{{mdiRefresh}}</v-icon></v-btn>
                    <v-btn icon dark @click="showDialog = false"><v-icon>{{mdiClose}}</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="text-center py-5" v-if="loading && !apilog">
                <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
            </div>
            <v-container v-if="apilog">
                <!-- Data -->
                <v-sheet width="100%" elevation="1" rounded class="pa-5">
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :label="System.lang('apilog.uuid')"
                                :value="apilog.uuid"
                                :prepend-icon="mdiIdentifier"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                label="API"
                                :value="System.lang(`apilog.api.${apilog.api}`)"
                                :prepend-icon="API[apilog.api].icon"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                :label="System.lang('apilog.route')"
                                :value="apilog.route"
                                :prepend-icon="mdiArrowDecision"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                :label="System.lang('apilog.response')"
                                :value="`HTTP/${apilog.http_status}${(apilog.reject_code ? ' - '+apilog.reject_code : '')}`"
                                :hint="(apilog.reject_message ?? System.lang('apilog.success'))"
                                persistent-hint
                                readonly
                            >
                                <template v-slot:prepend>
                                    <v-icon v-if="apilog.http_status > 299" color="error">{{mdiAlert}}</v-icon>
                                    <v-icon v-else color="success">{{mdiCheck}}</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :label="System.lang('apilog.start_at')"
                                :value="Dates.human(apilog.start_at, 2)"
                                :prepend-icon="mdiClockTimeOneOutline"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                :label="System.lang('apilog.finish_at')"
                                :value="Dates.human(apilog.finish_at, 2)"
                                :prepend-icon="mdiClockTimeFiveOutline"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                :label="System.lang('apilog.duration')"
                                :value="`${apilog.duration} msec.`"
                                :prepend-icon="mdiTimerOutline"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                label="IP"
                                :value="apilog.ip"
                                :prepend-icon="mdiIpNetwork"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-sheet>
                <!-- Mapping -->
                <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="apilog.log_maps?.length">
                    <HeaderPanel
                        :title="System.lang('apilog.mapping')"
                        :icon="mdiTransitConnectionVariant"
                        color="secondary"
                        class="mb-3"
                    />
                    <v-chip link v-for="map in apilog.log_maps" :key="map.id" class="ma-1" @click="openMapDialog(map)">
                        {{System.lang(`apilog.map.${map.item_type}`)}}
                    </v-chip>
                </v-sheet>
                <!-- Timeline -->
                <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="apilog.timeline?.length" v-show="!System.isMobile()">
                    <HeaderPanel
                        :title="System.lang('apilog.timeline')"
                        :icon="mdiClockOutline"
                        color="secondary"
                        class="mb-3"
                    />
                    <div id="chart" ref="chart" :style="{height: `${height}px`}"></div>
                </v-sheet>
                <!-- Log -->
                <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="apilog.log?.length">
                    <HeaderPanel
                        :title="System.lang('apilog.logData')"
                        :icon="mdiTextBoxOutline"
                        color="secondary"
                        class="mb-3"
                    />
                    <v-simple-table dense>
                        <tbody>
                            <tr v-for="log in apilog.log" :key="log.id">
                                <td style="width: 120px; font-family: monospace; font-size: 12px;">{{log.offset}} msec.</td>
                                <td style="width: 50px"><v-icon :color="LOG_SEVERITY[log.severity].color">{{LOG_SEVERITY[log.severity].icon}}</v-icon></td>
                                <td style="font-family: monospace; font-size: 12px;">{{log.text}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-sheet>
                <!-- Request / Response -->
                <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="apilog.request || apilog.response">
                    <HeaderPanel
                        :title="System.lang('apilog.rawData')"
                        :icon="mdiTextBoxOutline"
                        color="secondary"
                        class="mb-3"
                    />
                    <v-row>
                        <v-col cols="12" sm="6" v-if="apilog.request">
                            <v-subheader>{{System.lang('apilog.requestData')}}</v-subheader>
                            <pre v-html="System.prettyJSON(apilog.request)"></pre>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="apilog.response">
                            <v-subheader>{{System.lang('apilog.responseData')}}</v-subheader>
                            <pre v-html="System.prettyJSON(apilog.response)"></pre>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-container>
            <v-card-actions class="mt-6 primary" v-if="apilog">
                <v-spacer></v-spacer>
                <v-btn text dark @click="showDialog = false">{{System.lang('buttons.close')}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <CbLogDialog ref="callbackDialog" />
        <BillDialog ref="billDialog" />
        <ReversalDialog ref="reversalDialog" />
        <RefundDialog ref="refundDialog" />
        <PaymentDialog ref="paymentDialog" />
        <MerchantDialog ref="merchantDialog" />
        <TerminalDialog ref="terminalDialog" />
        <ChannelDialog ref="channelDialog" />
        <UserDialog ref="userDialog" />
    </v-dialog>
</template>


<script>
import {
    mdiClose,
    mdiRefresh,
    mdiTextSearch,
    mdiIdentifier,
    mdiClockTimeOneOutline,
    mdiClockTimeFiveOutline,
    mdiTimerOutline,
    mdiIpNetwork,
    mdiArrowDecision,
    mdiCheck,
    mdiAlert,
    mdiTransitConnectionVariant,
    mdiClockOutline,
    mdiTextBoxOutline,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Api from '@/services/api'
import Dates from '@/classes/Dates'
import {API, LOG_SEVERITY, LOG_MAP_ITEM, BILL_TYPE} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
    name: 'ApiLogDialog',
    components: {
        HeaderPanel,
        BillDialog: () => import('./BillDialog.vue'),
        CbLogDialog: () => import('./CbLogDialog.vue'),
        PaymentDialog: () => import('./PaymentDialog.vue'),
        ReversalDialog: () => import('./ReversalDialog.vue'),
        RefundDialog: () => import('./RefundDialog.vue'),
        MerchantDialog: () => import('./MerchantDialog.vue'),
        TerminalDialog: () => import('./TerminalDialog.vue'),
        ChannelDialog: () => import('./ChannelDialog.vue'),
        UserDialog: () => import('./UserDialog.vue'),
    },
    props: {
    },
    data () {
        return {
            // Globals
            System,
            Dates,
            API,
            LOG_SEVERITY,
            LOG_MAP_ITEM,
            BILL_TYPE,
            // Icons
            mdiClose,
            mdiRefresh,
            mdiTextSearch,
            mdiIdentifier,
            mdiClockTimeOneOutline,
            mdiClockTimeFiveOutline,
            mdiTimerOutline,
            mdiIpNetwork,
            mdiArrowDecision,
            mdiCheck,
            mdiAlert,
            mdiTransitConnectionVariant,
            mdiClockOutline,
            mdiTextBoxOutline,
            // Internal
            showDialog: false,
            loading: false,
            root: undefined,
            height: 100,
            // Data
            dbId: undefined,
            apilog: undefined,
            // Tables
        }
    },
    computed: {
    },
    methods: {
        show (dbId) {
            this.showDialog = true;

            if (this.dbId != dbId) {
                this.dbId = dbId;
                this.apilog = undefined;
                this.fetchLog();
            }
        },
        refresh () {
            this.apilog = undefined;
            this.fetchLog();
        },
        async fetchLog () {
            this.loading = true;

            return Api.get(`/monitor/fetch/apilog/${this.dbId}`)
                .then(data => {
                    this.apilog = data;
                    this.$nextTick(() => this.createChart(this.apilog.timeline, this.apilog.duration));
                })
                .catch(error => {
                    this.dbId = undefined;
                    this.showDialog = false;
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createChart (timeline, end) {
            if (!timeline.length) return;

            if (this.root) {
                this.root.dispose();
            }

            this.height = 100 + (timeline.length * 20);

            timeline.forEach((e, i) => {
                e.tag_orig = e.tag;
                e.tag = `${timeline.length-i} - ${e.tag}`;
                if (e.end === null) e.end = end;
                if (e.duration === null) e.duration = +end - +e.start;
            });

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            // let root = am5.Root.new("chart");
            let root = am5.Root.new(this.$refs.chart);

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panY",
                // wheelY: "zoomY",
                wheelY: "none",
                // pinchZoomY: true,
                pinchZoomY: false,
                paddingLeft: 0
            }));

            chart.get("colors").set("step", 3);

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            let yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                minorGridEnabled: true
            });

            let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "tag",
                renderer: yRenderer,
                // tooltip: am5.Tooltip.new(root, {}),
            }));

            yRenderer.labels.template.setAll({
                multiLocation: 0.5,
                fontSize: 12,
            });

            yRenderer.grid.template.setAll({
                location: 1
            });

            let xRenderer = am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 60,
            });

            let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: xRenderer,
                // renderer: am5xy.AxisRendererX.new(root, {
                //     strokeOpacity: 0.1,
                //     minGridDistance: 60,
                //     fontSize: 10,
                // })
            }));

            xRenderer.labels.template.setAll({
                fontSize: 12,
            });

            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                baseAxis: yAxis,
                valueXField: "end",
                openValueXField: "start",
                categoryYField: "tag",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{tag_orig}: {openValueX} - {valueX} ({duration} msec.)"
                })
            }));

            series.columns.template.setAll({
                height: 0.5
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 0,
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        fill: series.get("fill")
                    })
                })
            });

            let nextColor = chart.get("colors").getIndex(1);

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        fill: nextColor
                    })
                })
            });

            yAxis.data.setAll(timeline);
            series.data.setAll(timeline);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);

            this.root = root;
        },
        openMapDialog (map) {
            if (map.item_type == LOG_MAP_ITEM.CBCALL) this.$refs.callbackDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.DYNAMIC_BILL) this.$refs.billDialog.show(BILL_TYPE.DYNAMIC, map.item_id);
            if (map.item_type == LOG_MAP_ITEM.STATIC_BILL) this.$refs.billDialog.show(BILL_TYPE.STATIC, map.item_id);
            if (map.item_type == LOG_MAP_ITEM.TIMER_BILL) this.$refs.billDialog.show(BILL_TYPE.TIMER, map.item_id);
            if (map.item_type == LOG_MAP_ITEM.PAYMENT) this.$refs.paymentDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.REVERSAL) this.$refs.reversalDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.REFUND) this.$refs.refundDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.MERCHANT) this.$refs.merchantDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.TERMINAL) this.$refs.terminalDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.PAYCHANNEL) this.$refs.channelDialog.show(map.item_id);
            if (map.item_type == LOG_MAP_ITEM.USER) this.$refs.userDialog.show(map.item_id);
        }
    },
    mounted () {
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    },
}
</script>


<style scoped>
#chart {
    width: 100%;
    /* height: 400px; */
}
</style>
