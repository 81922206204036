<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <InstSelector
            @selected="instId = $event; init()"
        />
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="instId">
        <HeaderPanel
            :title="System.lang('merchants.merchants')"
            :icon="mdiStorefrontOutline"
            color="success"
            class="mb-6"
        >
            <!-- <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            /> -->
            <v-text-field
                v-model="search"
                clearable
                hide-details
                dense
                :prepend-icon="mdiMagnify"
            ></v-text-field>
        </HeaderPanel>
        <DataTable 
            :table="merchantsTable" 
            :search="search"
            :no-data-text="System.lang('merchants.table.noMerchants')" 
        >
            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == MERCHANT_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('merchants.status.' + item['status'])"
                    :color="item['status'] == MERCHANT_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    :icon="mdiEye"
                    :hint="System.lang('buttons.view')"
                    color="primary"
                    :fn="() => {$refs.merchantDialog.show(item.id)}"
                />
            </template>
        </DataTable>
        <MerchantDialog ref="merchantDialog" />
    </v-sheet>

</div>
</template>

<script>
import {
    // mdiRefresh,
    mdiStorefrontOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
    mdiEye,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table} from '@/classes/Elements'
import Api from '@/services/api'
import {MERCHANT_STATUS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import InstSelector from '@/components/parts/InstSelector'
import IconButton from '@/components/system/IconButton'
import IconHint from '@/components/system/IconHint'
import DataTable from '@/components/system/DataTable'

export default {
    name: 'MerchantsView',
    components: {
        HeaderPanel,
        InstSelector,
        // ActionForm,
        IconButton,
        // IconAction,
        IconHint,
        DataTable,
        MerchantDialog: () => import('../../parts/MerchantDialog.vue'),
    },
    data () {
        return {
            // Icons
            // mdiRefresh,
            mdiStorefrontOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            mdiEye,
            // Globals
            System,
            MERCHANT_STATUS,
            // Data
            instId: undefined,
            search: '',
            // Forms
            // Tables
            merchantsTable: new Table([
                {
                    text: System.lang('merchants.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.reg_no'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'reg_no',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.vat_no'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'vat_no',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterStatus,
                },
                {
                    text: System.lang('merchants.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'support-merchants-table', this.fetchMerchants),
            // Dialogs
        }
    },
    computed: {
    },
    methods: {
        init () {
            return this.merchantsTable.load();    
        },
        async fetchMerchants () {
            this.search = '';

            return Api.get(`/support/${this.instId}/list/merchants`)
                .then(data => data.merchants)
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
    },
    mounted () {
        System.setTitle('merchants.merchants');
        // this.init();
    },
}
</script>


<style scoped>
</style>
