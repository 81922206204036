import {en} from 'vuetify/lib/locale'

export default {
    ...en,

    // Errors
    errors: {
        'DEFAULT': 'Error code {0}',
        '0': 'Network or connection error',
        '403': 'Access denied',
        '404': 'Requested resource not found',
        '422': 'Form contains errors',
        '500': 'Backend server error',
        'UNKNOWN': 'Unknown error',
        'NETWORK': 'Network or connection error',
        'BACKEND_FAILED': 'Backend connection failed',
        'LOGIN_FAILED': 'Login failed',
    },

    // Global messages
    messages: {
        'REQUEST_FAILED': 'Request Failed',
        'SAVE_OK': 'Saved',
        'SAVE_ERROR': 'Save Failed',
        'SESSION_EXPIRED': 'Your session expied. Please login again.',
        'CLIPBOARD_COPIED': 'Copied to clipboard',
        'CLIPBOARD_FAILED': 'Failed to copy',
        'CLIPBOARD_NOT_AVAIL': 'Clipboard not available',
    },

    // Global buttons
    buttons: {
        ok: 'OK',
        cancel: 'Cancel',
        close: 'Close',
        delete: 'Delete',
        search: 'Search',
        view: 'View',
        copy: {
            label: 'Copy',
            hint: 'Copy to clipboard',
        },
        confirm: 'Confirm',
        refresh: 'Refresh',
        save: {
            label: 'Save',
            hint: 'Save changes',
        },
        saveQr: {
            label: 'Save QR',
            hint: 'Save QR code file',
        },
        help: {
            label: 'Help',
            hint: 'Show help',
        },
        config: {
            label: 'Configure',
            hint: 'Configure'
        },
        translate: {
            label: 'Translate',
            hint: 'Translations'
        },
    },

    // Interface
    interface: {
        logout: 'Logout',
        chpass: 'Change password',
        info: 'Information',
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
        more: 'Show more...',
        less: 'Show less...',
        title: 'sohoQR',
    },

    // Main menu
    menu: {
        dashboard: 'Dashboard',
        config: 'Configuration',
        insts: 'Institutions',
        inst: 'Institution',
        merchants: 'Merchants',
        paychannels: 'Payment Channels',
        monitor: 'Monitoring',
        apilog: 'API Log',
        cblog: 'Callback Log',
        perf: 'Performance Log',
        stress: 'Stress Test',
        support: 'Support',
        bills: 'Bills',
        testing: 'Testing',
        reports: 'Reports',
        users: 'Users',
        currencies: 'Currencies',
    },

    // Form validation messages
    val: {
        required: 'Field required',
        invalid: 'Invalid characters',
        shorter: 'Must be shorter',
        longer: 'Must be longer',
        less: 'Must be less',
        more: 'Must be more',
        positive: 'Must be > 0',
        integer: 'Must be integer',
        numeric: 'Must be numeric',
        alphanumeric: 'Must be alphanumeric',
        url: 'Invalid URL',
        amount: 'Invalid amount',
        ip: 'Invalid IP address',
        uuid: 'Invalid UUID',
        email: 'Invalid email',
        passmatch: 'Passwords do not match',
    },

    // Encryption and API keys
    keys: {
        generateApiKey: 'Generate new key',
        confirmGenApiKey: 'Generate new API key?',
        messages: {
            'APIKEY_GENERATED': 'API key generated',
        },
    },

    // Login screen
    login: {
        username: {
            label: 'Email',
            hint: 'Enter your login email',
            errors: [
                'Enter your login email',
                'Must be a valid email',
            ],
        },
        password: {
            label: 'Password',
            hint: 'Enter your password',
            errors: [
                'Enter your password',
            ],
        },
        buttons: {
            login: "Login",
        },
        errors: [
            'Incorrect password',
            'Login Failed',
        ],
        remember: 'Remember Me',
        forgot: 'Forgot password?',
        register: 'Register new account',
    },


    // Dashboard
    dashboard: {
        dashboard: 'Dashboard',
        system: 'System: ',
        frontVer: 'Frontend version: ',
        backVer: 'Backend version: ',
        support: 'Support',
        monitor: 'Monitor',
        apilog: 'API',
        cblog: 'Callbacks',
        config: 'Configure',
        channels: 'Paychannels',
    },


    // Institutions screen
    insts: {
        insts: 'Institutions',
        addNewHint: 'Add new institution',
        newInst: 'New Institution',
        searchHint: 'Search currencies',
        messages: {
            'INST_ADDED': 'New institution added',
            'INST_DELETED': 'Institution deleted',
        },
        table: {
            code: 'Code',
            name: 'Name',
            mid: 'Member ID',
            status: 'API Status',
            actions: 'Actions',
            noInsts: 'No institutions defined',
            search: 'Search...',
        },
        instapi: 'Institution API',
        merchapi: 'Merchant API',
        termapi: 'Terminal API',
        payapi: 'Payment API',
        userapi: 'User API',
        apistatus: {
            1: 'On',
            2: 'Off',
            3: 'Disabled',
        },
        newForm: {
            code: 'Code',
            codeHint: 'Institution code',
            name: 'Name',
            nameHint: 'Institution name',
            mid: 'Memeber ID',
            midHint: 'Unique member ID',
        },
    },

    // Institution screen
    inst: {
        backHint: 'Back to institution list',
        inst: 'Institution',
        apiControl: 'API Control',
        delete: 'Delete Institution',
        deleteHint: 'Delete institution and all related data including merchants and terminals. Delete is not possible if bill or payment history is not empty for this institution. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete institution?',
        confirmDeleteHint: 'This will delete institution and all related data',
        deleted: 'Institution deleted',
        instForm: {
            code: 'Code',
            codeHint: 'Institution code',
            name: 'Name',
            nameHint: 'Institution name',
            mid: 'Memeber ID',
            midHint: 'Unique member ID',
            currency: 'Default currency',
            qrPrefix: 'QR prefix',
            qrPrefixHint: 'URL prefix for QR codes',
            address: 'Address',
            addressHint: 'Address',
            publicContact: 'Public contact',
            publicContactHint: 'Public contact information',
            adminContact: 'Admin contact',
            adminContactHint: 'Admin contact information',
            legalInfo: 'Legal info',
            legalInfoHint: 'Institution legal info',
        },
        apiEnabled: 'API enabled',
        apiDisabled: 'API disabled',
    },

    // Merchants screen
    merchants: {
        merchants: 'Merchants',
        searchMerchants: 'Search merchants',
        addNewHint: 'Add new merchant',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        table: {
            search: 'Search...',
            code: 'Code',
            name: 'Name',
            reg_no: 'Reg No',
            vat_no: 'VAT No',
            status: 'Status',
            actions: 'Actions',
            noMerchants: 'No merchants defined',
        },
        newMerchant: 'New Merchant',
        newForm: {
            code: 'Code',
            codeHint: 'Merchant code',
            name: 'Name',
            nameHint: 'Merchant name',
        },
        messages: {
            'MERCH_ADDED': 'New merchant created',
        },
    },

    // Merchant screen
    merchant: {
        backHint: 'Back to merchants',
        merchant: 'Merchant',
        dynamicBills: 'Dynamic Bills',
        addNewDynType: 'Add new dynamic bill type',
        searchDynTypes: 'Search dynamic bill types',
        statBills: 'Static Bills',
        addNewStatType: 'Add new static bill type',
        searchStatTypes: 'Search static bill types',
        timerBills: 'Timer Bills',
        addNewTimerType: 'Add new timer bill type',
        searchTimerTypes: 'Search timer bill types',
        terminals: 'Terminals',
        addTerminal: 'Add new terminal',
        searchTerminals: 'Search terminals',
        currencies: 'Currencies',
        delete: 'Delete Merchant',
        deleteHint: 'Delete merchant and all related data including terminals and keys. Delete is not possible if bill or payment history is not empty for this merchant. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete merchant?',
        confirmDeleteHint: 'This will delete merchant and all related data',
        createdAt: 'Created',
        merchantForm: {
            code: 'Code',
            codeHint: 'Merchant code',
            name: 'Name',
            nameHint: 'Merchant name',
            status: 'Status',
            statusHint: 'Merchant status',
            regno: 'Registration No.',
            regnoHint: 'Merchant registration number',
            vatno: 'VAT No.',
            vatnoHint: 'Merchant VAT number',
            address: 'Address',
            addressHint: 'Merchant address',
            publicContact: 'Public contact',
            publicContactHint: 'Public contact information',
            adminContact: 'Admin contact',
            adminContactHint: 'Admin contact information',
        },
        newDynamic: 'New Dynamic Bill Type',
        newDynamicForm: {
            code: 'Code',
            codeHint: 'Operation code',
            name: 'Name',
            nameHint: 'Operation name',
        },
        newStatic: 'New Static Bill Type',
        newStaticForm: {
            code: 'Code',
            codeHint: 'Item code',
            name: 'Name',
            nameHint: 'Item name',
        },
        newTimer: 'New Timer Bill Type',
        newTimerForm: {
            code: 'Code',
            codeHint: 'Operation code',
            name: 'Name',
            nameHint: 'Operation name',
        },
        newTerminal: 'New Terminal',
        newTerminalForm: {
            code: 'Code',
            codeHint: 'Terminal code',
            name: 'Name',
            nameHint: 'Terminal name',
        },
        currenciesForm: {
            currencies: 'Merchant currencies',
        },
        dbtable: {
            search: 'Search...',
            code: 'Code',
            name: 'Name',
            driver: 'Driver',
            status: 'Status',
            actions: 'Actions',
            noBills: 'No dynamic bills configured',
        },
        sbtable: {
            search: 'Search...',
            code: 'Code',
            name: 'Name',
            currency: 'Currency',
            driver: 'Driver',
            status: 'Status',
            actions: 'Actions',
            noBills: 'No static bills configured',
        },
        tbtable: {
            search: 'Search...',
            code: 'Code',
            name: 'Name',
            currency: 'Currency',
            driver: 'Driver',
            status: 'Status',
            actions: 'Actions',
            noBills: 'No timer bills configured',
        },
        termtable: {
            search: 'Search...',
            code: 'Code',
            name: 'Name',
            address: 'Address',
            driver: 'Driver',
            status: 'Status',
            locked: 'Locked',
            actions: 'Actions',
            noTerminals: 'No terminals defined',
        },
        messages: {
            'MERCHANT_DELETED': 'Merchant deleted',
            'DYNAMIC_ADDED': 'New dynamic bill created',
            'STATIC_ADDED': 'New static bill created',
            'TIMER_ADDED': 'New timer bill created',
            'TERMINAL_ADDED': 'New terminal created',
        },
    },

    // Dynamic type screens
    dynamicType: {
        backHint: 'Back to merchant',
        dynamicType: 'Dynamic Bill',
        delete: 'Delete Bill',
        deleteHint: 'Delete dynamic bill definition. Delete is not possible if bill or payment history is not empty for this bill type. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete bill definition?',
        confirmDeleteHint: 'This will delete dynamic bill definition',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        validity: {
            2: 'Minutes',
            3: 'Hours',
            4: 'Days',
        },
        dynamicTypeForm: {
            code: 'Code',
            codeHint: 'Operation code',
            name: 'Name',
            nameHint: 'Operation name',
            status: 'Status',
            statusHint: 'Operation status',
            driver: 'Driver',
            validity: 'Validity',
            validityHint: 'Validity period',
        },
        messages: {
            'TYPE_DELETED': 'Bill definition deleted',
        },
    },

    // Static type screens
    staticType: {
        backHint: 'Back to merchant',
        staticType: 'Static Bill',
        amounts: 'Amounts',
        delete: 'Delete Bill',
        deleteHint: 'Delete static bill definition. Delete is not possible if bill or payment history is not empty for this bill type. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete bill definition?',
        confirmDeleteHint: 'This will delete static bill definition',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        staticTypeForm: {
            code: 'Code',
            codeHint: 'Item code',
            name: 'Name',
            nameHint: 'Item name',
            status: 'Status',
            statusHint: 'Operation status',
            driver: 'Driver',
        },
        amountsForm: {
            currency: 'Currency',
            type: 'Payment amount:',
            types: {
                1: 'Fixed',
                2: 'Variable',
            },
            fixed: 'Amount',
            fixedHint: 'Fixed payment amount',
            min: 'Min amount',
            minHint: 'Minimal payment amount',
            max: 'Max amount',
            maxHint: 'Maximal payment amount',
        },
        messages: {
            'TYPE_DELETED': 'Bill definition deleted',
        },
    },

    // Timer type screens
    timerType: {
        backHint: 'Back to merchant',
        timerType: 'Timer Bill',
        amounts: 'Amounts',
        periods: 'Timer Periods',
        addPeriod: 'Add new period',
        editPeriod: 'Edit period',
        deletePeriod: 'Delete period',
        deletePeriodConfirm: 'Delete period?',
        delete: 'Delete Bill',
        deleteHint: 'Delete timer bill definition. Delete is not possible if bill or payment history is not empty for this bill type. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete bill definition?',
        confirmDeleteHint: 'This will delete timer bill definition',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        periodType: {
            0: 'All',
            1: 'Infinite',
            2: 'Limited',
        },
        timeUnit: {
            1: 'Seconds',
            2: 'Minutes',
            3: 'Hours',
            4: 'Days',
            5: 'Months',
        },
        timerTypeForm: {
            code: 'Code',
            codeHint: 'Operation code',
            name: 'Name',
            nameHint: 'Operation name',
            status: 'Status',
            statusHint: 'Operation status',
            driver: 'Driver',
            payDelay: 'Payment period',
            payDelayHint: 'Payment wait period',
        },
        amountsForm: {
            currency: 'Currency',
            min: 'Min amount',
            minHint: 'Minimal payment amount',
            max: 'Max amount',
            maxHint: 'Maximal payment amount',
        },
        periodForm: {
            order: 'Order',
            orderHint: 'Period order',
            periodType: 'Duration:',
            period: 'Duration',
            periodHint: 'Period duration',
            step: 'Step',
            stepHint: 'Step duration',
            amountFlat: 'Flat amount',
            amountFlatHint: 'Flat period amount',
            amountStep: 'Step amount',
            amountStepHint: 'Amount of each step',
        },
        periodsTable: {
            order: 'Order',
            duration: 'Duration',
            step: 'Step',
            amountFlat: 'Flat amount',
            amountStep: 'Step amount',
            actions: 'Actions',
            noPeriods: 'No periods defined',
            infinite: 'Infinite',
        },
        messages: {
            'TYPE_DELETED': 'Bill definition deleted',
        },
    },

    // Callbacks
    callbacks: {
        driver: 'Driver',
        statusLabel: 'Callback:',
        status: {
            1: 'Disabled',
            2: 'Enabled',
        },
        standardapi: {
            url: 'URL',
            urlHint: 'Callback URL',
            key: 'API key',
            keyHint: 'Bearer authorisation token string',
            connectSettings: 'Connection settings',
            connect_timeout: 'Connect timeout:',
            request_timeout: 'Request timeout:',
            retry_count: 'Number of retries:',
            retry_delay: 'Retry delay:',
            noRetries: 'No retries',
        },
    },

    // IP filter
    ipFilter: {
        ip: 'IP Filter',
        addIp: 'Add IP address',
        allowedIp: 'Allowed IP addresses:',
        allowedAllIp: 'No IP address filtering',
        addIpForm: {
            single: 'Single IP address',
            range: 'IP address range',
            ip: 'IP address',
            from: 'Range start',
            to: 'Range end',
            mustBeLarger: 'Must be larger than start',
        },
    },

    // Terminal screen
    terminal: {
        backHint: 'Back to merchant',
        terminal: 'Terminal',
        delete: 'Delete terminal',
        deleteHint: 'Delete terminal and all related data. Delete is not possible if bill or payment history is not empty for this terminal. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete terminal?',
        confirmDeleteHint: 'This will delete terminal record and all related data',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        terminalForm: {
            code: 'Code',
            codeHint: 'Terminal code',
            name: 'Name',
            nameHint: 'Terminal name',
            status: 'Status',
            driver: 'Driver',
            address: 'Address',
            addressHint: 'Terminal address',
            publicContact: 'Public contact',
            publicContactHint: 'Public contact information',
            adminContact: 'Admin contact',
            adminContactHint: 'Admin contact information',
        },
        qrcode: 'QR Code',
        qrForm: {
            ecc: 'Error correction level',
            format: 'Image format',
        },
        apikeys: 'API Keys',
        noApiKeys: 'No API keys created',
        addNewApikey: 'Add new API key',
        viewApiKey: 'Show API key',
        deleteApiKey: 'Delete API key',
        deleteApiKeyConfirm: 'Delete this API key?',
        newApiKey: 'New API Key',
        newApiKeyForm: {
            name: 'Name',
            nameHint: 'API key name',
            expiry: 'Expires',
            expiryHint: 'Key expiration date',
            never: 'Never',
        },
        showApiKey: 'View API Key',
        showApiKeyWarn: 'Copy and save this key, it will not be visible again.',
        aktable: {
            search: 'Search...',
            name: 'Name',
            created: 'Created',
            expires: 'Expires',
            actions: 'Actions',
        },
        cback: 'Callback',
        dynamicBills: 'Dynamic Bills',
        dynamicTypeForm: {
            typesLabel: 'Permissions:',
            types: {
                1: 'All operations',
                2: 'Selected only',
            },
            selected: 'Selected operations',
        },
        staticBills: 'Static Bills',
        staticTypeForm: {
            typesLabel: 'Permissions:',
            types: {
                1: 'All items',
                2: 'Selected only',
            },
            selected: 'Selected items',
        },
        timerBills: 'Timer Bills',
        timerTypeForm: {
            typesLabel: 'Permissions:',
            types: {
                1: 'All operations',
                2: 'Selected only',
            },
            selected: 'Selected operations',
        },
        messages: {
            'TERMINAL_DELETED': 'Terminal deleted',
            'APIKEY_ADDED': 'API key added',
            'APIKEY_DELETED': 'API key deleted',
        },
        lockingHint: 'Lock terminal to stop accepting payments while terminal is busy or off duty',
        locked: 'Terminal is locked',
        unlocked: 'Terminal is unlocked',
        actions: 'Actions',
        buttons: {
            unlock: {
                label: 'Unlock',
                hint: 'Unlock terminal',
                confirm: 'Unlock this terminal?',
                confirmHint: 'This will unlock the terminal and allow payments',
            },
            lock: {
                label: 'Lock',
                hint: 'Lock terminal',
                confirm: 'Lock this terminal?',
                confirmHint: 'This will lock the terminal and stop payments',
            },
        },

    },


    // Payment channels screen
    paychannels: {
        paychannels: 'Payment Channels',
        searchPaychannels: 'Search payment channels',
        sharedchannels: 'Shared Channels',
        searchSharedChannels: 'Search shared payment channels',
        addNewHint: 'Add new payment channel',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        table: {
            search: 'Search...',
            inst: 'Institution',
            code: 'Code',
            name: 'Name',
            driver: 'Driver',
            status: 'Status',
            actions: 'Actions',
            allInsts: 'All',
            noChannels: 'No channels defined',
            noShared: 'No channels shared',
        },
        newPaychannel: 'New Payment Channel',
        newForm: {
            code: 'Code',
            codeHint: 'Channel code',
            name: 'Name',
            nameHint: 'Channel name',
        },
        messages: {
            'PAYCHANNEL_ADDED': 'New channel created',
        },
    },


    // Payment channel screen
    paychannel: {
        backHint: 'Back to channels',
        paychannel: 'Payment Channel',
        sharedpaychannel: 'Shared Channel',
        delete: 'Delete Channel',
        deleteHint: 'Delete payment channel definition. Delete is not possible if bill or payment history is not empty for this channel. History has to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete payment channel definition?',
        confirmDeleteHint: 'This will delete payment channel and all related data',
        status: {
            0: 'All',
            1: 'Enabled',
            2: 'Disabled',
        },
        paychannelForm: {
            code: 'Code',
            codeHint: 'Channel code',
            name: 'Name',
            nameHint: 'Channel name',
            status: 'Status',
            driver: 'Driver',
            address: 'Address',
            addressHint: 'Merchant address',
            publicContact: 'Public contact',
            publicContactHint: 'Public contact information',
            adminContact: 'Admin contact',
            adminContactHint: 'Admin contact information',
            legalInfo: 'Legal information',
            legalInfoHint: 'Channel legal information',
        },
        apikeys: 'API Keys',
        noApiKeys: 'No API keys created',
        addNewApikey: 'Add new API key',
        viewApiKey: 'Show API key',
        deleteApiKey: 'Delete API key',
        deleteApiKeyConfirm: 'Delete this API key?',
        newApiKey: 'New API Key',
        newApiKeyForm: {
            name: 'Name',
            nameHint: 'API key name',
            expiry: 'Expires',
            expiryHint: 'Key expiration date',
            never: 'Never',
        },
        showApiKey: 'View API Key',
        showApiKeyWarn: 'Copy and save this key, it will not be visible again.',
        aktable: {
            search: 'Search...',
            name: 'Name',
            created: 'Created',
            expires: 'Expires',
            actions: 'Actions',
        },
        cback: 'Callback',
        shared: 'Shared With',
        sharedHint: 'Institutions that have access to this payment channel',
        sharedForm: {
            insts: 'Institutions',
        },
        perm: 'Permissions',
        permstable: {
            all: 'All',
            selected: 'selected',
            name: 'Rule name',
            inst: 'Institution',
            merchant: 'Merchant',
            dynamic: 'Dynamic',
            static: 'Static',
            timer: 'Timer',
            actions: 'Actions',
            deletePerm: 'Delete rule',
            deletePermConfirm: 'Delete this permission rule?',
            configDynamic: 'Configure dynamic bills',
            configStatic: 'Configure static bills',
            configTimer: 'Configure timer bills',
            noPerms: 'No permissions defined',
        },
        addNewPerm: 'New permission',
        addNewPermHint: 'Add new permission',
        newPermForm: {
            merchant: 'Merchant',
            allMerchants: 'All merchants',
        },
        editDynamicPerm: 'Edit permission',
        editDynamicForm: {
            merchant: 'Merchant',
            dynamicType: 'Permission',
            typesLabel: 'Merchant operations:',
            dynamicTypes: {
                1: 'All operations',
                2: 'Selected only',
            },
            dynamicTypesSel: 'Selected operations',
        },
        editStaticPerm: 'Edit permission',
        editStaticForm: {
            merchant: 'Merchant',
            staticType: 'Permission',
            typesLabel: 'Merchant items:',
            staticTypes: {
                1: 'All items',
                2: 'Selected only',
            },
            staticTypesSel: 'Selected items',
        },
        editTimerPerm: 'Edit permission',
        editTimerForm: {
            merchant: 'Merchant',
            timerType: 'Permission',
            typesLabel: 'Merchant operations:',
            timerTypes: {
                1: 'All operations',
                2: 'Selected only',
            },
            timerTypesSel: 'Selected operations',
        },
        messages: {
            'PAYCHANNEL_DELETED': 'Channel deleted',
            'APIKEY_ADDED': 'API key added',
            'APIKEY_DELETED': 'API key deleted',
            'PERMRULE_DELETED': 'Rule deleted',
            'PERMRULE_ADDED': 'Rule added',
            'PERMRULE_SAVED': 'Rule saved',
        },
    },


    // API log screen
    apilog: {
        search: 'Search',
        uuidSearch: 'Search by UUID',
        advancedSearch: 'Advanced search',
        results: 'API Log',
        noResults: 'No rows selected',
        noMore: 'No more rows',
        loadMore: 'Load more',
        when: 'When',
        route: 'Route',
        httpStatus: 'HTTP',
        rejectCode: 'Reject code',
        msec: 'msec.',
        actions: 'Actions',
        api: {
            1: 'Terminal',
            2: 'Paychannel',
            3: 'User',
        },
        map: {
            1: 'Institution',
            2: 'Merchant',
            3: 'Terminal',
            4: 'Paychannel',
            5: 'User',
            10: 'Dynamic Bill',
            11: 'Static Bill',
            12: 'Timer Bill',
            20: 'Payment',
            21: 'Reversal',
            22: 'Refund',
            98: 'Callback',
            99: 'API Call',
        },
        searchUuidForm: {
            uuid: 'UUID',
            uuidHint: 'API log or error response UUID',
        },
        searchAdvancedForm: {
            onlyErrors: 'Only errors',
            api: 'API',
            channels: 'Paychannel',
            merchants: 'Merchant',
            terminals: 'Terminal',
            user: 'User',
            date: 'Date',
            fromTime: 'Time min',
            toTime: 'Time max',
            fromDuration: 'msec. Min',
            toDuration: 'msec. Max',
        },
        dialogTitle: 'API Log Details',
        uuid: 'UUID',
        start_at: 'Start at',
        finish_at: 'Finish at',
        duration: 'Duration',
        response: 'Response code',
        success: 'Success',
        mapping: 'Mapping:',
        timeline: 'Timeline:',
        rawData: 'Request / Response',
        requestData: 'Request:',
        responseData: 'Response:',
        logData: 'Log:',
    },


    // Callback log screen
    cblog: {
        search: 'Search',
        uuidSearch: 'Search by UUID',
        advancedSearch: 'Advanced search',
        results: 'Callback Log',
        noResults: 'No rows selected',
        noMore: 'No more rows',
        loadMore: 'Load more',
        when: 'When',
        res: 'Result',
        httpStatus: 'HTTP',
        rejectCode: 'Reject code',
        msec: 'msec.',
        cbtype: 'Type',
        actions: 'Actions',
        type: {
            1: 'Terminal',
            2: 'Paychannel',
        },
        result: {
            0: 'Unknown',
            1: 'Disabled',
            2: 'Sent',
            3: 'Reject',
            4: 'Failed',
            5: 'Timeout',
            6: 'Queued',
        },
        map: {
            1: 'Institution',
            2: 'Merchant',
            3: 'Terminal',
            4: 'Paychannel',
            10: 'Dynamic Bill',
            11: 'Static Bill',
            12: 'Timer Bill',
            20: 'Payment',
            21: 'Reversal',
            22: 'Refund',
            98: 'Callback',
            99: 'API Call',
        },
        searchUuidForm: {
            uuid: 'UUID',
            uuidHint: 'Callback message UUID',
        },
        searchAdvancedForm: {
            onlyErrors: 'Only errors',
            type: 'Type',
            channels: 'Paychannel',
            merchants: 'Merchant',
            terminals: 'Terminal',
            date: 'Date',
            fromTime: 'Time min',
            toTime: 'Time max',
            fromDuration: 'msec. Min',
            toDuration: 'msec. Max',
        },
        dialogTitle: 'Callback Log Details',
        uuid: 'UUID',
        start_at: 'Start at',
        finish_at: 'Finish at',
        duration: 'Duration',
        function: 'Function',
        success: 'Success',
        response: 'Result',
        mapping: 'Mapping',
        rawData: 'Request / Response',
        requestData: 'Request:',
        responseData: 'Response:',
    },


    // Bill log screen
    billlog: {
        selectType: 'Type:',
        types: {
            1: 'Dynamic',
            2: 'Static',
            3: 'Timer',
        },
        statuses: {
            1: 'Pending', 
            2: 'Paid', 
            3: 'Completed', 
            4: 'Cancelled', 
            5: 'Expired', 
        },
        paymentStatuses: {
            1: 'Success', 
            2: 'Failed', 
            3: 'Reversed', 
        },
        reversalStatuses: {
            1: 'Success', 
            2: 'Manual', 
            3: 'Failed', 
            4: 'Suspect', 
        },
        refundStatuses: {
            1: 'Success', 
            2: 'Manual', 
            3: 'Failed', 
            4: 'Suspect', 
        },
        cbresults: {
            0: 'None',
            1: 'Disabled',
            2: 'Sent',
            3: 'Reject',
            4: 'Failed',
            5: 'Timeout',
            6: 'Queued',
        },
        search: 'Search',
        uuidSearch: 'Search by UUID',
        advancedSearch: 'Advanced search',
        results: 'Bills',
        noResults: 'No rows selected',
        noMore: 'No more rows',
        loadMore: 'Load more',
        when: 'When',
        type: 'Type',
        merchant: 'Merchant',
        terminal: 'Terminal',
        amount: 'Amount',
        actions: 'Actions',
        status: 'Status',
        cbresult: 'Callback',
        operation: 'Operation',
        searchUuidForm: {
            uuid: 'UUID',
            uuidHint: 'Bill UUID',
        },
        searchAdvancedForm: {
            status: 'Status',
            merchants: 'Merchant',
            terminals: 'Terminal',
            date: 'Date',
            fromTime: 'Time min',
            toTime: 'Time max',
            fromAmount: 'Amount min',
            toAmount: 'Amount max',
            currency: 'Currency',
        },
        dialogTitle: 'Bill Details',
        validTill: 'Valid till',
        amountInitial: 'Initial amount',
        amountCalc: 'Calculated amount',
        amountPaid: 'Paid amount',
        amountFinal: 'Final amount',
        amountRefunded: 'Refunded amount',
        createdAt: 'Created',
        paidAt: 'Paid',
        confirmedAt: 'Confirmed',
        cancelledAt: 'Cancelled',
        calcAt: 'Calculated',
        calcTill: 'Payable till',
        mapping: 'Mapping',
        order: 'Order data:',
        orderCode: 'Order code',
        orderDescr: 'Order description',
        orderItems: 'Order items:',
        orderTaxes: 'Order taxes:',
        orderDiscounts: 'Order discounts:',
        stan: 'STAN',
        terminalData: 'Terminal data:',
        paymentData: 'Payment data:',
        hostData: 'Host data:',
        calcData: 'Calculation:',
        customData: 'Custom data',
        payments: 'Payments',
        payment: 'Payment',
        reversal: 'Reversal',
        refund: 'Refund',
        refundHint: 'Create a customer refund request for a merchandise return',
        refundAmount: 'Refund amount',
        maxAmount: 'Max amount',
        cancelHint: 'Cancel this bill and reverse all payments',
        confirmHint: 'Confirm this bill and set the final amount',
        confirmAmount: 'Final amount',
        apilog: 'API Log',
        cblog: 'Callbacks',
        buttons: {
            cancel: {
                label: 'Cancel bill',
                hint: 'Cancel bill and reverse all payments',
                confirm: 'Cancel this bill?',
                confirmHint: 'This will cancel this bill and reverse all payments',
            },
            refund: {
                label: 'Refund',
                hint: 'Create refund request',
            },
            confirm: {
                label: 'Confirm bill',
                hint: 'Confirm bill and set the final amount',
            },
        },
    },


    // Payment log screen
    paylog: {
        dialogTitle: 'Payment Details',
        channel: 'Paychannel',
        merchant: 'Merchant',
        bill: 'Bill',
        status: 'Status',
        createdAt: 'Created',
        amountPaid: 'Paid amount',
        amountReversed: 'Reversed amount',
        amountRefunded: 'Refunded amount',
        mapping: 'Mapping',
        revrefs: 'Reversals / Refunds',
        customData: 'Custom data',
        refundData: 'Refund data:',
        payerData: 'Payer data:',
        terminalData: 'Terminal data:',
        hostData: 'Host data:',
    },


    // Reversal log screen
    revlog: {
        dialogTitle: 'Reversal Details',
        channel: 'Paychannel',
        merchant: 'Merchant',
        status: 'Status',
        createdAt: 'Created',
        amount: 'Reversal amount',
        mapping: 'Mapping',
        customData: 'Custom data',
        payerData: 'Payer data:',
        hostData: 'Host data:',
        payment: 'Payment',
    },


    // Refund log screen
    reflog: {
        dialogTitle: 'Refund Details',
        channel: 'Paychannel',
        merchant: 'Merchant',
        status: 'Status',
        createdAt: 'Created',
        amount: 'Refund amount',
        mapping: 'Mapping',
        customData: 'Custom data',
        payerData: 'Payer data:',
        refundData: 'Refund data:',
        payment: 'Payment',
    },


    // Reports screen
    reports: {
        under: 'Reports section is under construction...',
    },


    // Stress test screen
    stress: {
        stress: 'Stress test',
        threads: 'Threads',
        totals: 'Totals',
        chart: 'Chart',
        done: 'Done:',
        errors: 'Errors:',
        queue: 'Queue:',
        totalsForm: {
            startAt: 'Started at: ',
            finishAt: 'Finished at: ',
            threads: 'Threads: ',
            done: 'Done (pairs): ',
            errors: 'Errors: ',
            interval: 'Interval: ',
        },
        stressForm: {
            url: 'API base URL',
            terminals: 'Terminal keys',
            channels: 'Channel keys',
            threads: 'Threads',
            interval: 'Interval',
        },
        buttons: {
            start: {
                label: 'Start',
                confirm: 'Start stress test?',
            },
            stop: {
                label: 'Stop',
                confirm: 'Stop stress test?',
            },
        },
    },


    // Perfomance view screen
    perfview: {
        perfview: 'Performance Log',
        chartTps: 'TPS Chart',
        chartRsp: 'Response Time Chart',
        perfAlert: 'Using breakdown per second with long time periods can seriously affect performance of backend server and your browser.',
        perfWarn: 'Using breakdown per minute with long time periods can affect performance of backend server and your browser.',
        routes: 'Routes',
        allRoutes: 'All routes',
        totalReqs: 'Total requests:',
        perfForm: {
            date: 'Date',
            fromTime: 'Min',
            toTime: 'Max',
            api: 'API',
            granula: 'Breakdown',
            stacked: 'Show stacked',
        },
        granula: {
            1: 'Per second',
            2: 'Per minute',
            3: 'Per hour',
        },
        buttons: {
            view: {
                label: 'View report',
                hint: 'View performance report',
            },
        },
    },


    // Users screen
    users: {
        users: 'Users',
        addNewHint: 'Create new user',
        searchUsers: 'Search users',
        newUser: 'New user',
        table: {
            noUsers: 'No users defined',
            search: 'Search...',
            username: 'Username',
            name: 'Name',
            role: 'Role',
            status: 'Status',
            actions: 'Actions',
        },
        newForm: {
            username: 'Username',
            usernameHint: 'Login username',
            name: 'Name',
            nameHint: 'Firstname and lastname',
            email: 'Email',
            emailHint: 'User email',
            role: 'Role',
        },
        status: {
            0: 'All',
            1: 'OK',
            2: 'Disabled',
        },
        roles: {
            0: 'All',
            1: 'Superuser',
            2: 'Admin',
        },
        messages: {
            USER_ADDED: 'User created',
        },
    },


    // Users screen
    user: {
        user: 'User',
        setPassword: 'Set Password',
        insts: 'Institutions',
        backHint: 'Back to users',
        userForm: {
            username: 'Username',
            usernameHint: 'Login username',
            name: 'Name',
            nameHint: 'Firstname and lastname',
            email: 'Email',
            emailHint: 'User email',
            status: 'Status',
            role: 'Role',
        },
        passForm: {
            password: 'Password',
            passwordHint: 'New user password',
            newPassword: 'New password',
            newPassword2: 'Confirm new password',
        },
        instsForm: {
            insts: 'Institutions',
        },
        status: {
            1: 'OK',
            2: 'Disabled',
        },
        roles: {
            1: 'Superuser',
            2: 'Admin',
        },
        delete: 'Delete User',
        deleteHint: 'Delete user and all its data from the system',
        confirmDelete: 'Delete user definition?',
        confirmDeleteHint: 'This will delete user definition and remove all its data',
        messages: {
            'USER_DELETED': 'User deleted',
        },
    },


    // Currencies screen
    currencies: {
        currencies: 'Currencies',
        addNewHint: 'Add new currency',
        searchHint: 'Search currencies',
        newCurrency: 'Add currency',
        table: {
            noData: 'No currencies added',
            code: 'Code',
            name: 'Name',
            decimals: 'Decimals',
            actions: 'Actions',
            search: 'Search...',
        },
        newForm: {
            code: 'Code',
            codeHint: 'Currency code',
            name: 'Name',
            nameHint: 'Currency name',
            decimals: 'Decimals', 
        },
        messages: {
            CURRENCY_ADDED: 'Currency added',
        },
    },


    // Currency screen
    currency: {
        currency: 'Currency',
        backHint: 'Back to currencies',
        currencyForm: {
            code: 'Code',
            codeHint: 'Currency code',
            name: 'Name',
            nameHint: 'Currency name',
            decimals: 'Decimals', 
        },
        delete: 'Delete Currency',
        deleteHint: 'Delete currency definition from the system. Delete is not possible if currency is used in bill definitions or history. Related items have to be removed first otherwise request will be rejected with backend server error.',
        confirmDelete: 'Delete currency?',
        confirmDeleteHint: 'This will delete currency definition',
        messages: {
            'CURRENCY_DELETED': 'Currency deleted',
        },

    },
}