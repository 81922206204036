<template>
    <v-text-field
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        autocomplete="new-password"
        :type="show ? 'text' : 'password'"
        :append-icon="show ? mdiEye : mdiEyeOff"
        @click:append="show = !show"
        @input="input"
    >
        <template v-slot:append-outer>
            <IconButton
                :disabled="disabled"
                :icon="mdiKeyStar"
                :hint="System.lang('keys.generateApiKey')"
                :color="color"
                :fn="generateKey"
                :confirm="System.lang('keys.confirmGenApiKey')"
                :confirmIcon="mdiKeyStar"
                :ok="System.lang('keys.messages.APIKEY_GENERATED')"
                :error="System.lang('messages.REQUEST_FAILED')"
            />
            <IconButton
                :icon="mdiContentCopy"
                :hint="System.lang('buttons.copy.hint')"
                :color="color"
                :disabled="disabled || !hasValue"
                :fn="() => {return System.copy(value)}"
            />
        </template>
    </v-text-field>
</template>


<script>
import {
    mdiEye,
    mdiEyeOff,
    mdiKeyStar,
    mdiContentCopy,
} from '@mdi/js';

import System from '@/classes/System'
import Api from '@/services/api'

import IconButton from '@/components/system/IconButton'

export default {
    name: 'ApiKeyInput',
    inheritAttrs: false,
    components: {
        IconButton,
    },
    props: {
        value: {
            type: String,
            default: '',
        },        
        color: {
            type: String,
            default: 'primary',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiEye,
            mdiEyeOff,
            mdiKeyStar,
            mdiContentCopy,
            // Data
            show: false,
        }
    },
    computed: {
        hasValue () {
            return this.value?.length > 0;
        },
    },
    methods: {
        async generateKey () {
            return Api.get('/admin/genapikey')
                .then(data => {
                    this.$emit('input', data.key);
                });
        },
        input (event) {
            this.$emit('input', event);
        },
    },
    mounted () {
    },

}
</script>


<style scoped>
</style>
