import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import admin from './admin'
import monitor from './monitor'
import support from './support'
import testing from './testing'

import Settings from '@/constants/settings'

import LoginView from '@/components/views/LoginView'
import HomeView from '@/components/views/HomeView'

Vue.use(VueRouter)

function user() {
    return store.state.user.profile;
}

function onlyNonAuth(to, from, next) {
    if (user()) {
        next({name: 'home'});
    } else {
        next();
    }
}

const routes = [

    // Home
    {
        path: '/home',
        name: 'home',
        component: HomeView,
    },

    // Login
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        beforeEnter: onlyNonAuth,
    },

    // Default
    {
        path: '*',
        redirect: {name: 'home'},
    },
].concat(admin).concat(monitor).concat(support).concat(testing);

const router = new VueRouter({
    mode: (Settings.useNiceUrl ? 'history' : 'hash'),
    base: process.env.BASE_URL,
    routes,
})

export default router
