<template>
<div>
    <v-form ref="form" @input="v => $emit('input', v)" @submit.prevent>
        <slot></slot>
    </v-form>
</div>
</template>


<script>
export default {
    name: 'SimpleForm',
    components: {
    },
    props: {
        // form: {
        //     type: [Object],
        //     required: true,
        // },
    },
    data () {
        return {
            // Objects
            // Icons
            // Data
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
        this.$emit('ref', this.$refs.form);
    },
}
</script>


<style scoped>
</style>
