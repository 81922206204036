<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <InstSelector
            @selected="instId = $event; init()"
        />
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="instId">
        <HeaderPanel
            :title="System.lang('merchants.merchants')"
            :icon="mdiStorefrontOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiStorefrontPlusOutline"
                :hint="System.lang('merchants.addNewHint')"
                :fn="() => {newDialog.open()}"
            />
            <IconButton
                v-if="merchantsTable.hasData()"
                :color="showSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('merchants.searchMerchants')"
                :fn="() => {showSearch = !showSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <DataTable 
            :table="merchantsTable" 
            :search="search"
            :no-data-text="System.lang('merchants.table.noMerchants')" 
        >
            <template v-slot:[`body.prepend`] v-if="merchantsTable.hasData() && showSearch">
                <tr>
                    <td colspan="3" class="px-1">
                        <v-text-field
                            v-model="search"
                            :label="System.lang('merchants.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="searchStatus"
                            :items="statusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == MERCHANT_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('merchants.status.' + item['status'])"
                    :color="item['status'] == MERCHANT_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-merchant', params: {id: item.id}}"
                />
            </template>
        </DataTable>

    </v-sheet>

    <SimpleDialog
        :dialog="newDialog"
        :title="System.lang('merchants.newMerchant')"
        :icon="mdiStorefrontPlusOutline"
        @close="newDialog.close()"
    >
        <SimpleForm 
            @ref="newForm.ref = $event"
            @input="newForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('merchants.newForm.code')"
                        :hint="System.lang('merchants.newForm.codeHint')"
                        counter="25"
                        v-model="newForm.values.code"
                        :rules="newForm.rules.code"
                        :error-messages="newForm.errors.code"
                        @click="newForm.errors.code = []"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('merchants.newForm.name')"
                        :hint="System.lang('merchants.newForm.nameHint')"
                        counter="50"
                        v-model="newForm.values.name"
                        :rules="newForm.rules.name"
                        :error-messages="newForm.errors.name"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addMerchant"
                :disabled="!newForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiLifebuoy,
    mdiRefresh,
    mdiStorefrontOutline,
    mdiStorefrontPlusOutline,
    mdiStorefrontCheckOutline,
    mdiStorefrontRemoveOutline,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import Api from '@/services/api'
import {MERCHANT_STATUS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import InstSelector from '@/components/parts/InstSelector'
import IconButton from '@/components/system/IconButton'
import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'MerchantsView',
    components: {
        HeaderPanel,
        InstSelector,
        // ActionForm,
        IconButton,
        IconLink,
        // IconAction,
        IconHint,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiLifebuoy,
            mdiRefresh,
            mdiStorefrontOutline,
            mdiStorefrontPlusOutline,
            mdiStorefrontCheckOutline,
            mdiStorefrontRemoveOutline,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            // Globals
            System,
            MERCHANT_STATUS,
            // Data
            instId: undefined,
            showSearch: false,
            search: '',
            searchStatus: 0,
            statusItems: [
                {value: 0, text: System.lang('merchants.status.0')},
                {value: 1, text: System.lang('merchants.status.1')},
                {value: 2, text: System.lang('merchants.status.2')},
            ],
            // Forms
            newForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            // Tables
            merchantsTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('merchants.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('merchants.table.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterStatus,
                },
                {
                    text: System.lang('merchants.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'merchants-table', this.fetchMerchants),
            // Dialogs
            newDialog: new Dialog(() => {this.newForm.reset()}),
        }
    },
    computed: {
        // ...mapState({
        //     sites: state => state.user.sites,
        // }),
    },
    methods: {
        init () {
            return this.merchantsTable.load();    
        },
        async fetchMerchants () {
            this.search = '';
            this.searchStatus = 0;

            return Api.get(`/admin/insts/${this.instId}/merchants`)
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addMerchant () {
            if (this.newForm.validate()) {
                return Api.post(`/admin/insts/${this.instId}/merchants`, this.newForm.values)
                    .then(data => {
                        this.newDialog.close();
                        this.merchantsTable.add(data);
                        System.redirectTo('admin-merchant', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('merchants.messages.MERCH_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        filterStatus (value) {
            return !this.searchStatus || value == this.searchStatus;
        },
    },
    mounted () {
        System.setTitle('merchants.merchants');
        // this.init();
    },
}
</script>


<style scoped>
</style>
