<template>
<div>

    <!-- Data -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('staticType.staticType')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="staticType"
                :icon="mdiBackburger"
                :hint="System.lang('staticType.backHint')"
                :fn="() => {System.redirectTo('admin-merchant', {params: {id: staticType.merchant_id}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="staticType" 
            :fn="updateType"
            @errors="staticTypeForm.setErrors($event)"
        >
            <v-row dense>
                <v-col cols="6" sm="2" md="3">
                    <v-text-field
                        :label="System.lang('staticType.staticTypeForm.code')"
                        :hint="System.lang('staticType.staticTypeForm.codeHint')"
                        counter="25"
                        v-model="staticType.code"
                        :rules="staticTypeForm.rules.code"
                        :error-messages="staticTypeForm.errors.code"
                        @input="staticTypeForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7" md="6">
                    <v-text-field
                        :label="System.lang('staticType.staticTypeForm.name')"
                        :hint="System.lang('staticType.staticTypeForm.nameHint')"
                        counter="50"
                        v-model="staticType.name"
                        :rules="staticTypeForm.rules.name"
                        :error-messages="staticTypeForm.errors.name"
                        @input="staticTypeForm.resetError('name')"
                    >
                        <template v-slot:append>
                            <LangText
                                :label="System.lang('staticType.staticTypeForm.name')"
                                :hint="System.lang('staticType.staticTypeForm.nameHint')"
                                counter="50"
                                v-model="staticType.name_ml"
                                :rules="staticTypeForm.rules.name_ml"
                                @changed="staticTypeForm.resetError('name')"
                            />
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('staticType.staticTypeForm.status')"
                        :hint="System.lang('staticType.staticTypeForm.statusHint')"
                        v-model="staticType.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == STATIC_TYPE_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                    <v-select
                        :items="drivers"
                        :label="System.lang('staticType.staticTypeForm.driver')"
                        v-model="staticType.driver_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>
    
    <!-- Amounts -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="staticType">
        <HeaderPanel
            :title="System.lang('staticType.amounts')"
            :icon="mdiCurrencyUsd"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateAmounts"
            @errors="amountsForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-radio-group 
                        v-model="staticType.amount_type" 
                        :label="System.lang('staticType.amountsForm.type')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('staticType.amountsForm.types.1')" :value="STATIC_BILL_AMOUNT.FIXED"></v-radio>
                        <v-radio :label="System.lang('staticType.amountsForm.types.2')" :value="STATIC_BILL_AMOUNT.VARIABLE"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="4" lg="3">
                    <v-select
                        :items="currencies"
                        :label="System.lang('staticType.amountsForm.currency')"
                        v-model="staticType.currency_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="3" lg="2" v-if="staticType.amount_type == STATIC_BILL_AMOUNT.FIXED">
                    <v-text-field
                        :label="System.lang('staticType.amountsForm.fixed')"
                        :hint="System.lang('staticType.amountsForm.fixedHint')"
                        v-model="staticType.amount_fixed"
                        :rules="amountsForm.rules.amount_fixed"
                        :error-messages="amountsForm.errors.amount_fixed"
                        @click="amountsForm.resetError('amount_fixed')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" lg="2" v-if="staticType.amount_type == STATIC_BILL_AMOUNT.VARIABLE">
                    <v-text-field
                        :label="System.lang('staticType.amountsForm.min')"
                        :hint="System.lang('staticType.amountsForm.minHint')"
                        v-model="staticType.amount_min"
                        :rules="amountsForm.rules.amount_min"
                        :error-messages="amountsForm.errors.amount_min"
                        @click="amountsForm.resetError('amount_min'); amountsForm.resetError('amount_max')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" lg="2" v-if="staticType.amount_type == STATIC_BILL_AMOUNT.VARIABLE">
                    <v-text-field
                        :label="System.lang('staticType.amountsForm.max')"
                        :hint="System.lang('staticType.amountsForm.maxHint')"
                        v-model="staticType.amount_max"
                        :rules="amountsForm.rules.amount_max"
                        :error-messages="amountsForm.errors.amount_max"
                        @click="amountsForm.resetError('amount_max'); amountsForm.resetError('amount_min')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="staticType">
        <HeaderPanel
            :title="System.lang('staticType.delete')"
            :hint="System.lang('staticType.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('staticType.delete')"
            :ok="System.lang('staticType.messages.TYPE_DELETED')"
            :confirm="System.lang('staticType.confirmDelete')"
            :confirmHint="System.lang('staticType.confirmDeleteHint')"
            :fn="deleteType"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiTextBoxOutline,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiCurrencyUsd,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Form} from '@/classes/Elements'
import {STATIC_TYPE_STATUS, STATIC_BILL_AMOUNT} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import LangText from '@/components/system/LangText'
import ActionForm from '@/components/forms/ActionForm'
import DeleteActionButton from '@/components/parts/DeleteActionButton'

export default {
    name: 'StaticTypeView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        DeleteActionButton,
        LangText,
    },
    data () {
        return {
            // Icons
            mdiTextBoxOutline,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            mdiCurrencyUsd,
            // Globals
            System,
            STATIC_TYPE_STATUS,
            STATIC_BILL_AMOUNT,
            // Data
            loading: false,
            staticTypeId: undefined,
            staticType: undefined,
            drivers: [],
            currencies: [],
            statusItems: [
                {value: 1, text: System.lang('staticType.status.1')},
                {value: 2, text: System.lang('staticType.status.2')},
            ],
            // Forms
            staticTypeForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                name_ml: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 50) || System.lang('val.shorter'),
                ],
                status: [],
                driver_id: [],
            }, {'name_ml': 'name'}),
            amountsForm: new Form({
                currency_id: [],
                amount_fixed: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+(\.\d+)?$/.test(v) || System.lang('val.amount'),
                    (v) => (+v > 0) || System.lang('val.amount'),
                ],
                amount_min: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+(\.\d+)?$/.test(v) || System.lang('val.amount'),
                    (v) => (+v >= 0) || System.lang('val.amount'),
                ],
                amount_max: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+(\.\d+)?$/.test(v) || System.lang('val.amount'),
                    (v) => (+v >= 0) || System.lang('val.amount'),
                    (v) => (+v > +this.staticType.amount_min) || System.lang('val.more'),
                ],
            }),
            // Tables
            // Dialogs
        }
    },
    methods: {
        init () {
            this.staticTypeId = this.$route.params.id;
            this.fetchData();
        },
        setData (data) {
            this.staticType = data.type;
            this.drivers = data.drivers;
            this.currencies = data.currencies;
        },
        async fetchData () {
            this.staticType = undefined;
            this.loading = true;

            return Api.get(`/admin/static-types/${this.staticTypeId}`)
                .then(data => {
                    this.setData(data);
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateType () {
            return Api.patch(`/admin/static-types/${this.staticTypeId}`, this.staticType).then(data => this.setData(data));
        },
        async updateAmounts () {
            return Api.patch(`/admin/static-types/${this.staticTypeId}/amounts`, this.staticType).then(data => this.setData(data));
        },
        async deleteType () {
            return Api.delete(`/admin/static-types/${this.staticTypeId}`, {})
                .then(() => {
                    System.redirectTo('admin-merchant', {params: {id: this.staticType.merchant_id}});
                });
        },
    },
    mounted () {
        System.setTitle('staticType.staticType');
        this.init();
    },
}
</script>


<style scoped>
</style>
