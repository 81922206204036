<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('insts.insts')"
            :icon="mdiDomain"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiDomainPlus"
                :hint="System.lang('insts.addNewHint')"
                :fn="() => {newDialog.open()}"
            />
            <IconButton
                v-if="instsTable.hasData()"
                :color="showSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('insts.searchHint')"
                :fn="() => {showSearch = !showSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <DataTable 
            :table="instsTable" 
            :search="search"
            :no-data-text="System.lang('insts.table.noInsts')" 
        >
            <template v-slot:[`body.prepend`] v-if="instsTable.hasData() && showSearch">
                <tr>
                    <td colspan="5" class="px-1">
                        <v-text-field
                            v-model="search"
                            :label="System.lang('insts.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="mdiPrinterPosOutline"
                    :hint="System.lang('insts.termapi') + ': ' + System.lang('insts.apistatus.' + item['status_termapi'])"
                    :color="apiStatusColor(item['status_termapi'])"
                />
                <IconHint
                    :icon="mdiCreditCardOutline"
                    :hint="System.lang('insts.payapi') + ': ' + System.lang('insts.apistatus.' + item['status_payapi'])"
                    :color="apiStatusColor(item['status_payapi'])"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-inst', query: {inst: item.id}}"
                />
            </template>
        </DataTable>

    </v-sheet>

    <SimpleDialog
        :dialog="newDialog"
        :title="System.lang('insts.newInst')"
        :icon="mdiDomainPlus"
        @close="newDialog.close()"
    >
        <SimpleForm 
            @ref="newForm.ref = $event"
            @input="newForm.valid = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('insts.newForm.code')"
                        :hint="System.lang('insts.newForm.codeHint')"
                        counter="25"
                        v-model="newForm.values.code"
                        :rules="newForm.rules.code"
                        :error-messages="newForm.errors.code"
                        @input="newForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('insts.newForm.name')"
                        :hint="System.lang('insts.newForm.nameHint')"
                        counter="50"
                        v-model="newForm.values.name"
                        :rules="newForm.rules.name"
                        :error-messages="newForm.errors.name"
                        @input="newForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6" sm="4">
                    <v-text-field
                        :label="System.lang('insts.newForm.mid')"
                        :hint="System.lang('insts.newForm.midHint')"
                        counter="25"
                        v-model="newForm.values.mid"
                        :rules="newForm.rules.mid"
                        :error-messages="newForm.errors.mid"
                        @input="newForm.resetError('mid')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addInst"
                :disabled="!newForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiMagnify,
    mdiLifebuoy,
    mdiRefresh,
    mdiDomain,
    mdiDomainPlus,
    mdiCogOutline,
    mdiStorefrontOutline,
    mdiPrinterPosOutline,
    mdiCreditCardOutline,
} from '@mdi/js';

// import { mapState } from 'vuex'

import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import Api from '@/services/api'
import {API_STATUS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
// import IconAction from '@/components/system/IconAction'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'InstsView',
    components: {
        HeaderPanel,
        // ActionForm,
        IconButton,
        IconLink,
        // IconAction,
        IconHint,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiMagnify,
            mdiLifebuoy,
            mdiRefresh,
            mdiDomain,
            mdiDomainPlus,
            mdiCogOutline,
            mdiStorefrontOutline,
            mdiPrinterPosOutline,
            mdiCreditCardOutline,
            // Globals
            System,
            // Internal
            showSearch: false,
            search: '',
            // Data
            // Forms
            newForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                mid: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            // Tables
            instsTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: false,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('insts.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('insts.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('insts.table.mid'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'mid',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('insts.table.status'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'status',
                    width: '200px',
                },
                {
                    text: System.lang('insts.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'insts-table', this.fetchInsts),
            // Dialogs
            newDialog: new Dialog(() => {this.newForm.reset()}),
        }
    },
    computed: {
    },
    methods: {
        init () {
            return this.instsTable.load();    
        },
        async fetchInsts () {
            return Api.get('/admin/insts')
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addInst () {
            if (this.newForm.validate()) {
                return Api.post('/admin/insts', this.newForm.values)
                    .then(data => {
                        this.newDialog.close();
                        this.instsTable.add(data);
                        User.addInst(data);
                        System.redirectTo('admin-inst', {query: {inst: data.id}});
                        Interface.snackSuccess(System.lang('insts.messages.INST_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        apiStatusColor (status) {
            switch (status) {
                case API_STATUS.ON: return 'success';
                case API_STATUS.OFF: return 'error';
                case API_STATUS.DISABLED: return 'grey';
                default: return 'grey';
            }
        },
    },
    mounted () {
        System.setTitle('insts.insts');
        this.init();
    },
}
</script>


<style scoped>
</style>
