<template>
    <ActionButton
        text
        :icon="mdiDeleteForeverOutline"
        :color="color" 
        :label="label || System.lang('buttons.delete')"
        :hint="hint"
        :fn="fn"
        :ok="ok"
        :confirm="confirm"
        :confirmColor="confirmColor"
        :confirmIcon="confirmIcon"
        :confirmHint="confirmHint"
        :confirmYes="confirmYes || System.lang('buttons.delete')"
        :confirmNo="confirmNo"
        :error="System.lang('messages.REQUEST_FAILED')"
    />
</template>


<script>
import {
    mdiDeleteForeverOutline,
} from '@mdi/js';

import System from '@/classes/System'

import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'DeleteActionButton',
    components: {
        ActionButton,
    },
    props: {
        color: {
            type: String,
            default: 'error',
        },
        label: {
            type: [String, Boolean],
            default: false,
        },
        hint: {
            type: String,
            default: '',
        },
        fn: {
            type: Function,
            required: true,
        },
        ok: {
            type: [String, Boolean],
            default: false,
        },
        confirm: {
            type: [String, Boolean],
            default: false,
        },
        confirmColor: {
            type: String,
            default: 'error',
        },
        confirmIcon: {
            type: [String, Boolean],
            default: mdiDeleteForeverOutline,
        },
        confirmHint: {
            type: [String, Boolean],
            default: false,
        },
        confirmYes: {
            type: [String, Boolean],
            default: false,
        },
        confirmNo: {
            type: [String, Boolean],
            default: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiDeleteForeverOutline,
            // Data
            show: false,
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
    },

}
</script>


<style scoped>
</style>
