<template>
    <v-tooltip bottom :disabled="!hint">
        <template v-slot:activator="{on, attrs}">
            <v-chip
                v-bind="{...attrs, ...$attrs}"
                v-on="on"
                :class="'chip ' + classes"
                @click="$emit('click', $event)"
            >
                <slot></slot>
            </v-chip>
        </template>
        <span>{{hint}}</span>
    </v-tooltip>
</template>


<script>
export default {
    name: 'ActionChip',
    inheritAttrs: false,
    props: {
        hint: {
            type: [String, Boolean],
            default: false,
        },
        classes: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            // Icons
            // Data
        }
    },
    computed: {
    },
    methods: {
    },
}
</script>


<style scoped>
.chip {
    cursor: pointer;
}
</style>
