<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <InstSelector
            @selected="instId = $event; init()"
        />
    </v-sheet>


    <!-- Channels -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3"  v-if="instId">
        <HeaderPanel
            :title="System.lang('paychannels.paychannels')"
            :icon="mdiCreditCardOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiCreditCardPlusOutline"
                :hint="System.lang('paychannels.addNewHint')"
                :fn="() => {newDialog.open()}"
            />
            <IconButton
                v-if="channelsTable.hasData()"
                :color="showSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('paychannels.searchPaychannels')"
                :fn="() => {showSearch = !showSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {channelsTable.load()}"
            />
        </HeaderPanel>
        <DataTable 
            :table="channelsTable" 
            :search="search"
            :no-data-text="System.lang('paychannels.table.noChannels')" 
        >
            <template v-slot:[`body.prepend`] v-if="channelsTable.hasData() && showSearch">
                <tr>
                    <td colspan="4" class="px-1">
                        <v-text-field
                            v-model="search"
                            :label="System.lang('paychannels.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="searchStatus"
                            :items="statusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.driver`]="{ item }">
                <span class="grey--text">{{ item.driver.name }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == PAYCHANNEL_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('paychannels.status.' + item['status'])"
                    :color="item['status'] == PAYCHANNEL_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-paychannel', params: {id: item.id, instId: instId}}"
                />
            </template>
        </DataTable>
    </v-sheet>


    <!-- Shared channels -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="instId">
        <HeaderPanel
            :title="System.lang('paychannels.sharedchannels')"
            :icon="mdiCreditCardSyncOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="sharedChannelsTable.hasData()"
                :color="showSharedSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('paychannels.searchSharedChannels')"
                :fn="() => {showSharedSearch = !showSharedSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {sharedChannelsTable.load()}"
            />
        </HeaderPanel>
        <DataTable 
            :table="sharedChannelsTable" 
            :search="sharedSearch"
            :no-data-text="System.lang('paychannels.table.noShared')" 
        >
            <template v-slot:[`body.prepend`] v-if="sharedChannelsTable.hasData() && showSharedSearch">
                <tr>
                    <td colspan="2" class="px-1">
                        <v-select
                            v-model="sharedSearchInst"
                            :items="sharedSearchInsts"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td colspan="2" class="px-1">
                        <v-text-field
                            v-model="sharedSearch"
                            :label="System.lang('paychannels.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="sharedSearchStatus"
                            :items="statusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <!-- <template v-slot:[`item.inst`]="{ item }">
                <span class="">{{ item.inst.name }}</span>
            </template> -->

            <template v-slot:[`item.code`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.code }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == PAYCHANNEL_STATUS.ENABLED ? mdiCheck : mdiClose"
                    :hint="System.lang('paychannels.status.' + item['status'])"
                    :color="item['status'] == PAYCHANNEL_STATUS.ENABLED ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-sharedpaychannel', params: {id: item.id, instId: instId}}"
                />
            </template>
        </DataTable>

    </v-sheet>

    <SimpleDialog
        :dialog="newDialog"
        :title="System.lang('paychannels.newPaychannel')"
        :icon="mdiCreditCardPlusOutline"
        @close="newDialog.close()"
    >
        <SimpleForm 
            @ref="newForm.ref = $event"
            @input="newForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('paychannels.newForm.code')"
                        :hint="System.lang('paychannels.newForm.codeHint')"
                        counter="25"
                        v-model="newForm.values.code"
                        :rules="newForm.rules.code"
                        :error-messages="newForm.errors.code"
                        @input="newForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('paychannels.newForm.name')"
                        :hint="System.lang('paychannels.newForm.nameHint')"
                        counter="50"
                        v-model="newForm.values.name"
                        :rules="newForm.rules.name"
                        :error-messages="newForm.errors.name"
                        @input="newForm.resetError('name')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addChannel"
                :disabled="!newForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiLifebuoy,
    mdiRefresh,
    mdiCreditCardOutline,
    mdiCreditCardSyncOutline,
    mdiCreditCardPlusOutline,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import Api from '@/services/api'
import {PAYCHANNEL_STATUS} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import InstSelector from '@/components/parts/InstSelector'
import IconButton from '@/components/system/IconButton'
import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'PaychannelsView',
    components: {
        HeaderPanel,
        InstSelector,
        // ActionForm,
        IconButton,
        IconLink,
        // IconAction,
        IconHint,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiLifebuoy,
            mdiRefresh,
            mdiCreditCardOutline,
            mdiCreditCardSyncOutline,
            mdiCreditCardPlusOutline,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            // Globals
            System,
            PAYCHANNEL_STATUS,
            // Data
            instId: undefined,
            showSearch: false,
            showSharedSearch: false,
            search: '',
            searchStatus: 0,
            sharedSearch: '',
            sharedSearchStatus: 0,
            sharedSearchInst: 0,
            sharedSearchInsts: [],
            statusItems: [
                {value: 0, text: System.lang('paychannels.status.0')},
                {value: 1, text: System.lang('paychannels.status.1')},
                {value: 2, text: System.lang('paychannels.status.2')},
            ],
            // Forms
            newForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            // Tables
            channelsTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('paychannels.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannels.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannels.table.driver'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'driver',
                    width: '20%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannels.table.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterStatus,
                },
                {
                    text: System.lang('paychannels.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'paychannels-table', this.fetchChannels),
            sharedChannelsTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('paychannels.table.inst'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'inst.name',
                    width: '20%',
                    cellClass: "no-wrap",
                    filter: this.filterSharedInst,
                },
                {
                    text: System.lang('paychannels.table.code'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'code',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannels.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannels.table.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterSharedStatus,
                },
                {
                    text: System.lang('paychannels.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'sharedchannels-table', this.fetchSharedChannels),
            // Dialogs
            newDialog: new Dialog(() => {this.newForm.reset()}),
        }
    },
    computed: {
    },
    methods: {
        init () {
            this.channelsTable.load();
            this.sharedChannelsTable.load();
        },
        async fetchChannels () {
            this.search = '';
            this.searchStatus = 0;
            return Api.get(`/admin/insts/${this.instId}/paychannels`)
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async fetchSharedChannels () {
            this.sharedSearch = '';
            this.sharedSearchStatus = 0;
            this.sharedSearchInst = 0;
            return Api.get(`/admin/insts/${this.instId}/sharedpaychannels`)
                .then(data => {
                    this.sharedSearchInsts.push({value: 0, text: System.lang('paychannels.table.allInsts')});
                    data.forEach(rec => {
                        if (!this.sharedSearchInsts.find(inst => inst.id == rec.inst.id)) {
                            this.sharedSearchInsts.push({value: rec.inst.id, text: rec.inst.name});
                        }
                    });
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addChannel () {
            if (this.newForm.validate()) {
                return Api.post(`/admin/insts/${this.instId}/paychannels`, this.newForm.values)
                    .then(data => {
                        this.newDialog.close();
                        this.channelsTable.add(data);
                        System.redirectTo('admin-paychannel', {params: {id: data.id, instId: this.instId}});
                        Interface.snackSuccess(System.lang('paychannels.messages.PAYCHANNEL_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        filterStatus (value) {
            return !this.searchStatus || value == this.searchStatus;
        },
        filterSharedStatus (value) {
            return !this.sharedSearchStatus || value == this.sharedSearchStatus;
        },
        filterSharedInst (value, serach, item) {
            return !this.sharedSearchInst || item.inst.id == this.sharedSearchInst;
        },
    },
    mounted () {
        System.setTitle('paychannels.paychannels');
        // this.init();
    },
}
</script>


<style scoped>
</style>
