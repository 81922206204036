<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('timerType.timerType')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="timerType"
                :icon="mdiBackburger"
                :hint="System.lang('timerType.backHint')"
                :fn="() => {System.redirectTo('admin-merchant', {params: {id: timerType.merchant_id}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="timerType" 
            :fn="updateType"
            @errors="timerTypeForm.setErrors($event)"
        >
            <v-row dense>
                <v-col cols="6" sm="2" md="3">
                    <v-text-field
                        :label="System.lang('timerType.timerTypeForm.code')"
                        :hint="System.lang('timerType.timerTypeForm.codeHint')"
                        counter="25"
                        v-model="timerType.code"
                        :rules="timerTypeForm.rules.code"
                        :error-messages="timerTypeForm.errors.code"
                        @input="timerTypeForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7" md="6">
                    <v-text-field
                        :label="System.lang('timerType.timerTypeForm.name')"
                        :hint="System.lang('timerType.timerTypeForm.nameHint')"
                        counter="50"
                        v-model="timerType.name"
                        :rules="timerTypeForm.rules.name"
                        :error-messages="timerTypeForm.errors.name"
                        @input="timerTypeForm.resetError('name')"
                    >
                        <template v-slot:append>
                            <LangText
                                :label="System.lang('timerType.timerTypeForm.name')"
                                :hint="System.lang('timerType.timerTypeForm.nameHint')"
                                counter="50"
                                v-model="timerType.name_ml"
                                :rules="timerTypeForm.rules.name_ml"
                                @changed="timerTypeForm.resetError('name')"
                            />
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('timerType.timerTypeForm.status')"
                        :hint="System.lang('timerType.timerTypeForm.statusHint')"
                        v-model="timerType.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == TIMER_TYPE_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                    <v-select
                        :items="drivers"
                        :label="System.lang('timerType.timerTypeForm.driver')"
                        v-model="timerType.driver_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6" sm="2" lg="2">
                    <v-text-field
                        :label="System.lang('timerType.timerTypeForm.payDelay')"
                        :hint="System.lang('timerType.timerTypeForm.payDelayHint')"
                        v-model="timerType.pay_delay"
                        :rules="timerTypeForm.rules.pay_delay"
                        :error-messages="timerTypeForm.errors.pay_delay"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                    <v-select
                        :items="timeUnits"
                        v-model="timerType.pay_delay_units"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>
    
    <!-- Amounts -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="timerType">
        <HeaderPanel
            :title="System.lang('timerType.amounts')"
            :icon="mdiCurrencyUsd"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateAmounts"
            @errors="amountsForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="4" lg="3">
                    <v-select
                        :items="currencies"
                        :label="System.lang('timerType.amountsForm.currency')"
                        v-model="timerType.currency_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="3" lg="2">
                    <v-text-field
                        :label="System.lang('timerType.amountsForm.min')"
                        :hint="System.lang('timerType.amountsForm.minHint')"
                        v-model="timerType.amount_min"
                        :rules="amountsForm.rules.amount_min"
                        :error-messages="amountsForm.errors.amount_min"
                        @click="amountsForm.resetError('amount_min'); amountsForm.resetError('amount_max')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" lg="2">
                    <v-text-field
                        :label="System.lang('timerType.amountsForm.max')"
                        :hint="System.lang('timerType.amountsForm.maxHint')"
                        v-model="timerType.amount_max"
                        :rules="amountsForm.rules.amount_max"
                        :error-messages="amountsForm.errors.amount_max"
                        @click="amountsForm.resetError('amount_max'); amountsForm.resetError('amount_min')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>
    
    <!-- Periods -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="timerType">
        <HeaderPanel
            :title="System.lang('timerType.periods')"
            :icon="mdiClockTimeFourOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiPlusCircleOutline"
                :hint="System.lang('timerType.addPeriod')"
                :fn="() => {openPeriodDialog()}"
            />
        </HeaderPanel>
        <v-data-table
            :headers="periodsHeaders" 
            :items="sortedPeriods"
            :no-data-text="System.lang('timerType.periodsTable.noPeriods')" 
            hide-default-footer
        >
            <template v-slot:[`item.duration`]="{ item }">
                <span class="" v-if="item.period_type == TIMER_BILL_PERIOD.INFINITE">{{System.lang('timerType.periodsTable.infinite')}}</span>
                <span class="" v-else>{{item.period}} {{System.lang(`timerType.timeUnit.${item.period_units}`).toLowerCase()}}</span>
            </template>

            <template v-slot:[`item.step`]="{ item }">
                <span class="">{{item.step}} {{System.lang(`timerType.timeUnit.${item.step_units}`).toLowerCase()}}</span>
            </template>

            <template v-slot:[`item.amount_flat`]="{ item }">
                <span class="">{{item.amount_flat}} {{timerType.currency.code}}</span>
            </template>

            <template v-slot:[`item.amount_step`]="{ item }">
                <span class="">{{item.amount_step}} {{timerType.currency.code}}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    color="primary"
                    :icon="mdiCogOutline"
                    :hint="System.lang('timerType.editPeriod')"
                    :fn="() => {openPeriodDialog(item)}"
                />
                <IconButton
                    color="error"
                    :icon="mdiDeleteForever"
                    :hint="System.lang('timerType.deletePeriod')"
                    :confirm="System.lang('timerType.deletePeriodConfirm')"
                    confirmColor="error"
                    :confirmIcon="mdiDeleteForever"
                    :confirmYes="System.lang('buttons.delete')"
                    :fn="() => {timerType.periods = reject(timerType.periods, item)}"
                />
            </template>

            <template v-slot:[`footer`]>
                <v-divider></v-divider>
            </template>
        </v-data-table>
        <div class="mt-5">
            <ActionButton
                text
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :icon="mdiContentSaveOutline"
                :hint="System.lang('buttons.save.hint')"
                :fn="updatePeriods"
                :error="System.lang('messages.SAVE_ERROR')"
                :ok="System.lang('messages.SAVE_OK')"
            />
        </div>
    </v-sheet>

    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="timerType">
        <HeaderPanel
            :title="System.lang('timerType.delete')"
            :hint="System.lang('timerType.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('timerType.delete')"
            :ok="System.lang('timerType.messages.TYPE_DELETED')"
            :confirm="System.lang('timerType.confirmDelete')"
            :confirmHint="System.lang('timerType.confirmDeleteHint')"
            :fn="deleteType"
        />
    </v-sheet>


    <!-- Period Dialog -->
    <SimpleDialog
        :dialog="periodDialog"
        :title="(periodIndex == -1 ? System.lang('timerType.addPeriod') : System.lang('timerType.editPeriod'))"
        :icon="mdiClockTimeFourOutline"
        :width="350"
        @close="periodDialog.close()"
    >
        <SimpleForm 
            @ref="periodForm.ref = $event"
            @input="periodForm.valid = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-radio-group 
                        v-model="periodForm.values.period_type" 
                        :label="System.lang('timerType.periodForm.periodType')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('timerType.periodType.1')" :value="TIMER_BILL_PERIOD.INFINITE"></v-radio>
                        <v-radio :label="System.lang('timerType.periodType.2')" :value="TIMER_BILL_PERIOD.LIMITED"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row dense v-if="periodForm.values.period_type == TIMER_BILL_PERIOD.LIMITED">
                <v-col cols="6">
                    <v-text-field
                        :label="System.lang('timerType.periodForm.period')"
                        :hint="System.lang('timerType.periodForm.periodHint')"
                        v-model="periodForm.values.period"
                        :rules="periodForm.rules.period"
                        :error-messages="periodForm.errors.period"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select
                        :items="timeUnits"
                        v-model="periodForm.values.period_units"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <v-text-field
                        :label="System.lang('timerType.periodForm.step')"
                        :hint="System.lang('timerType.periodForm.stepHint')"
                        v-model="periodForm.values.step"
                        :rules="periodForm.rules.step"
                        :error-messages="periodForm.errors.step"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select
                        :items="timeUnits"
                        v-model="periodForm.values.step_units"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <v-text-field
                        :label="System.lang('timerType.periodForm.amountFlat')"
                        :hint="System.lang('timerType.periodForm.amountFlatHint')"
                        v-model="periodForm.values.amount_flat"
                        :rules="periodForm.rules.amount_flat"
                        :error-messages="periodForm.errors.amount_flat"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        :label="System.lang('timerType.periodForm.amountStep')"
                        :hint="System.lang('timerType.periodForm.amountStepHint')"
                        v-model="periodForm.values.amount_step"
                        :rules="periodForm.rules.amount_step"
                        :error-messages="periodForm.errors.amount_step"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <v-text-field
                        :label="System.lang('timerType.periodForm.order')"
                        :hint="System.lang('timerType.periodForm.orderHint')"
                        v-model="periodForm.values.order"
                        :rules="periodForm.rules.order"
                        :error-messages="periodForm.errors.order"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.ok')"
                :disabled="!periodForm.valid"
                :fn="savePeriod"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {periodDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiTextBoxOutline,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiCurrencyUsd,
    mdiClockTimeFourOutline,
    mdiPlusCircleOutline,
    mdiCogOutline,
    mdiContentSaveOutline,
} from '@mdi/js';

import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import reject from 'lodash/reject'
import System from '@/classes/System'
import Interface from '@/classes/Interface'
import {Form, Dialog} from '@/classes/Elements'
import {TIMER_TYPE_STATUS, TIMER_BILL_PERIOD, TIME_UNITS} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import LangText from '@/components/system/LangText'
import ActionButton from '@/components/system/ActionButton'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionForm from '@/components/forms/ActionForm'
import SimpleForm from '@/components/forms/SimpleForm'
import DeleteActionButton from '@/components/parts/DeleteActionButton'

export default {
    name: 'TimerTypeView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        DeleteActionButton,
        LangText,
        ActionButton,
        SimpleDialog,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiTextBoxOutline,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            mdiCurrencyUsd,
            mdiClockTimeFourOutline,
            mdiPlusCircleOutline,
            mdiCogOutline,
            mdiContentSaveOutline,
            // Globals
            System,
            TIMER_TYPE_STATUS,
            TIMER_BILL_PERIOD,
            TIME_UNITS,
            reject,
            // Data
            loading: false,
            timerTypeId: undefined,
            timerType: undefined,
            periodIndex: -1,
            drivers: [],
            currencies: [],
            statusItems: [
                {value: 1, text: System.lang('timerType.status.1')},
                {value: 2, text: System.lang('timerType.status.2')},
            ],
            timeUnits: [
                {value: 1, text: System.lang('timerType.timeUnit.1')},
                {value: 2, text: System.lang('timerType.timeUnit.2')},
                {value: 3, text: System.lang('timerType.timeUnit.3')},
                {value: 4, text: System.lang('timerType.timeUnit.4')},
            ],
            // Forms
            timerTypeForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                name_ml: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 50) || System.lang('val.shorter'),
                ],
                status: [],
                driver_id: [],
                pay_delay: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d*$/.test(v) || System.lang('val.invalid'),
                    (v) => (+v <= 1000) || System.lang('val.less'),
                ],
                pay_delay_units: [],
            }, {'name_ml': 'name'}),
            amountsForm: new Form({
                currency_id: [],
                amount_min: [
                    (v) => (!v || /^\d+(\.\d+)*$/.test(v)) || System.lang('val.amount'),
                    (v) => (!v || +v >= 0) || System.lang('val.amount'),
                ],
                amount_max: [
                    (v) => (!v || /^\d+(\.\d+)*$/.test(v)) || System.lang('val.amount'),
                    (v) => (!v || +v >= 0) || System.lang('val.amount'),
                    (v) => (!v || !this.timerType.amount_min || +v > +this.timerType.amount_min) || System.lang('val.more'),
                ],
            }),
            periodForm: new Form({
                order: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+$/.test(v) || System.lang('val.invalid'),
                    (v) => +v > 0 || System.lang('val.positive'),
                ],
                period_type: [],
                period: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+$/.test(v) || System.lang('val.invalid'),
                    (v) => +v > 0 || System.lang('val.positive'),
                ],
                period_units: [],
                step: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d+$/.test(v) || System.lang('val.invalid'),
                    (v) => +v > 0 || System.lang('val.positive'),
                ],
                step_units: [],
                amount_flat: [
                    (v) => (/^\d+(\.\d+)*$/.test(v)) || System.lang('val.amount'),
                ],
                amount_step: [
                    (v) => (/^\d+(\.\d+)*$/.test(v)) || System.lang('val.amount'),
                ],
            }),
            // Tables
            periodsHeaders: [
                {
                    text: System.lang('timerType.periodsTable.order'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'order',
                    width: '50px',
                },
                {
                    text: System.lang('timerType.periodsTable.duration'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'duration',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('timerType.periodsTable.step'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'step',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('timerType.periodsTable.amountFlat'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'amount_flat',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('timerType.periodsTable.amountStep'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'amount_step',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('timerType.periodsTable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ],
            // Dialogs
            periodDialog: new Dialog(),
        }
    },
    computed: {
        sortedPeriods () {
            return sortBy(this.timerType.periods, ['order']);
        },
    },
    methods: {
        init () {
            this.timerTypeId = this.$route.params.id;
            this.fetchData();
        },
        setData (data) {
            this.timerType = data.type;
            this.drivers = data.drivers;
            this.currencies = data.currencies;
        },
        async fetchData () {
            this.timerType = undefined;
            this.loading = true;

            return Api.get(`/admin/timer-types/${this.timerTypeId}`)
                .then(data => {
                    this.setData(data);
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateType () {
            return Api.patch(`/admin/timer-types/${this.timerTypeId}`, this.timerType).then(data => this.setData(data));
        },
        async updateAmounts () {
            return Api.patch(`/admin/timer-types/${this.timerTypeId}/amounts`, this.timerType).then(data => this.setData(data));
        },
        async updatePeriods () {
            return Api.patch(`/admin/timer-types/${this.timerTypeId}/periods`, {periods: this.sortedPeriods}).then(data => this.setData(data));
        },
        async deleteType () {
            return Api.delete(`/admin/timer-types/${this.timerTypeId}`, {})
                .then(() => {
                    System.redirectTo('admin-merchant', {params: {id: this.timerType.merchant_id}});
                });
        },
        newPeriod () {
            let maxOrder = 0;

            if (this.timerType?.periods?.length) {
                maxOrder = +maxBy(this.timerType.periods, 'order').order;
            }

            return {
                order: maxOrder + 1,
                period_type: TIMER_BILL_PERIOD.INFINITE,
                period: undefined,
                period_units: TIME_UNITS.MINUTES,
                step: undefined,
                step_units: TIME_UNITS.MINUTES,
                amount_flat: 0,
                amount_step: 0,
            }
        },
        openPeriodDialog (item = undefined) {
            if (!item) {
                this.periodIndex = -1;
                this.periodForm.values = Object.assign({}, this.newPeriod());
            } else {
                this.periodIndex = this.timerType.periods.indexOf(item);
                this.periodForm.values = Object.assign({}, item);
            }

            this.$nextTick(() => this.periodDialog.open());
        },
        savePeriod () {
            if (this.periodIndex == -1) {
                this.shiftOrders(this.periodForm.values.order);
                this.timerType.periods.push(this.periodForm.values);
            } else {
                if (this.timerType.periods[this.periodIndex].order != this.periodForm.values.order) {
                    this.shiftOrders(this.periodForm.values.order);
                }
                Object.assign(this.timerType.periods[this.periodIndex], this.periodForm.values);
            }

            this.periodDialog.close();
        },
        shiftOrders (order) {
            if (this.timerType.periods.find(p => p.order == order)) {
                this.timerType.periods.forEach(period => {
                    if (+period.order >= +order) {
                        +period.order++;
                    }
                });
            }
        },
    },
    mounted () {
        System.setTitle('timerType.timerType');
        this.init();
    },
}
</script>


<style scoped>
</style>
