<template>
    <v-app>

        <v-app-bar app clipped-left clipped-right flat elevation="1">
            <div class="d-flex align-center">
                <v-app-bar-nav-icon v-if="user" @click.stop="drawerLeft = !drawerLeft"></v-app-bar-nav-icon>
                <v-img alt="Logo" class="ml-3" src="@/assets/images/logo.png" position="left" contain width="85" />
            </div>
            <v-spacer></v-spacer>
            <TopMenu v-if="user" />
            <LangMenu />
        </v-app-bar>

        <v-navigation-drawer v-if="user" v-model="drawerLeft" clipped app>
            <UserHead />
            <MainMenu />
        </v-navigation-drawer>

        <v-main>
            <v-container>
                <TopMessage />

                <div v-if="status == STATUS.LOADING" class="d-flex justify-center align-center mt-10">
                    <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>                
                </div>
                <div v-else-if="status == STATUS.ERROR" class="text-center mt-10">
                    <p class="error--text">{{System.lang('errors.BACKEND_FAILED')}}</p>
                    <v-btn color="error" @click="location.reload()">{{System.lang('buttons.refresh')}}</v-btn>
                </div>
                <div v-else>
                    <router-view></router-view>
                </div>
                
                <PopupMessage />
                <SnackMessage />
            </v-container>
        </v-main>

    </v-app>
</template>

<script>
import { mapState } from 'vuex';

import System from '@/classes/System'
import Interface from '@/classes/Interface'

import TopMenu from '@/components/system/TopMenu';
import LangMenu from '@/components/system/LangMenu'
import MainMenu from '@/components/system/MainMenu';
import UserHead from '@/components/system/UserHead';
import SnackMessage from '@/components/system/SnackMessage';
import PopupMessage from '@/components/system/PopupMessage';
import TopMessage from '@/components/system/TopMessage';

const STATUS = {
    LOADING: 0,
    READY: 1,
    ERROR: 2,
}

export default {
    name: 'App',
    components: {
        TopMenu,
        LangMenu,
        MainMenu,
        UserHead,
        SnackMessage,
        PopupMessage,
        TopMessage,
    },
    data () {
        return {
            location,
            System,
            STATUS,
            drawerLeft: undefined,
            status: STATUS.LOADING,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.profile,
        }),
    },
    created () {
        System.init(this)
            .then(() => {
                this.status= STATUS.READY;
            })
            .catch(error => {
                Interface.popupError(System.lang('errors.BACKEND_FAILED'), error.message);
                this.status= STATUS.ERROR;
            });
    },
};
</script>

<style>
pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-size: 12px;
    background-color: #F5F5F5;
    border-radius: 5px;
    max-height: 400px;
    padding: 5px;
    overflow: auto;
}
</style>
