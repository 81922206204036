function getDefaultState () {
    return {
        profile: false,
        insts: [],
    }
}

const state = getDefaultState();

const getters = {};

const mutations = {
    resetState (state) {
        Object.assign(state, getDefaultState());
    },
    setProfile (state, data) {
        state.profile = data;
    },
    setInsts (state, data) {
        state.insts = data;
    },
    addInst (state, data) {
        state.insts.push(data);
    },
    delInst (state, id) {
        state.insts = state.insts.filter(inst => inst.id != id);
    },
    updInst (state, data) {
        state.insts.forEach(inst => {
            if (inst.id == data.id) {
                inst.name = data.name;
                inst.code = data.code;
            }
        });
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }