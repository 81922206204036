<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <InstSelector
            @selected="selected = $event; fetchData($event)"
        >
            <IconButton
                v-if="isSuper"
                :icon="mdiBackburger"
                :hint="System.lang('inst.backHint')"
                :fn="() => {System.redirectTo('admin-insts')}"
            />
            <IconButton
                :disabled="!selected"
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData(selected)}"
            />
        </InstSelector>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>
    
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="inst">
        <HeaderPanel
            :title="System.lang('inst.inst')"
            :icon="mdiDatabase"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm 
            :fn="updateInst"
            @errors="instForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('inst.instForm.code')"
                        :hint="System.lang('inst.instForm.codeHint')"
                        counter="25"
                        v-model="inst.code"
                        :rules="instForm.rules.code"
                        :error-messages="instForm.errors.code"
                        :readonly="!isSuper"
                        @click="instForm.errors.code = []"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('inst.instForm.name')"
                        :hint="System.lang('inst.instForm.nameHint')"
                        counter="50"
                        v-model="inst.name"
                        :rules="instForm.rules.name"
                        :error-messages="instForm.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('inst.instForm.mid')"
                        :hint="System.lang('inst.instForm.midHint')"
                        counter="25"
                        v-model="inst.mid"
                        :rules="instForm.rules.mid"
                        :error-messages="instForm.errors.mid"
                        :readonly="!isSuper"
                        @click="instForm.errors.mid = []"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="3">
                    <v-autocomplete
                        :items="currencies"
                        :label="System.lang('inst.instForm.currency')"
                        v-model="inst.currency_id"
                        item-value="id"
                        item-text="name"
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('inst.instForm.qrPrefix')"
                        :hint="System.lang('inst.instForm.qrPrefixHint')"
                        counter="100"
                        v-model="inst.qr_prefix"
                        :rules="instForm.rules.qr_prefix"
                        :error-messages="instForm.errors.qr_prefix"
                    ></v-text-field>
                </v-col>
            </v-row>

            <div class="pt-3">
                <a href="#" @click.prevent="showMore = !showMore" class="text-caption">{{(showMore ? System.lang('interface.less') : System.lang('interface.more'))}}</a>
            </div>
            <v-expand-transition>
                <div v-show="showMore" class="pt-3">
                    <v-row dense>
                        <v-col cols="12" sm="3">
                            <v-textarea
                                :label="System.lang('inst.instForm.address')"
                                :hint="System.lang('inst.instForm.addressHint')"
                                counter="250"
                                rows="4"
                                v-model="inst.address"
                                :rules="instForm.rules.address"
                                :error-messages="instForm.errors.address"
                                @click="instForm.errors.address = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-textarea
                                :label="System.lang('inst.instForm.publicContact')"
                                :hint="System.lang('inst.instForm.publicContactHint')"
                                counter="250"
                                rows="4"
                                v-model="inst.public_contact"
                                :rules="instForm.rules.public_contact"
                                :error-messages="instForm.errors.public_contact"
                                @click="instForm.errors.public_contact = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-textarea
                                :label="System.lang('inst.instForm.adminContact')"
                                :hint="System.lang('inst.instForm.adminContactHint')"
                                counter="250"
                                rows="4"
                                v-model="inst.admin_contact"
                                :rules="instForm.rules.admin_contact"
                                :error-messages="instForm.errors.admin_contact"
                                @click="instForm.errors.admin_contact = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-textarea
                                :label="System.lang('inst.instForm.legalInfo')"
                                :hint="System.lang('inst.instForm.legalInfoHint')"
                                counter="250"
                                rows="4"
                                v-model="inst.legal_info"
                                :rules="instForm.rules.admin_contact"
                                :error-messages="instForm.errors.admin_contact"
                                @click="instForm.errors.admin_contact = []"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-expand-transition>
        </ActionForm>
    </v-sheet>

    <!-- API control -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="inst">
        <HeaderPanel
            :title="System.lang('inst.apiControl')"
            :icon="mdiLoginVariant"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm 
            :fn="updateApi"
            @errors="apiForm.errors = $event"
        >
            <v-row>
                <v-col cols="12" sm="2" class="text-center">
                    <v-select
                        v-model="apiForm.values.status_termapi"
                        :label="System.lang('insts.termapi')"
                        :items="apiStatusItems(inst.status_termapi)"
                        :disabled="apiStatusDisabled(inst.status_termapi)"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="API_STATUSES[item.value].color">{{API_STATUSES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left :color="API_STATUSES[item.value].color">{{API_STATUSES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="2" class="text-center">
                    <v-select
                        v-model="apiForm.values.status_payapi"
                        :label="System.lang('insts.payapi')"
                        :items="apiStatusItems(inst.status_payapi)"
                        :disabled="apiStatusDisabled(inst.status_payapi)"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="API_STATUSES[item.value].color">{{API_STATUSES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left :color="API_STATUSES[item.value].color">{{API_STATUSES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="inst && isSuper">
        <HeaderPanel
            :title="System.lang('inst.delete')"
            :hint="System.lang('inst.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('inst.delete')"
            :ok="System.lang('inst.deleted')"
            :confirm="System.lang('inst.confirmDelete')"
            :confirmHint="System.lang('inst.confirmDeleteHint')"
            :fn="deleteInst"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiLifebuoy,
    mdiRefresh,
    mdiDomain,
    mdiCogOutline,
    mdiDatabase,
    mdiDeleteForever,
    mdiLoginVariant,
    mdiBackburger,
} from '@mdi/js';

import { mapState } from 'vuex'

import System from '@/classes/System';
import User from '@/classes/User';
import Interface from '@/classes/Interface'
import {Form} from '@/classes/Elements'
import Api from '@/services/api'
import {USER_ROLE, API_STATUS, API_STATUSES} from "@/constants/system";

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import InstSelector from '@/components/parts/InstSelector'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
// import IconLink from '@/components/system/IconLink'
// import IconHint from '@/components/system/IconHint'
// import IconAction from '@/components/system/IconAction'
// import DataTable from '@/components/system/DataTable'
// import SimpleDialog from '@/components/system/SimpleDialog'
// import ActionButton from '@/components/system/ActionButton'
import ActionForm from '@/components/forms/ActionForm'

export default {
    name: 'InstView',
    components: {
        HeaderPanel,
        IconButton,
        InstSelector,
        DeleteActionButton,
        // ActionForm,
        // IconLink,
        // IconAction,
        // IconHint,
        // DataTable,
        // SimpleDialog,
        // ActionButton,
        ActionForm,
    },
    data () {
        return {
            // Icons
            mdiLifebuoy,
            mdiRefresh,
            mdiDomain,
            mdiCogOutline,
            mdiDatabase,
            mdiDeleteForever,
            mdiLoginVariant,
            mdiBackburger,
            // Globals
            System,
            API_STATUS,
            API_STATUSES,
            // Internal
            loading: false,
            showMore: false,
            // Data
            selected: undefined,
            inst: undefined,
            currencies: [],
            apiStatusItemsAdmin: [
                {value: 1, text: System.lang('insts.apistatus.1')},
                {value: 2, text: System.lang('insts.apistatus.2')},
            ],
            apiStatusItemsSuper: [
                {value: 1, text: System.lang('insts.apistatus.1')},
                {value: 2, text: System.lang('insts.apistatus.2')},
                {value: 3, text: System.lang('insts.apistatus.3')},
            ],
            // Forms
            instForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                mid: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                currency_id: [],
                qr_prefix: [
                    (v) => (!v || v.length <= 100) || System.lang('val.shorter'),
                ],
                address: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                admin_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                public_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                legal_info: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
            }),
            apiForm: new Form({
                status_termapi: [],
                status_payapi: [],
            }),
            // Tables
            // Dialogs
        }
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile,
        }),
        isSuper () {
            return this.profile.role == USER_ROLE.SUPER;
        },
    },
    methods: {
        init () {
            this.fetchCurrencies();
        },
        async fetchData (id) {
            this.inst = false;
            this.instForm.reset();
            this.loading = true;

            return Api.get(`/admin/insts/${id}`)
                .then(data => {
                    this.inst = data;
                    this.apiForm.values.status_termapi = this.inst.status_termapi;
                    this.apiForm.values.status_payapi = this.inst.status_payapi;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async fetchCurrencies () {
            return Api.get('/list/currencies')
                .then(data => this.currencies = data)
                .catch(error => Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message));
        },
        async updateInst () {
            return Api.patch(`/admin/insts/${this.inst.id}`, this.inst)
                .then(data => {
                    this.inst = data;
                    User.updInst(this.inst);
                });
        },
        async updateApi () {
            return Api.post(`/admin/insts/${this.inst.id}/api`, this.apiForm.values)
                .then(data => {
                    this.inst = data;
                });
        },
        async deleteInst () {
            return Api.delete(`/admin/insts/${this.inst.id}`, {})
                .then(() => {
                    User.delInst(this.inst.id);
                    System.redirectTo('admin-insts');
                });
        },
        async apiOn (api) {
            return Api.post(`/admin/insts/${this.inst.id}/apicontrol`, {api: api, enabled: false})
                .then(() => {
                    Interface.snackSuccess(System.lang('inst.apiEnabled'));
                });
        },
        apiStatusItems (status) {
            if (this.isSuper || status == API_STATUS.DISABLED) return this.apiStatusItemsSuper;
            return this.apiStatusItemsAdmin;
        },
        apiStatusDisabled (status) {
            if (!this.isSuper && status == API_STATUS.DISABLED) return true;
            return false;
        },
    },
    mounted () {
        System.setTitle('inst.inst');
        this.init();
    },
}
</script>


<style scoped>
</style>
