<template>
    <v-row dense>
        <v-col cols="12" sm="6">
            <v-text-field
                :disabled="disabled"
                :label="System.lang('callbacks.standardapi.url')"
                :hint="System.lang('callbacks.standardapi.urlHint')"
                counter="250"
                v-model="callbackSettings.url"
                :rules="(disabled ? undefined : callbackForm.rules.url)"
                :error-messages="callbackForm.errors.url"
                @click="callbackForm.resetError('url')"
                @input="input"
            >
                <template v-slot:append-outer>
                    <IconButton
                        :disabled="disabled"
                        color="primary"
                        :icon="mdiCogOutline"
                        :hint="System.lang('callbacks.standardapi.connectSettings')"
                        :fn="() => {return connectSettingsDialog.open()}"
                    />
                    <SimpleDialog
                        :dialog="connectSettingsDialog"
                        :width="350"
                        :title="System.lang('callbacks.standardapi.connectSettings')"
                        :icon="mdiCogOutline"
                        @close="connectSettingsDialog.close(); input();"
                    >
                        <v-row>
                            <v-col cols="12">
                                <v-subheader>{{System.lang('callbacks.standardapi.connect_timeout')}}</v-subheader>
                                <v-slider
                                    v-model="callbackSettings.connect_timeout"
                                    persistent-hint
                                    track-color="grey"
                                    :prepend-icon="mdiPhoneClock"
                                    :hint="`${callbackSettings.connect_timeout} sec.`"
                                    dense
                                    max="15"
                                    min="1"
                                ></v-slider>
                                <v-subheader>{{System.lang('callbacks.standardapi.request_timeout')}}</v-subheader>
                                <v-slider
                                    v-model="callbackSettings.request_timeout"
                                    persistent-hint
                                    track-color="grey"
                                    :prepend-icon="mdiSortClockDescendingOutline"
                                    :hint="`${callbackSettings.request_timeout} sec.`"
                                    dense
                                    max="15"
                                    min="1"
                                ></v-slider>
                                <v-subheader>{{System.lang('callbacks.standardapi.retry_count')}}</v-subheader>
                                <v-slider
                                    v-model="callbackSettings.retry_count"
                                    persistent-hint
                                    track-color="grey"
                                    :prepend-icon="mdiNumeric"
                                    :hint="(callbackSettings.retry_count ? `${callbackSettings.retry_count}` : System.lang('callbacks.standardapi.noRetries'))"
                                    dense
                                    max="10"
                                    min="0"
                                ></v-slider>
                                <v-subheader>{{System.lang('callbacks.standardapi.retry_delay')}}</v-subheader>
                                <v-slider
                                    v-model="callbackSettings.retry_delay"
                                    persistent-hint
                                    track-color="grey"
                                    :prepend-icon="mdiTimerRefreshOutline"
                                    :hint="`${callbackSettings.retry_delay} ms.`"
                                    dense
                                    max="2000"
                                    min="100"
                                    step="100"
                                ></v-slider>
                            </v-col>
                        </v-row>
                    </SimpleDialog>
                </template>
            </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <ApiKeyInput
                :disabled="disabled"
                :label="System.lang('callbacks.standardapi.key')"
                :hint="System.lang('callbacks.standardapi.keyHint')"
                counter="150"
                v-model="callbackSettings.apikey"
                :rules="(disabled ? undefined : callbackForm.rules.apikey)"
                :error-messages="callbackForm.errors.apikey"
                @click="callbackForm.resetError('apikey')"
                @input="input"
            ></ApiKeyInput>
        </v-col>
    </v-row>
</template>


<script>
import {
    mdiCogOutline,
    mdiPhoneClock,
    mdiSortClockDescendingOutline,
    mdiNumeric,
    mdiTimerRefreshOutline,
} from '@mdi/js';

import System from '@/classes/System'
import {Form, Dialog} from '@/classes/Elements'
import SimpleDialog from '@/components/system/SimpleDialog'
import ApiKeyInput from '@/components/parts/ApiKeyInput'

import IconButton from '@/components/system/IconButton'

export default {
    name: 'CallbackStandardJson',
    inheritAttrs: false,
    components: {
        IconButton,
        SimpleDialog,
        ApiKeyInput,
    },
    props: {
        callback: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            required: false,
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiCogOutline,
            mdiPhoneClock,
            mdiSortClockDescendingOutline,
            mdiNumeric,
            mdiTimerRefreshOutline,
            // Data
            callbackSettings: {
                url: undefined,
                apikey: undefined,
                connect_timeout: undefined,
                request_timeout: undefined,
                retry_count: undefined,
                retry_delay: undefined,
            },
            // Forms
            callbackForm: new Form({
                url: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                    (v) => /^(https?):\/\/[^\s/$.?#].[^\s]*$/i.test(v) || System.lang('val.url'),
                ],
                apikey: [
                    (v) => (!v || v.length <= 150) || System.lang('val.shorter'),
                    (v) => /^[a-zA-Z0-9]*$/.test(v) || System.lang('val.alphanumeric'),
                ],
            }),
            // Dialogs
            connectSettingsDialog: new Dialog(),
        }
    },
    computed: {
    },
    methods: {
        initValues () {
            this.callbackSettings.url = this.settings.url ?? '';
            this.callbackSettings.apikey = this.settings.apikey ?? '';
            this.callbackSettings.connect_timeout = this.settings.connect_timeout ?? 3;
            this.callbackSettings.request_timeout = this.settings.request_timeout ?? 3;
            this.callbackSettings.retry_count = this.settings.retry_count ?? 0;
            this.callbackSettings.retry_delay = this.settings.retry_delay ?? 100;
        },
        input () {
            this.$emit('input', this.callbackSettings);
        },
    },
    mounted () {
        this.initValues();
        this.input();
    },
    watch: {
        // callback () {
        //     this.initValues();
        // },
        errors (errors) {
            // Object.assign(this.callbackForm.errors, errors);
            this.callbackForm.errors.url = errors['driver_settings.standardapi.url'];
            this.callbackForm.errors.apikey = errors['driver_settings.standardapi.apikey'];
        },
    },

}
</script>


<style scoped>
</style>
