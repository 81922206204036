import {ru} from 'vuetify/lib/locale'

export default {
    ...ru,

    // Errors
    errors: {
        'DEFAULT': 'Ошибка {0}',
        '0': 'Ошибка сети или соединения',
        '403': 'Доступ запрещен',
        '404': 'Запрашиваемый ресурс не найден',
        '422': 'Форма содержит ошибки',
        '500': 'Ошибка обработки на сервере',
        'UNKNOWN': 'Неизвестная ошибка',
        'NETWORK': 'Ошибка сети или соединения',
        'BACKEND_FAILED': 'Ошибка соединения с сервером',
        'LOGIN_FAILED': 'Авторизация не удалась',
    },
    
    // Global messages
    messages: {
        'REQUEST_FAILED': 'Ошибка запроса',
        'SAVE_OK': 'Сохранено',
        'SAVE_ERROR': 'Не удалось сохранить',
        'PROCESS_OK': 'Сделано',
        'PROCESS_ERROR': 'Ошибка процесса',
        'SESSION_EXPIRED': 'Ваша сессия истекла. Пожалуйста авторизуйтесь.',
    },

    // Global buttons
    buttons: {
        ok: 'Ок',
        cancel: 'Отмена',
        close: 'Закрыть',
        delete: 'Удалить',
        copy: 'Копировать',
        confirm: 'Подтвердить',
        refresh: 'Обновить',
        save: {
            label: 'Сохранить',
            hint: 'Сохранить изменения',
        },
        help: {
            label: 'Помощь',
            hint: 'Показать помощь',
        },
    },

    // Interface
    interface: {
        logout: 'Выход',
        info: 'Информация',
        success: 'Успех',
        warning: 'Предупреждение',
        error: 'Ошибка',
    },

    // Main menu
    menu: {
        test: 'Тест',
        insts: 'Организации',
        inst: 'Организация',
    },

    // Form validation messages
    val: {
        required: 'Поле обязательно',
        shorter: 'Нужно короче',
        longer: 'Нужно длиннее',
        less: 'Нужно меньше',
        more: 'Нужно больше',
    },

    // Login screen
    login: {
        username: {
            label: 'Емайл',
            hint: 'Введите ваш емайл',
            errors: [
                'Введите ваш емайл',
                'Некорректый емайл адрес',
            ],
        },
        password: {
            label: 'Пароль',
            hint: 'Введите ваш пароль',
            errors: [
                'Введите ваш пароль',
            ],
        },
        buttons: {
            login: "Вход",
        },
        errors: [
            'Неверный емайл или пароль',
            'Ошибка входа',
        ],
        remember: 'Запомнить меня',
        forgot: 'Забыли пароль?',
        register: 'Регистрация нового аккаунта',
    },

    // Institutions screen
    insts: {
        insts: 'Организации',
        addNewHint: 'Добавить новую организацию',
        configHint: 'Настройка и управление',
        newInst: 'Новая организация',
        messages: {
            'INST_ADDED': 'Организация добавлена',
            'INST_DELETED': 'Организация удалена',
        },
        table: {
            code: 'Код',
            name: 'Название',
            status: 'Статус API',
            actions: 'Действия',
            instapi: 'API организации',
            merchapi: 'API торговца',
            termapi: 'API терминала',
            payapi: 'API платежей',
        },
        newForm: {
            code: 'Код',
            codeHint: 'Код организации',
            name: 'Название',
            nameHint: 'Название организации',
        },
    },

}