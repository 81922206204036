import axios from 'axios'
import store from '@/store'
import router from '@/router'

// import Interface from '@/classes/Interface'
import User from '@/classes/User'
import Interface from '@/classes/Interface'
import Err from '@/classes/Err'
import Api from '@/services/api'
import Settings from '@/constants/settings'
import {LANGS} from '@/constants/interface'


class System {
    constructor () {
        this.self = null;
    }


// Initialization

    init (self) {
        // Save self
        this.self = self;

        // Set language
        this.setLanguage(this.computeLanguage());
        this.setTitle();

        // Set debug
        if (process.env.NODE_ENV == 'development' || this.self.$route.query.debug === null || Settings.debug) {
            this.debugOn();
        } else {
            this.debugOff();
        }

        // Print settings
        this.debug('Settings: ', Settings);

        // Call backend init
        return Api.get('/init')
            .then(data => {
                this.setData(data.system);
                this.setLangs(data.langs);
                if (data.user) {
                    User.setLogin(data.user);
                    this.restoreRoute(this.isLoginRoute() ? 'home' : false);
                } else {
                    this.redirectToLogin();
                }
                return data;
            });
    }

// Data store functions

    setData (data) {
        store.commit('system/setData', data);
    }

    setLangs (data) {
        store.commit('system/setLangs', data);
    }

// Helper functions

    isMobile () {
        return this.self.$vuetify.breakpoint.xsOnly || false;
    }

    copy (text) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    Interface.snackSuccess(this.lang('messages.CLIPBOARD_COPIED'));
                })
                .catch(() => {
                    Interface.snackError(this.lang('messages.CLIPBOARD_FAILED'));
                });
        } else {
            Interface.snackError(this.lang('messages.CLIPBOARD_NOT_AVAIL'));
        }
    }

    setTitle (title = false) {
        if (title) {
            document.title = this.lang('interface.title') + ' : ' + this.lang(title);
        } else {
            document.title = this.lang('interface.title');
        }
    }

    saveFile (filename, data) {
        let downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = data;
        downloadLink.target = '_self';
        downloadLink.download = filename;
        downloadLink.click();
        downloadLink.remove();
    }

    svgToImage (svgString, width, height, format, callback) {
        // set default for format parameter
        format = format ? format : 'png';
        // SVG data URL from SVG string
        let svgData = svgString;
        // create canvas in memory(not in DOM)
        let canvas = document.createElement('canvas');
        // get canvas context for drawing on canvas
        let context = canvas.getContext('2d');
        // set canvas size
        canvas.width = width;
        canvas.height = height;
        // create image in memory(not in DOM)
        let image = new Image();
        // later when image loads run this
        image.onload = function () { // async (happens later)
            // clear canvas
            context.clearRect(0, 0, width, height);
            // draw image with SVG data to canvas
            context.drawImage(image, 0, 0, width, height);
            // snapshot canvas as png
            var pngData = canvas.toDataURL('image/' + format);
            // pass png data URL to callback
            callback(pngData);
        }; // end async
        // start loading SVG data into in memory image
        image.src = svgData;
    }    

    prettyJSON (data_str) {
        try {
            let data_json = JSON.parse(data_str);
            return JSON.stringify(data_json, null, 2);
        } catch (error) {
            return data_str;
        }
    }

// Debug functions

    debugOn () {
        console.log('System debug enabled');
        store.commit('system/debugOn');
    }

    debugOff () {
        store.commit('system/debugOff');
    }

    debug(...args) {
        if (store.state.system.debug) {
            console.log(...args);
        }
    }


// Language functions

    getLanguage (locale) {
        let found = 0;

        LANGS.forEach((lang, index) => {
            if (lang.locale == locale) {
                found = index;
            }
        });

        return LANGS[found];
    }

    setLanguage (locale) {
        this.self.$vuetify.lang.current = locale;
        localStorage.setItem('locale', locale);
        document.cookie = `locale=${locale}`;
    }

    computeLanguage (locale = 'en') {
        let result = localStorage.getItem('locale') || navigator.language.split('-', 1)[0] || locale;
        return this.getLanguage(result).locale || locale;
    }

    lang (label, ...args) {
        let text = this.self?.$vuetify?.lang.t(`$vuetify.${label}`, ...args) || label;
        if (text.startsWith('$vuetify')) text = label;
        return text;
    }

    createLangsObject () {
        let langs = {};
        store.state.system.langs.forEach(lang => {
            langs[lang.code] = '';
        });
        return langs;
    }


// Network functions

    request (method, url, params = false, data = false, isAuth = false, type = 'json') {
        let config = {
            url: url,
            method: method,
            responseType: type,
            withCredentials: isAuth,
        }

        this.debug(`Request [${config.method}][${config.url}]`);

        if (params) {
            config.params = params;
            this.debug('Params: ', config.params);
        }
        
        if (data) {
            config.data = data;
            this.debug('Data: ', config.data);
        }

        return new Promise ((resolve, reject) => {
            axios(config)
                .then(response => {
                    this.debug(`Response [${config.url}][${response.status}][${response.statusText}]`);
                    this.debug('Data: ', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    // User auth or xsrf error
                    if (error.response && ['401','419'].includes(String(error.response.status))) {
                        User.setLogout();
                        this.redirectToLogin();
                        Interface.messageWarning(this.lang('messages.SESSION_EXPIRED'));
                    // Application error
                    } else if (error.response?.data?.code) {
                        reject(new Err(error.response.data.code, error.response.data.message, error.response.data.errors));
                    // Some other HTTP response
                    } else if (error.response) {
                        reject(new Err(String(error.response.status), undefined, error.response.data?.errors));
                    // Network or connection error
                    } else if (error.request) {
                        reject(new Err('NETWORK'));
                    // Unknown error
                    } else {
                        reject(new Err('UNKNOWN'));
                    }                
                });            
        })
    }

// Router functions

    isLoginRoute () {
        return router.currentRoute.name == 'login';
    }

    isGuestRoute () {
        let routes = [
            'login',
        ];
        return routes.includes(router.currentRoute.name);
    }

    saveRoute () {
        let path = router.currentRoute.fullPath;
        localStorage.setItem('to_route', path);
    }

    restoreRoute (dflt = false) {
        let to = localStorage.getItem('to_route');

        if (to) {
            localStorage.removeItem('to_route');
            router.push(to);
        } else if (dflt) {
            this.redirectTo(dflt);
        }
    }

    redirectTo (to, params = {}) {
        if (router.currentRoute.name != to)
            router.push({name: to, ...params});
    }

    redirectToLogin (saveCurrent = true) {
        if (saveCurrent && !this.isLoginRoute()) {
            this.saveRoute();
        }
        this.redirectTo('login');
    }

    redirectToHome () {
        this.redirectTo('home');
    }

// Local settings functions

    loadSetting (name, dflt = false) {
        let value = localStorage.getItem('settings.' + name) || dflt;

        if (typeof dflt == 'number') {
            return Number(value);
        } else if (typeof dflt == 'boolean') {
            return Boolean(value);
        } else {
            return value;
        }
    }
    
    saveSetting (name, value) {
        localStorage.setItem('settings.' + name, value);
    }

}


export default new System();