<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
            <v-icon 
                :color="color"
                class="mx-1 icon-hint"
                v-bind="{...attrs, ...$attrs}"
                v-on="on"
            >{{icon}}</v-icon>
        </template>
        <span><slot>{{hint}}</slot></span>
    </v-tooltip>
</template>


<script>
// import System from "@/classes/System";
// import Interface from "@/classes/Interface";

export default {
    name: 'IconHint',
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'grey',
        },
        icon: {
            type: String,
            required: true,
        },
        hint: {
            type: [String],
            default: '',
        },
    },
    data () {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
}
</script>


<style scoped>
</style>
