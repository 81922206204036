import store from '@/store'
import Api from '@/services/api'
import System from './System';

class User {

// Helper functions

    initials (string) {
        let names = string.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        
        return initials;
    }


// Set and get data functions

    setLogin (data) {
        store.commit('user/setProfile', data.profile);
        store.commit('user/setInsts', data.insts);
    }

    setLogout () {
        store.commit('user/resetState');
        localStorage.removeItem('to_route');
    }

    addInst (data) {
        store.commit('user/addInst', data);
    }

    delInst (id) {
        store.commit('user/delInst', id);
    }
 
    updInst (data) {
        store.commit('user/updInst', data);
    }

    
// API functions

    login (data) {
        return Api.post('/login', data, false)
            .then(data => {
                this.setLogin(data);
                return data;
            });
    }

    logout () {
        return Api.post('/logout')
            .finally(() => {
                this.setLogout();
                System.redirectToLogin(false);
            });
    }

}


export default new User();