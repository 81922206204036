import ApilogView from '@/components/views/monitor/ApilogView'
import CblogView from '@/components/views/monitor/CblogView'
import PerfView from '@/components/views/monitor/PerfView'

let prefix = '/monitor';

const routes = [

    // API log
    {
        path: prefix + '/apilog',
        name: 'monitor-apilog',
        component: ApilogView,
    },

    // Callback log
    {
        path: prefix + '/cblog',
        name: 'monitor-cblog',
        component: CblogView,
    },

    // Performance view
    {
        path: prefix + '/perf',
        name: 'monitor-perf',
        component: PerfView,
    },
]

export default routes
