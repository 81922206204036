import StressView from '@/components/views/testing/StressView'

let prefix = '/testing';

const routes = [

    // Stress test
    {
        path: prefix + '/stress',
        name: 'test-stress',
        component: StressView,
    },

]

export default routes
