import System from "@/classes/System";

class Err extends Error {

    constructor (code = undefined, message = undefined, details = undefined) {
        if (!code) {
            code = 'UNKNOWN';
        }
        
        if (!message) {
            message = System.lang(`errors.${code}`);
        }

        if (message == `errors.${code}`) {
            message = System.lang(`errors.DEFAULT`, code);
        }

        super(message);
        this.code = code;
        this.details = details;
        
        System.debug(`ERROR: [${code}][${message}]`, details);
    }

}

export default Err;