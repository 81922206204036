<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
            <v-icon
                v-bind="attrs"
                v-on="on"
                class="translate-icon"
                @click="dialog.open()"
            >{{mdiTranslateVariant}}</v-icon>
        </template>
        <span>{{System.lang('buttons.translate.hint')}}</span>
        <SimpleDialog
            :dialog="dialog"
            :width="width"
            :title="label"
            :hint="hint"
            :icon="mdiTranslateVariant"
            @close="dialog.close()"
        >
            <SimpleForm 
                @ref="form.ref = $event"
                @input="form.valid = $event"
            >
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-for="lang in langs" :key="lang.id"
                            :hint="lang.name"
                            persistent-hint
                            :counter="counter"
                            v-model="data[lang.code]"
                            :rules="rules"
                            @input="$emit('changed')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </SimpleForm>

            <template v-slot:actions>
                <ActionButton
                    text
                    :block="false"
                    color="primary" 
                    :label="System.lang('buttons.ok')"
                    :fn="save"
                    :disabled="!form.valid"
                />
                <v-spacer></v-spacer>
                <ActionButton
                    text
                    :block="false"
                    color="grey"
                    :label="System.lang('buttons.cancel')"
                    :fn="() => {dialog.close()}"
                />
            </template>
        </SimpleDialog>

    </v-tooltip>
</template>


<script>
import {
    mdiTranslateVariant,
} from '@mdi/js';

import { mapState } from 'vuex'

import System from '@/classes/System'
import {Dialog, Form} from '@/classes/Elements'

import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'LangText',
    props: {
        value: {
            type: [Object, Array],
            required: true,
        },        
        label: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: false,
        },
        width: {
            type: Number,
            default: 400,
        },
        counter: {
            type: [Number, String],
            default: undefined,
        },
        rules: {
            type: Array,
        },
    },
    components: {
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            //Icons
            mdiTranslateVariant,
            // Globals
            System,
            // Data
            data: System.createLangsObject(),
            // Dialogs
            dialog: new Dialog(this.init),
            // Forms
            form: new Form({
            }),

        }
    },
    computed: {
        ...mapState({
            langs: state => state.system.langs,
        }),
    },
    methods: {
        init () {
            this.langs.forEach(lang => {
                this.data[lang.code] = this.value[lang.code] || '';
            });
        },
        save () {
            if (this.form.validate()) {
                this.$emit('input', {...this.data});
                this.dialog.close();
            }
        },
    },
    mounted () {
        this.init();
    },
}
</script>


<style scoped>
.translate-icon {
    cursor: pointer;
}
</style>
