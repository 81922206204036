<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('currency.currency')"
            :icon="mdiCurrencyUsd"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiBackburger"
                :hint="System.lang('currency.backHint')"
                :fn="() => {System.redirectTo('admin-currencies')}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="currency" 
            :fn="updateCurrency"
            @errors="currencyForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('currency.currencyForm.code')"
                        :hint="System.lang('currency.currencyForm.codeHint')"
                        counter="25"
                        v-model="currency.code"
                        :rules="currencyForm.rules.code"
                        :error-messages="currencyForm.errors.code"
                        @click="currencyForm.errors.code = []"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('currency.currencyForm.name')"
                        :hint="System.lang('currency.currencyForm.nameHint')"
                        counter="50"
                        v-model="currency.name"
                        :rules="currencyForm.rules.name"
                        :error-messages="currencyForm.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3">
                    <v-select
                        :items="decimalsItems"
                        :label="System.lang('currency.currencyForm.decimals')"
                        v-model="currency.decimals"
                    ></v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>

    <!-- Delete  -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="currency && currency.code != systemData.default_currency">
        <HeaderPanel
            :title="System.lang('currency.delete')"
            :hint="System.lang('currency.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('currency.delete')"
            :ok="System.lang('currency.messages.CURRENCY_DELETED')"
            :confirm="System.lang('currency.confirmDelete')"
            :confirmHint="System.lang('currency.confirmDeleteHint')"
            :fn="deleteCurrency"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiRefresh,
    mdiCurrencyUsd,
    mdiDeleteForever,
    mdiBackburger,
} from '@mdi/js';

import { mapState } from 'vuex';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Form} from '@/classes/Elements'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import ActionForm from '@/components/forms/ActionForm'

export default {
    name: 'MerchantView',
    components: {
        HeaderPanel,
        IconButton,
        DeleteActionButton,
        ActionForm,
    },
    data () {
        return {
            // Icons
            mdiRefresh,
            mdiCurrencyUsd,
            mdiDeleteForever,
            mdiBackburger,
            // Globals
            System,
            // Internal
            loading: false,
            // Data
            currencyId: undefined,
            currency: undefined,
            decimalsItems: [0,1,2,3,4,5,6,7,8],
            // Forms
            currencyForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                decimals: [],
            }),
            // Tables
            // Dialogs
        }
    },
    computed: {
        ...mapState({
            systemData: state => state.system.data,
        }),
    },
    methods: {
        init () {
            this.currencyId = this.$route.params.id;
            this.fetchData();
        },
        async fetchData () {
            this.currency = undefined;
            this.loading = true;

            return Api.get(`/super/currencies/${this.currencyId}`)
                .then(data => {
                    this.currency = data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateCurrency () {
            return Api.patch(`/super/currencies/${this.currencyId}`, this.currency).then(data => this.currency = data);
        },
        async deleteCurrency () {
            return Api.delete(`/super/currencies/${this.currencyId}`, {})
                .then(() => {
                    System.redirectTo('admin-currencies');
                });
        },
    },
    mounted () {
        System.setTitle('currency.currency');
        this.init();
    },
}
</script>


<style scoped>
</style>
