<template>
    <SimpleDialog
        :dialog="passDialog"
        :title="System.lang('interface.chpass')"
        :icon="mdiKeyOutline"
        :width="350"
        @close="passDialog.close()"
    >
        <SimpleForm 
            @ref="passForm.ref = $event"
            @input="passForm.valid = $event"
        >
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-text-field 
                        :label="System.lang('user.passForm.password')"
                        :type="showc ? 'text' : 'password'"
                        autocomplete="current-password"
                        counter="50"
                        v-model="passForm.values.current"
                        :append-icon="showc ? mdiEye : mdiEyeOff"
                        :rules="passForm.rules.current"
                        :error-messages="passForm.errors.current"
                        @click:append="showc = !showc"
                        @click="() => {passForm.resetError('current')}"
                    ></v-text-field>
                    <v-text-field 
                        :label="System.lang('user.passForm.newPassword')"
                        :type="show ? 'text' : 'password'"
                        counter="50"
                        autocomplete="new-password"
                        v-model="passForm.values.password"
                        :rules="passForm.rules.password"
                        :error-messages="passForm.errors.password"
                        :append-icon="show ? mdiEye : mdiEyeOff"
                        @click:append="show = !show"
                        @click="() => {passForm.resetError('password')}"
                    ></v-text-field>
                    <v-text-field 
                        :disabled="show"
                        :label="System.lang('user.passForm.newPassword2')"
                        type="password"
                        counter="50"
                        autocomplete="new-password"
                        v-model="passForm.values.password2"
                        :rules="passForm.rules.password2"
                        :error-messages="passForm.errors.password2"
                        :append-icon="show ? mdiEye : mdiEyeOff"
                        @click:append="show = !show"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="changePass"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {passDialog.close()}"
            />
        </template>
    </SimpleDialog>
</template>


<script>
import {
    mdiKeyOutline,
    mdiEye,
    mdiEyeOff,
} from '@mdi/js';

import System from '@/classes/System'
import Interface from '@/classes/Interface'
import Api from '@/services/api'
import {Dialog, Form} from '@/classes/Elements'

import SimpleDialog from '@/components/system/SimpleDialog'
import SimpleForm from '@/components/forms/SimpleForm'
import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'ChpassDialog',
    components: {
        SimpleDialog,
        SimpleForm,
        ActionButton,
    },
    props: {
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiKeyOutline,
            mdiEye,
            mdiEyeOff,
            // Internal
            show: false,
            showc: false,
            // Data
            // Forms
            passForm: new Form({
                current: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                password: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                password2: [
                    (v) => (this.show || !!v) || System.lang('val.required'),
                    (v) => (this.show || v == this.passForm.values.password) || System.lang('val.passmatch'),
                ],
            }),
            // Dialogs
            passDialog: new Dialog(() => {this.passForm.reset()}),
        }
    },
    computed: {
    },
    methods: {
        open () {
            console.log('open');
            this.passDialog.open();
        },
        async changePass () {
            if (!this.passForm.validate()) return false;

            return Api.post('/chpass', this.passForm.values)
                .then(() => {
                    this.passDialog.close();
                    Interface.snackSuccess(System.lang('messages.SAVE_OK'));
                })
                .catch(error => {
                    if (!this.passForm.catch(error)) {
                        Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                    }
                });
        },
    },
    mounted () {
    },

}
</script>


<style scoped>
</style>
