<template>
    <v-snackbar
        v-model="show"
        timeout="1500"
        bottom
        :color="color"
        rounded="pill"
        min-width="15px"
        app
    >
        <div class="d-flex justify-left align-center">
            <v-icon>{{icon}}</v-icon>
            <span class="ml-3 mr-1 text-body-2">{{snack.text}}</span>
        </div>
    </v-snackbar>
</template>


<script>
import Interface from '@/classes/Interface'

export default {
    name: 'SnackMessage',
    components: {
    },
    data () {
        return {
        }
    },
    computed: {
        snack () {
            return this.$store.state.system.snack;
        },
        show: {
            get () {
                return this.snack.show;
            },
            set (v) {
                if (!v) {
                    this.$store.commit('system/snackOff')
                }
            }
        },
        color () {
            return Interface.messageColor(this.snack.type);
        },
        icon () {
            return Interface.messageIcon(this.snack.type);
        },
    },
    methods: {
    },
}
</script>


<style scoped>
</style>
