// import merge from 'lodash/merge';

// Application default settings 

let settings = {
    // GUI API URL
    // Type: String ''
    // guiBaseUrl: '/api/gui',
    // guiBaseUrl: 'http://localhost/qr-backend/gui',
    guiBaseUrl: process.env.VUE_APP_GUI_URL,


    // API URL
    // Type: String ''
    // guiBaseUrl: '/api/gui',
    // apiBaseUrl: 'http://localhost/ng/api',
    apiBaseUrl: process.env.VUE_APP_API_URL,


    // Public assets URL base
    // Type: String ''
    pubBaseUrl: '/info',
 

    // Application URL base
    // Use if application is not served from the root
    // Type: String ''
    // appBaseUrl: '/',
 

    // Use nice URLs without hash #
    // Requires setting on server side to redirect all requests to app file
    // Type: Boolean true/false
    useNiceUrl: true,


    // Use POST instead of PATCH and DELETE methods
    // Type: Boolean true/false
    usePostMethod: true,


    // Console debug enabled
    // Type: Boolean
    debug: false,
    // debug: process.env.VUE_APP_DEBUG,
    
    // Application version
    appVersion: process.env.VUE_APP_VERSION,
};

// merge(settings, window.$settings);


export default settings;
