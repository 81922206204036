// import System from '@/classes/System'

class Table {
    constructor (headers, name = 'data.table', fn = false) {
        this.name = name;
        this.headers = headers;
        this.fn = fn;
        this.data = [];
        this.loading = false;
    }

    load (data = false) {
        this.data = [];

        if (data) {
            this.data = data;
            return true;
        } else if (this.fn) {
            this.loading = true;
            return Promise.resolve(this.fn())
                .then(data => {
                    this.data = data; 
                    return data;
                })
                .finally(() => {
                    this.loading = false
                });
        } else {
            return true;
        }
    }

    add (record) {
        return this.data.push(record);
    }

    delete (record, field = 'id') {
        return this.data = this.data.filter(row => row[field] != record[field]);
    }

    hasData () {
        return this.data?.length > 0;
    }

}


class Dialog {
    constructor (fn = false) {
        this.show = false;
        this.loading = false;
        this.fn = fn;
    }

    open () {
        this.show = true;
        if (!this.fn) return;
        this.loading = true;

        return Promise.resolve(this.fn())
            .finally(() => {
                this.loading = false;
            });
    }

    close () {
        this.show = false;
    }

}


class Form {
    valid = undefined;
    
    get valid() {
        return this.valid;
    }
    
    set valid(x) {
        this.validmsg = x;
    }    
    
    constructor (fields, mlFieldMap = {}) {
        this.fields = fields;
        this.ref = undefined;
        this.fields = fields;
        this.rules = fields;
        this.values = {};
        this.errors = {};
        this.mlFieldMap = mlFieldMap;

        Object.keys(fields).forEach(field => {
            this.errors[field] = [];
            this.values[field] = undefined;
        });
    }

    validate () {
        let valid = this.ref?.validate();

        if (valid) {
            this.errors = {};
            this.ref?.resetValidation();
        }

        return valid;
    }

    resetError (field) {
        this.errors[field] = [];
    }

    resetErrors () {
        this.ref?.resetValidation();

        Object.keys(this.fields).forEach(field => {
            this.errors[field] = [];
        });
    }

    setErrors (errors) {
        Object.keys(errors).forEach(fieldName => {
            let field = fieldName.split(".")[0];
            if (this.mlFieldMap[field] && !errors[this.mlFieldMap[field]]) {
                errors[this.mlFieldMap[field]] = errors[fieldName].slice();
            }
        });
        this.errors = errors;
    }

    catch (error) {
        if (error.code == '422') {
            this.errors = error.details || {};
            return true;
        } else {
            return false;
            // throw error;
        }
    }

    reset (values = {}) {
        Object.keys(this.fields).forEach(field => {
            this.errors[field] = [];
            this.values[field] = values[field] !== undefined ? values[field] : undefined;
        });
        
        this.ref?.resetValidation();
        this.ref?.reset();
    }
    
}


export {Table, Dialog, Form};