<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('user.user')"
            :icon="mdiAccount"
            color="success"
            class="mb-6"
        >
            <IconButton
                :icon="mdiBackburger"
                :hint="System.lang('user.backHint')"
                :fn="() => {System.redirectTo('admin-users')}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <ActionForm
            v-if="user" 
            :fn="updateType"
            @errors="userForm.setErrors($event)"
        >
            <v-row dense>
                <v-col cols="6" sm="2" md="3">
                    <v-text-field
                        :label="System.lang('user.userForm.username')"
                        :hint="System.lang('user.userForm.usernameHint')"
                        counter="50"
                        v-model="user.username"
                        :rules="userForm.rules.username"
                        :error-messages="userForm.errors.username"
                        @input="userForm.resetError('username')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7" md="6">
                    <v-text-field
                        :label="System.lang('user.userForm.name')"
                        :hint="System.lang('user.userForm.nameHint')"
                        counter="150"
                        v-model="user.name"
                        :rules="userForm.rules.name"
                        :error-messages="userForm.errors.name"
                        @input="userForm.resetError('name')"
                    ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('user.userForm.status')"
                        v-model="user.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == USER_STATUS.OK" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == USER_STATUS.OK" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="7" sm="3" md="3">
                    <v-select
                        :items="roleItems"
                        :label="System.lang('user.userForm.role')"
                        v-model="user.role"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="USER_ROLES[item.value].color">{{USER_ROLES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left :color="USER_ROLES[item.value].color">{{USER_ROLES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="7" md="6">
                    <v-text-field
                        :label="System.lang('user.userForm.email')"
                        :hint="System.lang('user.userForm.emailHint')"
                        counter="250"
                        v-model="user.email"
                        :rules="userForm.rules.email"
                        :error-messages="userForm.errors.email"
                        @input="userForm.resetError('email')"
                    ></v-text-field>
                </v-col>
            </v-row>
            <template v-slot:actions>
                <ActionButton 
                    color="secondary" 
                    text
                    :label="System.lang('user.setPassword')"
                    :icon="mdiKeyOutline"
                    :fn="() => {passDialog.open()}"
                />
            </template>
        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>

    <!-- Institutions -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="user && insts">
        <HeaderPanel
            :title="System.lang('user.insts')"
            :icon="mdiDomain"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateInsts"
            @errors="instsForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-select
                        :items="insts"
                        :label="System.lang('user.instsForm.insts')"
                        v-model="user.insts"
                        item-value="id"
                        item-text="name"
                        multiple
                        chips
                        :disabled="user.role == USER_ROLE.SUPER"
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="user && currentUser.username != user.username">
        <HeaderPanel
            :title="System.lang('user.delete')"
            :hint="System.lang('user.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('user.delete')"
            :ok="System.lang('user.messages.USER_DELETED')"
            :confirm="System.lang('user.confirmDelete')"
            :confirmHint="System.lang('user.confirmDeleteHint')"
            :fn="deleteType"
        />
    </v-sheet>

    <!-- Password dialog -->
    <SimpleDialog
        :dialog="passDialog"
        :title="System.lang('user.setPassword')"
        :icon="mdiKeyOutline"
        :width="350"
        @close="passDialog.close()"
    >
        <SimpleForm 
            @ref="passForm.ref = $event"
            @input="passForm.valid = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-text-field
                        :label="System.lang('user.passForm.password')"
                        :hint="System.lang('user.passForm.passwordHint')"
                        counter="50"
                        v-model="passForm.values.password"
                        :rules="passForm.rules.password"
                        :error-messages="passForm.errors.password"
                        clearable
                        @input="passForm.resetError('password')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="updatePass"
                :disabled="!passForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {passDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiAccount,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiKeyOutline,
    mdiDomain,
} from '@mdi/js';

import { mapState } from 'vuex';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Form, Dialog} from '@/classes/Elements'
import {USER_STATUS, USER_ROLES, USER_ROLE} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import ActionForm from '@/components/forms/ActionForm'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import SimpleDialog from '@/components/system/SimpleDialog'
import SimpleForm from '@/components/forms/SimpleForm'
import ActionButton from '@/components/system/ActionButton'

export default {
    name: 'UserView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        DeleteActionButton,
        SimpleDialog,
        SimpleForm,
        ActionButton,
    },
    data () {
        return {
            // Icons
            mdiAccount,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            mdiKeyOutline,
            mdiDomain,
            // Globals
            System,
            USER_STATUS,
            USER_ROLES,
            USER_ROLE,
            loading: false,
            // Data
            userId: undefined,
            user: undefined,
            insts: undefined,
            statusItems: [
                {value: 1, text: System.lang('user.status.1')},
                {value: 2, text: System.lang('user.status.2')},
            ],
            roleItems: [
                {value: 1, text: System.lang('user.roles.1')},
                {value: 2, text: System.lang('user.roles.2')},
            ],
            // Forms
            userForm: new Form({
                username: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                email: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
                    (v) => (v && v.length <= 250) || System.lang('val.shorter'),
                ],
                status: [],
                role: [],
            }),
            passForm: new Form({
                password: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
            }),
            instsForm: new Form({
                insts: [],
            }),
            // Tables
            // Dialogs
            passDialog: new Dialog(() => {this.passForm.reset()}),
        }
    },
    computed: {
        ...mapState({
            currentUser: state => state.user.profile,
        }),
    },
    methods: {
        init () {
            this.userId = this.$route.params.id;
            this.fetchInsts();
            this.fetchData();
        },
        async fetchData () {
            this.user = undefined;
            this.loading = true;

            return Api.get(`/admin/users/${this.userId}`)
                .then(data => {
                    this.user = data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async fetchInsts () {
            return Api.get('/super/fetch/insts')
                .then(data => this.insts = data)
                .catch(error => Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message));
        },
        async updateType () {
            return Api.patch(`/admin/users/${this.userId}`, this.user).then(data => this.user = data);
        },
        async updatePass () {
            return Api.post(`/admin/users/${this.userId}/pass`, this.passForm.values)
                .then(() => {
                    this.passDialog.close();
                    this.passForm.reset();
                    Interface.snackSuccess(System.lang('messages.SAVE_OK'));
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async updateInsts () {
            return Api.post(`/admin/users/${this.userId}/insts`, {insts: this.user.insts});
        },
        async deleteType () {
            return Api.delete(`/admin/users/${this.userId}`, {})
                .then(() => {
                    System.redirectTo('admin-users');
                });
        },
    },
    mounted () {
        System.setTitle('user.user');
        this.init();
    },
}
</script>


<style scoped>
</style>
