<template>
    <div>
        <v-sheet width="100%" elevation="1" rounded class="pa-5">
            <div class="text-center">
                <v-icon color="grey" large>{{mdiElectronFramework}}</v-icon>
                <p class="text-caption grey--text mt-5">{{System.lang('reports.under')}}</p>
            </div>
        </v-sheet>
    </div>
</template>


<script>
import {
    mdiElectronFramework,
} from '@mdi/js';

import System from '@/classes/System';
// import Interface from '@/classes/Interface'
// import Api from '@/services/api'

// import HeaderPanel from '@/components/system/HeaderPanel'
// import ActionForm from '@/components/forms/ActionForm'
// import IconButton from '@/components/system/IconButton'

export default {
    name: 'ReportsView',
    components: {
        // HeaderPanel,
        // ActionForm,
        // IconButton,
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiElectronFramework,
            // Internal
            // Data
            // Tables
            // Forms
            // Dialogs
        }
    },
    computed: {
    },
    methods: {
    },
    mounted () {
        System.setTitle('menu.reports');
    },
}
</script>


<style scoped>
</style>
