<template>
<div>

    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('dynamicType.dynamicType')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="dynamicType"
                :icon="mdiBackburger"
                :hint="System.lang('dynamicType.backHint')"
                :fn="() => {System.redirectTo('admin-merchant', {params: {id: dynamicType.merchant_id}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="dynamicType" 
            :fn="updateType"
            @errors="dynamicTypeForm.setErrors($event)"
        >
            <v-row dense>
                <v-col cols="6" sm="2" md="3">
                    <v-text-field
                        :label="System.lang('dynamicType.dynamicTypeForm.code')"
                        :hint="System.lang('dynamicType.dynamicTypeForm.codeHint')"
                        counter="25"
                        v-model="dynamicType.code"
                        :rules="dynamicTypeForm.rules.code"
                        :error-messages="dynamicTypeForm.errors.code"
                        @input="dynamicTypeForm.resetError('code')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="7" md="6">
                    <v-text-field
                        :label="System.lang('dynamicType.dynamicTypeForm.name')"
                        :hint="System.lang('dynamicType.dynamicTypeForm.nameHint')"
                        counter="50"
                        v-model="dynamicType.name"
                        :rules="dynamicTypeForm.rules.name"
                        :error-messages="dynamicTypeForm.errors.name"
                        @input="dynamicTypeForm.resetError('name')"
                    >
                        <template v-slot:append>
                            <LangText
                                :label="System.lang('dynamicType.dynamicTypeForm.name')"
                                :hint="System.lang('dynamicType.dynamicTypeForm.nameHint')"
                                counter="50"
                                v-model="dynamicType.name_ml"
                                :rules="dynamicTypeForm.rules.name_ml"
                                @changed="dynamicTypeForm.resetError('name')"
                            />
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="7" sm="3" md="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('dynamicType.dynamicTypeForm.status')"
                        :hint="System.lang('dynamicType.dynamicTypeForm.statusHint')"
                        v-model="dynamicType.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == DYNAMIC_TYPE_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                    <v-select
                        :items="drivers"
                        :label="System.lang('dynamicType.dynamicTypeForm.driver')"
                        v-model="dynamicType.driver_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6" sm="2" lg="2">
                    <v-text-field
                        :label="System.lang('dynamicType.dynamicTypeForm.validity')"
                        :hint="System.lang('dynamicType.dynamicTypeForm.validityHint')"
                        v-model="dynamicType.validity"
                        :rules="dynamicTypeForm.rules.validity"
                        :error-messages="dynamicTypeForm.errors.validity"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                    <v-select
                        :items="validityUnits"
                        v-model="dynamicType.validity_units"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>

    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>
    
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="dynamicType">
        <HeaderPanel
            :title="System.lang('dynamicType.delete')"
            :hint="System.lang('dynamicType.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('dynamicType.delete')"
            :ok="System.lang('dynamicType.messages.TYPE_DELETED')"
            :confirm="System.lang('dynamicType.confirmDelete')"
            :confirmHint="System.lang('dynamicType.confirmDeleteHint')"
            :fn="deleteType"
        />
    </v-sheet>

</div>
</template>


<script>
import {
    mdiTextBoxOutline,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Form} from '@/classes/Elements'
import {DYNAMIC_TYPE_STATUS} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import LangText from '@/components/system/LangText'
import ActionForm from '@/components/forms/ActionForm'
import DeleteActionButton from '@/components/parts/DeleteActionButton'

export default {
    name: 'DynamicTypeView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        DeleteActionButton,
        LangText,
    },
    data () {
        return {
            // Icons
            mdiTextBoxOutline,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            // Globals
            System,
            DYNAMIC_TYPE_STATUS,
            // Data
            loading: false,
            dynamicTypeId: undefined,
            dynamicType: undefined,
            drivers: [],
            statusItems: [
                {value: 1, text: System.lang('dynamicType.status.1')},
                {value: 2, text: System.lang('dynamicType.status.2')},
            ],
            validityUnits: [
                {value: 2, text: System.lang('dynamicType.validity.2')},
                {value: 3, text: System.lang('dynamicType.validity.3')},
                {value: 4, text: System.lang('dynamicType.validity.4')},
            ],
            // Forms
            dynamicTypeForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                name_ml: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 50) || System.lang('val.shorter'),
                ],
                status: [],
                driver_id: [],
                validity: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^\d*$/.test(v) || System.lang('val.invalid'),
                    (v) => (+v <= 1000) || System.lang('val.less'),
                ],
                validity_units: [],
            }, {'name_ml': 'name'}),
            // Tables
            // Dialogs
        }
    },
    methods: {
        init () {
            this.dynamicTypeId = this.$route.params.id;
            this.fetchData();
        },
        async fetchData () {
            this.dynamicType = undefined;
            this.loading = true;

            return Api.get(`/admin/dynamic-types/${this.dynamicTypeId}`)
                .then(data => {
                    this.dynamicType = data.type;
                    this.drivers = data.drivers;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateType () {
            return Api.patch(`/admin/dynamic-types/${this.dynamicTypeId}`, {...this.dynamicType});
        },
        async deleteType () {
            return Api.delete(`/admin/dynamic-types/${this.dynamicTypeId}`, {})
                .then(() => {
                    System.redirectTo('admin-merchant', {params: {id: this.dynamicType.merchant_id}});
                });
        },
    },
    mounted () {
        System.setTitle('dynamicType.dynamicType');
        this.init();
    },
}
</script>


<style scoped>
</style>
