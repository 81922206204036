<template>
    <v-row>
        <v-col cols="12" sm="6">
            <div class="d-flex">
                <v-select
                    v-model="selected"
                    :items="insts"
                    item-value="id"
                    item-text="name"
                    :label="System.lang('menu.inst')"
                    hide-details
                    dense
                    outlined
                    @change="value"
                    v-bind="$attrs"
                    class="mr-1"
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-slot:prepend>
                        <v-icon left :color="color">{{mdiDomain}}</v-icon>
                    </template>
                </v-select>
                <IconButton
                    v-if="hasSlot && System.isMobile()"
                    :icon="mdiDotsHorizontal"
                    :fn="() => {show = !show}"
                />
            </div>
        </v-col>
        <v-col class="text-right" v-if="!System.isMobile() || show">
            <slot></slot>
        </v-col>
    </v-row>
</template>


<script>
import {
    mdiDomain,
    mdiDotsHorizontal,
} from '@mdi/js';

import { mapState } from 'vuex'

// import User from '@/classes/User'
import System from '@/classes/System'

import IconButton from '@/components/system/IconButton'

export default {
    name: 'InstSelector',
    components: {
        IconButton,
    },
    props: {
        color: {
            type: String,
            default: 'success',
        },
    },
    data () {
        return {
            // Globals
            System,
            // Icons
            mdiDomain,
            mdiDotsHorizontal,
            // Data
            selected: undefined,
            show: false,
        }
    },
    computed: {
        ...mapState({
            insts: state => state.user.insts,
        }),
        hasSlot () {
            return !!this.$slots.default;
        },
    },
    methods: {
        value () {
            System.saveSetting('current-inst', this.selected);
            this.$emit('selected', this.selected);
        },
    },
    mounted () {
        if (this.insts.length) {
            this.selected = Number(this.$route.query.inst) || System.loadSetting('current-inst', this.insts[0].id || 0);
            if (!this.insts.find(inst => inst.id == this.selected)) this.selected = this.insts[0].id || 0;
            this.value();
        }
    },

}
</script>


<style scoped>
</style>
