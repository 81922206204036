<template>
<div>

    <!-- Data -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('terminal.terminal')"
            :icon="mdiPrinterPosOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="terminal"
                :icon="mdiBackburger"
                :hint="System.lang('terminal.backHint')"
                :fn="() => {System.redirectTo('admin-merchant', {params: {id: terminal.merchant_id}})}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <ActionForm
            v-if="terminal" 
            :fn="updateTerminal"
            @errors="terminalForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('terminal.terminalForm.code')"
                        :hint="System.lang('terminal.terminalForm.codeHint')"
                        counter="25"
                        v-model="terminal.code"
                        :rules="terminalForm.rules.code"
                        :error-messages="terminalForm.errors.code"
                        @click="terminalForm.errors.code = []"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('terminal.terminalForm.name')"
                        :hint="System.lang('terminal.terminalForm.nameHint')"
                        counter="50"
                        v-model="terminal.name"
                        :rules="terminalForm.rules.name"
                        :error-messages="terminalForm.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('terminal.terminalForm.status')"
                        v-model="terminal.status"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == TERMINAL_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                    <v-select
                        :items="drivers"
                        :label="System.lang('terminal.terminalForm.driver')"
                        v-model="terminal.driver_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <div class="pt-3">
                <a href="#" @click.prevent="showMore = !showMore" class="text-caption">{{(showMore ? System.lang('interface.less') : System.lang('interface.more'))}}</a>
            </div>
            <v-expand-transition>
                <div v-show="showMore" class="pt-3">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('terminal.terminalForm.address')"
                                :hint="System.lang('terminal.terminalForm.addressHint')"
                                counter="250"
                                rows="4"
                                v-model="terminal.address"
                                :rules="terminalForm.rules.address"
                                :error-messages="terminalForm.errors.address"
                                @click="terminalForm.errors.address = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('terminal.terminalForm.publicContact')"
                                :hint="System.lang('terminal.terminalForm.publicContactHint')"
                                counter="250"
                                rows="4"
                                v-model="terminal.public_contact"
                                :rules="terminalForm.rules.public_contact"
                                :error-messages="terminalForm.errors.public_contact"
                                @click="terminalForm.errors.public_contact = []"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-textarea
                                :label="System.lang('terminal.terminalForm.adminContact')"
                                :hint="System.lang('terminal.terminalForm.adminContactHint')"
                                counter="250"
                                rows="4"
                                v-model="terminal.admin_contact"
                                :rules="terminalForm.rules.admin_contact"
                                :error-messages="terminalForm.errors.admin_contact"
                                @click="terminalForm.errors.admin_contact = []"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-expand-transition>
        </ActionForm>
    </v-sheet>


    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>


    <!-- QR Code -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.qrcode')"
            :icon="mdiQrcode"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateQR"
            @errors="qrForm.errors = $event"
        >
            <v-row>
                <v-col cols="12" sm="4" lg="3">
                    <v-select
                        :items="qrEccItems"
                        :label="System.lang('terminal.qrForm.ecc')"
                        v-model="terminal.qr_ecc"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="4" lg="3">
                    <v-select
                        :items="qrFormatItems"
                        :label="System.lang('terminal.qrForm.format')"
                        v-model="terminal.qr_format"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- API Keys -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.apikeys')"
            :icon="mdiKeyOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiKeyPlus"
                :hint="System.lang('terminal.addNewApikey')"
                :fn="() => {newApiKeyDialog.open()}"
            />
        </HeaderPanel>
        <DataTable 
            :table="apiKeysTable" 
            :no-data-text="System.lang('terminal.noApiKeys')" 
        >
            <template v-slot:[`item.name`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.name }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
                {{Dates.human(item.created_at, 2)}}
            </template>

            <template v-slot:[`item.valid_till`]="{ item }">
                {{(item.valid_till ? Dates.human(item.valid_till, 1) : System.lang('terminal.newApiKeyForm.never'))}}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    color="error"
                    :icon="mdiDeleteForever"
                    :hint="System.lang('terminal.deleteApiKey')"
                    :ok="System.lang('terminal.messages.APIKEY_DELETED')"
                    :error="System.lang('messages.REQUEST_FAILED')"
                    :confirm="System.lang('terminal.deleteApiKeyConfirm')"
                    :confirmHint="item.name"
                    confirmColor="error"
                    :confirmIcon="mdiKeyRemove"
                    :confirmYes="System.lang('buttons.delete')"
                    :fn="() => {return deleteApiKey(item)}"
                />
            </template>
        </DataTable>
    </v-sheet>


    <!-- IP Filter -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('ipFilter.ip')"
            :icon="mdiIpNetwork"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiPlusNetworkOutline"
                :hint="System.lang('ipFilter.addIp')"
                :fn="() => {addIpDialog.open()}"
            />
        </HeaderPanel>
        <div v-if="terminal.ip_filters && terminal.ip_filters.length">
            <p class="mb-0 text-caption grey--text">{{System.lang('ipFilter.allowedIp')}}</p>
            <v-chip
                v-for="ip in terminal.ip_filters" :key="ip.id"
                class="ma-1"
                close
                @click:close="terminal.ip_filters = reject(terminal.ip_filters, ip)"
            >
                <span v-if="ip.filter_type == IP_FILTER.ADDRESS">{{ip.from}}</span>
                <span v-if="ip.filter_type == IP_FILTER.RANGE">{{ip.from}} - {{ip.to}}</span>
            </v-chip>
        </div>
        <div v-else>
            <p class="mb-0 text-caption grey--text">{{System.lang('ipFilter.allowedAllIp')}}</p>
        </div>
        <div class="mt-5">
            <ActionButton
                text
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :icon="mdiContentSaveOutline"
                :hint="System.lang('buttons.save.hint')"
                :fn="updateIP"
                :error="System.lang('messages.SAVE_ERROR')"
                :ok="System.lang('messages.SAVE_OK')"
            />
        </div>
    </v-sheet>


    <!-- Callback -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.cback')"
            :icon="mdiArrowULeftBottom"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateCallback"
            @errors="callbackForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12" sm="6">
                    <v-radio-group 
                        v-model="terminal.callback.status" 
                        :label="System.lang('callbacks.statusLabel')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('callbacks.status.1')" :value="CALLBACK_STATUS.DISABLED"></v-radio>
                        <v-radio :label="System.lang('callbacks.status.2')" :value="CALLBACK_STATUS.INSTANT"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="6" lg="4">
                    <v-select
                        :disabled="terminal.callback.status == CALLBACK_STATUS.DISABLED"
                        :items="callback_drivers"
                        :label="System.lang('callbacks.driver')"
                        v-model="terminal.callback.driver_id"
                        :rules="callbackForm.rules.driver_id"
                        :error-messages="callbackForm.errors.driver_id"
                        item-value="id"
                        item-text="name"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <CallbackStandardJson 
                v-if="selectedCallbackDriver.code == 'standardapi'"
                :disabled="terminal.callback.status == CALLBACK_STATUS.DISABLED" 
                :callback="terminal.callback"
                :settings="terminal.callback.driver_settings?.standardapi ?? {}"
                :errors="callbackForm.errors"
                @input="set(terminal.callback, 'driver_settings.standardapi', $event)"
            />
        </ActionForm>
    </v-sheet>


    <!-- Dynamic Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.dynamicBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateDynamic"
            @errors="dynamicTypeForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-radio-group 
                        v-model="terminal.option_perm_dynamic" 
                        :label="System.lang('terminal.dynamicTypeForm.typesLabel')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('terminal.dynamicTypeForm.types.1')" :value="1"></v-radio>
                        <v-radio :label="System.lang('terminal.dynamicTypeForm.types.2')" :value="2"></v-radio>
                    </v-radio-group>
                    <v-select
                        :disabled="terminal.option_perm_dynamic != 2"
                        :items="dynamic_types_merchant"
                        :label="System.lang('terminal.dynamicTypeForm.selected')"
                        v-model="dynamic_types_terminal"
                        item-value="id"
                        item-text="name"
                        multiple
                        chips
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Static Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.staticBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateStatic"
            @errors="staticTypeForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-radio-group 
                        v-model="terminal.option_perm_static" 
                        :label="System.lang('terminal.staticTypeForm.typesLabel')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('terminal.staticTypeForm.types.1')" :value="1"></v-radio>
                        <v-radio :label="System.lang('terminal.staticTypeForm.types.2')" :value="2"></v-radio>
                    </v-radio-group>
                    <v-select
                        :disabled="terminal.option_perm_static != 2"
                        :items="static_types_merchant"
                        :label="System.lang('terminal.staticTypeForm.selected')"
                        v-model="static_types_terminal"
                        item-value="id"
                        item-text="name"
                        multiple
                        chips
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Timer Bills -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.timerBills')"
            :icon="mdiTextBoxOutline"
            color="success"
            class="mb-6"
        >
        </HeaderPanel>
        <ActionForm
            :fn="updateTimer"
            @errors="timerTypeForm.errors = $event"
        >
            <v-row dense>
                <v-col cols="12">
                    <v-radio-group 
                        v-model="terminal.option_perm_timer" 
                        :label="System.lang('terminal.timerTypeForm.typesLabel')" 
                        mandatory 
                        :row="!System.isMobile()"
                    >
                        <v-radio :label="System.lang('terminal.timerTypeForm.types.1')" :value="1"></v-radio>
                        <v-radio :label="System.lang('terminal.timerTypeForm.types.2')" :value="2"></v-radio>
                    </v-radio-group>
                    <v-select
                        :disabled="terminal.option_perm_timer != 2"
                        :items="timer_types_merchant"
                        :label="System.lang('terminal.timerTypeForm.selected')"
                        v-model="timer_types_terminal"
                        item-value="id"
                        item-text="name"
                        multiple
                        chips
                    >
                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </ActionForm>
    </v-sheet>


    <!-- Delete -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="terminal">
        <HeaderPanel
            :title="System.lang('terminal.delete')"
            :hint="System.lang('terminal.deleteHint')"
            :icon="mdiDeleteForever"
            color="error"
            class="mb-6"
        >
        </HeaderPanel>
        <DeleteActionButton 
            :hint="System.lang('terminal.delete')"
            :ok="System.lang('terminal.messages.TERMINAL_DELETED')"
            :confirm="System.lang('terminal.confirmDelete')"
            :confirmHint="System.lang('terminal.confirmDeleteHint')"
            :fn="deleteTerminal"
        />
    </v-sheet>


    <!-- New API Key Dialog -->
    <SimpleDialog
        :dialog="newApiKeyDialog"
        :title="System.lang('terminal.newApiKey')"
        :icon="mdiKeyPlus"
        @close="newApiKeyDialog.close()"
    >
        <SimpleForm 
            @ref="newApiKeyForm.ref = $event"
            @input="newApiKeyForm.valid = $event"

        >
            <v-row dense>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('terminal.newApiKeyForm.name')"
                        :hint="System.lang('terminal.newApiKeyForm.nameHint')"
                        counter="50"
                        v-model="newApiKeyForm.values.name"
                        :rules="newApiKeyForm.rules.name"
                        :error-messages="newApiKeyForm.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-menu
                        v-model="menuKeyExpires"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="newApiKeyForm.values.valid_till"
                                :label="System.lang('terminal.newApiKeyForm.expiry')"
                                :hint="System.lang('terminal.newApiKeyForm.expiryHint')"
                                :prepend-icon="mdiCalendarRemoveOutline"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click="() => {newApiKeyForm.resetError('expiry')}"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newApiKeyForm.values.valid_till"
                            first-day-of-week="1"
                            :min="currentDate"
                            no-title
                            scrollable
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="menuKeyExpires = false"
                            >
                                {{System.lang('buttons.ok')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="success"
                                @click="newApiKeyForm.values.valid_till = undefined; menuKeyExpires = false"
                            >
                                {{System.lang('terminal.newApiKeyForm.never')}}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :disabled="!newApiKeyForm.valid"
                :fn="addApiKey"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newApiKeyDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- Show API Key Dialog -->
    <SimpleDialog
        v-if="currentApiKey"
        :dialog="showApiKeyDialog"
        :title="System.lang('terminal.showApiKey')"
        :icon="mdiKeyOutline"
        @close="() => {showApiKeyDialog.close(); currentApiKey = undefined}"
        :width="450"
        persistent
    >
        <div class="text-center">
            <p class="text-caption red--text mb-0">{{System.lang('terminal.showApiKeyWarn')}}</p>
            <v-img :src="currentApiKey.qr" :max-width="300" class="mx-auto"></v-img>
            <p class="grey lighten-3 rounded pa-3">{{currentApiKey.key}}</p>
        </div>

        <div class="d-flex justify-space-around">
            <ActionButton
                :block="false"
                color="primary" 
                :label="System.lang('buttons.copy.label')"
                :hint="System.lang('buttons.copy.hint')"
                :fn="() => {return System.copy(currentApiKey.key)}"
            />
            <ActionButton
                :block="false"
                color="primary" 
                :label="System.lang('buttons.saveQr.label')"
                :hint="System.lang('buttons.saveQr.hint')"
                :fn="() => {return saveQR(currentApiKey.qr, currentApiKey.name)}"
            />
        </div>
    </SimpleDialog>


    <!-- Add IP Dialog -->
    <SimpleDialog
        :dialog="addIpDialog"
        :title="System.lang('ipFilter.addIp')"
        :icon="mdiPlusNetworkOutline"
        @close="addDialog.close()"
        :width="350"
    >
        <SimpleForm 
            @ref="addIpForm.ref = $event"
            @input="addIpForm.valid = $event"
        >

            <v-radio-group 
                v-model="addIpForm.values.filter_type" 
                class="mt-0 mb-5"
                hide-details
                mandatory
                :error-messages="addIpForm.errors.filter_type"
            >
                <v-radio :label="System.lang('ipFilter.addIpForm.single')" :value="IP_FILTER.ADDRESS"></v-radio>
                <v-radio :label="System.lang('ipFilter.addIpForm.range')" :value="IP_FILTER.RANGE"></v-radio>
            </v-radio-group>
            <v-text-field
                :label="(addIpForm.values.filter_type == IP_FILTER.ADDRESS ? System.lang('ipFilter.addIpForm.ip') : System.lang('ipFilter.addIpForm.from'))"
                counter="15"
                v-model="addIpForm.values.from"
                :rules="addIpForm.rules.from"
                :error-messages="addIpForm.errors.from"
                @click="() => {addIpForm.resetError('from')}"
            ></v-text-field>
            <v-text-field
                v-if="addIpForm.values.filter_type == IP_FILTER.RANGE"
                :label="System.lang('ipFilter.addIpForm.to')"
                counter="15"
                v-model="addIpForm.values.to"
                :rules="addIpForm.rules.to"
                :error-messages="addIpForm.errors.to"
                @click="() => {addIpForm.resetError('to')}"
            ></v-text-field>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.ok')"
                :fn="addIp"
                :disabled="!addIpForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {addIpDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiPrinterPosOutline,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiArrowULeftBottom,
    mdiTextBoxOutline,
    mdiKeyOutline,
    mdiKeyPlus,
    mdiKeyRemove,
    mdiQrcode,
    mdiCogOutline,
    mdiPhoneClock,
    mdiSortClockDescendingOutline,
    mdiTimerRefreshOutline,
    mdiApplicationCogOutline,
    mdiNumeric,
    mdiCalendarRemoveOutline,
    mdiIpNetwork,
    mdiPlusNetworkOutline,
    mdiContentSaveOutline,
} from '@mdi/js';

import set from 'lodash/set';
import reject from 'lodash/reject'
import System from '@/classes/System';
import Dates from '@/classes/Dates';
import Interface from '@/classes/Interface'
import {Form, Table, Dialog} from '@/classes/Elements'
import {TERMINAL_STATUS, CALLBACK_STATUS, IP_FILTER} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import ActionForm from '@/components/forms/ActionForm'
import DeleteActionButton from '@/components/parts/DeleteActionButton'
import CallbackStandardJson from '@/components/parts/CallbackStandardJson'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'TerminalView',
    components: {
        HeaderPanel,
        IconButton,
        ActionForm,
        DeleteActionButton,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
        CallbackStandardJson,
    },
    data () {
        return {
            // Icons
            mdiPrinterPosOutline,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            mdiArrowULeftBottom,
            mdiTextBoxOutline,
            mdiKeyOutline,
            mdiKeyPlus,
            mdiKeyRemove,
            mdiQrcode,
            mdiCogOutline,
            mdiPhoneClock,
            mdiSortClockDescendingOutline,
            mdiTimerRefreshOutline,
            mdiApplicationCogOutline,
            mdiNumeric,
            mdiCalendarRemoveOutline,
            mdiIpNetwork,
            mdiPlusNetworkOutline,
            mdiContentSaveOutline,
            // Globals
            System,
            Dates,
            TERMINAL_STATUS,
            CALLBACK_STATUS,
            IP_FILTER,
            set,
            reject,
            // Internal
            loading: false,
            menuKeyExpires: false,
            showMore: false,
            currentApiKey: undefined,
            // Data
            terminalId: undefined,
            terminal: undefined,
            drivers: [],
            callback_drivers: [],
            dynamic_types_merchant: [],
            dynamic_types_terminal: [],
            static_types_merchant: [],
            static_types_terminal: [],
            timer_types_merchant: [],
            timer_types_terminal: [],
            statusItems: [
                {value: 1, text: System.lang('terminal.status.1')},
                {value: 2, text: System.lang('terminal.status.2')},
            ],
            qrEccItems: [
                {value: 1, text: 'Level L (7%)'},
                {value: 2, text: 'Level M (15%)'},
                {value: 3, text: 'Level Q (25%)'},
                {value: 4, text: 'Level H (30%)'},
            ],
            qrFormatItems: [
                {value: 1, text: 'SVG'},
                {value: 2, text: 'PNG'},
                {value: 3, text: 'JPG'},
            ],
            // Forms
            terminalForm: new Form({
                code: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 25) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                status: [],
                driver_id: [],
                address: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                admin_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
                public_contact: [
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (!v || v.length <= 250) || System.lang('val.shorter'),
                ],
            }),
            callbackForm: new Form({
                status: [],
                driver_id: [],
            }),
            dynamicTypeForm: new Form({
                option_perm_dynamic: [],
                types: [],
            }),
            staticTypeForm: new Form({
                option_perm_static: [],
                types: [],
            }),
            timerTypeForm: new Form({
                option_perm_timer: [],
                types: [],
            }),
            newApiKeyForm: new Form({
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                    (v) => /^[a-zA-Z0-9\s-]*$/.test(v) || System.lang('val.invalid'),
                ],
                valid_till: [],
            }),
            qrForm: new Form({
                qr_ecc: [],
                qr_format: [],
            }),
            addIpForm: new Form({
                filter_type: [],
                from: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || System.lang('val.ip'),
                ],
                to: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || System.lang('val.ip'),
                    (v) => this.largerFrom(v) || System.lang('ipFilter.addIpForm.mustBeLarger'),
                ],
            }),
            // Tables
            apiKeysTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '1px',
                },
                {
                    text: System.lang('terminal.aktable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    // width: '30%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('terminal.aktable.created'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'created_at',
                    // width: '200px',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('terminal.aktable.expires'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'valid_till',
                    // width: '100px',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('terminal.aktable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '150px',
                },
            ], 'apikeys-table'),            
            // Dialogs
            newApiKeyDialog: new Dialog(() => {this.newApiKeyForm.reset()}),
            showApiKeyDialog: new Dialog(),
            addIpDialog: new Dialog(() => {this.addIpForm.reset({filter_type: IP_FILTER.ADDRESS})}),
        }
    },
    computed: {
        selectedCallbackDriver () {
            return this.callback_drivers.find(driver => driver.id == this.terminal.callback.driver_id);
        },
        currentDate () {
            return Dates.today();
        },
    },
    methods: {
        init () {
            this.terminalId = this.$route.params.id;
            this.fetchData();
        },
        async fetchData () {
            this.terminal = undefined;
            this.loading = true;

            return Api.get(`/admin/terminals/${this.terminalId}`)
                .then(data => {
                    this.terminal = data.terminal;
                    this.apiKeysTable.load(this.terminal.apikeys);
                    this.drivers = data.drivers;
                    this.callback_drivers = data.callback_drivers;
                    this.dynamic_types_merchant = data.dynamic_types_merchant;
                    this.dynamic_types_terminal = data.dynamic_types_terminal;
                    this.static_types_merchant = data.static_types_merchant;
                    this.static_types_terminal = data.static_types_terminal;
                    this.timer_types_merchant = data.timer_types_merchant;
                    this.timer_types_terminal = data.timer_types_terminal;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateTerminal () {
            return Api.patch(`/admin/terminals/${this.terminalId}`, this.terminal);
        },
        async updateCallback () {
            return Api.patch(`/admin/terminals/${this.terminalId}/callback`, this.terminal.callback);
        },
        async updateDynamic () {
            return Api.patch(`/admin/terminals/${this.terminalId}/dynamic-types`, {option_perm_dynamic: this.terminal.option_perm_dynamic, types: this.dynamic_types_terminal});
        },
        async updateStatic () {
            return Api.patch(`/admin/terminals/${this.terminalId}/static-types`, {option_perm_static: this.terminal.option_perm_static, types: this.static_types_terminal});
        },
        async updateTimer () {
            return Api.patch(`/admin/terminals/${this.terminalId}/timer-types`, {option_perm_timer: this.terminal.option_perm_timer, types: this.timer_types_terminal});
        },
        async updateQR () {
            return Api.patch(`/admin/terminals/${this.terminalId}/qr`, this.terminal);
        },
        async updateIP () {
            return Api.patch(`/admin/terminals/${this.terminalId}/ip`, {ip_filters: this.terminal.ip_filters}).then(data => this.terminal.ip_filters = data.ip_filters);
        },
        async deleteTerminal () {
            return Api.delete(`/admin/terminals/${this.terminalId}`, {})
                .then(() => {
                    System.redirectTo('admin-merchant', {params: {id: this.terminal.merchant_id}});
                });
        },
        async addApiKey () {
            if (this.newApiKeyForm.validate()) {
                return Api.post(`/admin/terminals/${this.terminalId}/apikey`, this.newApiKeyForm.values)
                    .then(data => {
                        this.newApiKeyDialog.close();
                        this.apiKeysTable.add(data.key);

                        this.currentApiKey = Object.assign({}, data.key);
                        this.currentApiKey.key = data.value;
                        this.currentApiKey.qr = data.qr;
                        this.showApiKeyDialog.open();
                        
                        Interface.snackSuccess(System.lang('terminal.messages.APIKEY_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newApiKeyForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        async deleteApiKey (key) {
            return Api.delete(`/admin/terminals/${this.terminalId}/apikey/${key.id}`, {})
                .then(() => {
                    this.apiKeysTable.delete(key);
                });
        },
        async saveQR (data, name) {
            System.svgToImage(data, 500, 500, 'png', (pngData) => {
                System.saveFile(`${name}.png`, pngData);
            });
        },
        addIp () {
            if (this.addIpForm.validate()) {
                let ip = Object.assign({}, this.addIpForm.values);
    
                if (!this.terminal.ip_filters) {
                    this.terminal.ip_filters = [];
                }
    
                this.terminal.ip_filters.push(ip);
                this.addIpDialog.close();
            }
        },
        ip2long(ip) {
            return ip.split('.').reduce((ipLong, octet) => (ipLong << 8) | parseInt(octet), 0) >>> 0;
        },
        largerFrom (v) {
            if (!v || !this.addIpForm.values.from) return false;
            return this.ip2long(v) > this.ip2long(this.addIpForm.values.from);
        },
    },
    mounted () {
        System.setTitle('terminal.terminal');
        this.init();
    },
}
</script>


<style scoped>
</style>
