<template>
<div>

    <!-- Data -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5">
        <HeaderPanel
            :title="System.lang('paychannel.sharedpaychannel')"
            :icon="mdiCreditCardSyncOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                v-if="paychannel"
                :icon="mdiBackburger"
                :hint="System.lang('paychannel.backHint')"
                :fn="() => {System.redirectTo('admin-paychannels')}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="() => {return fetchData()}"
            />
        </HeaderPanel>
        <SimpleForm
            v-if="paychannel" 
        >
            <v-row dense>
                <v-col cols="6" sm="3">
                    <v-text-field
                        :label="System.lang('paychannel.paychannelForm.code')"
                        v-model="paychannel.code"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        :label="System.lang('paychannel.paychannelForm.name')"
                        v-model="paychannel.name"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="7" sm="3">
                    <v-select
                        :items="statusItems"
                        :label="System.lang('paychannel.paychannelForm.status')"
                        v-model="paychannel.status"
                        disabled
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left v-if="item.value == PAYCHANNEL_STATUS.ENABLED" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left v-if="item.value == 1" color="success">{{mdiCheck}}</v-icon>
                            <v-icon left v-else color="error">{{mdiClose}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <div class="pt-3">
                <a href="#" @click.prevent="showMore = !showMore" class="text-caption">{{(showMore ? System.lang('interface.less') : System.lang('interface.more'))}}</a>
            </div>
            <v-expand-transition>
            <div v-show="showMore" class="pt-3">

            <v-row dense>
                <v-col cols="12" sm="3">
                    <v-textarea
                        :label="System.lang('paychannel.paychannelForm.address')"
                        rows="4"
                        v-model="paychannel.address"
                        disabled
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-textarea
                        :label="System.lang('paychannel.paychannelForm.legalInfo')"
                        rows="4"
                        v-model="paychannel.legal_info"
                        disabled
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-textarea
                        :label="System.lang('paychannel.paychannelForm.publicContact')"
                        rows="4"
                        v-model="paychannel.public_contact"
                        disabled
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-textarea
                        :label="System.lang('paychannel.paychannelForm.adminContact')"
                        rows="4"
                        v-model="paychannel.admin_contact"
                        disabled
                    ></v-textarea>
                </v-col>
            </v-row>

            </div>
            </v-expand-transition>

        </SimpleForm>
    </v-sheet>


    <v-progress-linear indeterminate rounded class="mt-2" v-if="loading"></v-progress-linear>


    <!-- Permissions -->
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3" v-if="paychannel">
        <HeaderPanel
            :title="System.lang('paychannel.perm')"
            :icon="mdiTableKey"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiTablePlus"
                :hint="System.lang('paychannel.addNewPermHint')"
                :fn="() => {newPermDialog.open()}"
            />
        </HeaderPanel>
        <DataTable 
            :table="permsTable" 
            :no-data-text="System.lang('paychannel.permstable.noPerms')" 
        >
            <template v-slot:[`item.inst`]="{ item }">
                <p class="my-0 primary--text" v-if="item.inst.id == instId">{{ item.inst.code }}</p>
                <p class="my-0" v-else>{{ item.inst.code }}</p>
                <p class="my-0 text-caption grey--text">{{ item.inst.name }}</p>
            </template>

            <template v-slot:[`item.merchant`]="{ item }">
                <div v-if="item.merchant">
                    <p class="my-0">{{ item.merchant.code }}</p>
                    <p class="my-0 text-caption grey--text">{{ item.merchant.name }}</p>
                </div>
                <v-chip
                    v-else
                    class="text-caption"
                >
                    {{ System.lang('paychannel.permstable.all') }}
                </v-chip>
            </template>

            <template v-slot:[`item.dynamic`]="{ item }">
                <v-chip
                    v-if="item.merchant == null || (item.inst.id != instId && item.dynamic == null)"
                    class="text-caption"
                >
                    {{ System.lang('paychannel.permstable.all') }}
                </v-chip>
                <v-chip
                    v-else-if="item.inst.id != instId && item.dynamic != null"
                    class="text-caption"
                    color="primary"
                >
                    {{ item.dynamic?.length }} {{ System.lang('paychannel.permstable.selected') }}
                </v-chip>
                <ActionChip
                    v-else-if="item.dynamic != null"
                    :hint="System.lang('paychannel.permstable.configDynamic')"
                    classes="text-caption"
                    color="primary"
                    @click="openEditDynamicDialog(item)"
                >
                    {{ item.dynamic?.length }} {{ System.lang('paychannel.permstable.selected') }}
                </ActionChip>
                <ActionChip
                    v-else
                    :hint="System.lang('paychannel.permstable.configDynamic')"
                    classes="text-caption"
                    @click="openEditDynamicDialog(item)"
                >
                    {{ System.lang('paychannel.permstable.all') }}
                </ActionChip>
            </template>

            <template v-slot:[`item.static`]="{ item }">
                <v-chip
                    v-if="item.merchant == null || (item.inst.id != instId && item.static == null)"
                    class="text-caption"
                >
                    {{ System.lang('paychannel.permstable.all') }}
                </v-chip>
                <v-chip
                    v-else-if="item.inst.id != instId && item.static != null"
                    class="text-caption"
                    color="primary"
                >
                    {{ item.static?.length }} {{ System.lang('paychannel.permstable.selected') }}
                </v-chip>
                <ActionChip
                    v-else-if="item.static != null"
                    :hint="System.lang('paychannel.permstable.configStatic')"
                    classes="text-caption"
                    color="primary"
                    @click="openEditStaticDialog(item)"
                >
                    {{ item.static?.length }} {{ System.lang('paychannel.permstable.selected') }}
                </ActionChip>
                <ActionChip
                    v-else
                    :hint="System.lang('paychannel.permstable.configStatic')"
                    classes="text-caption"
                    @click="openEditStaticDialog(item)"
                >
                    {{ System.lang('paychannel.permstable.all') }}
                </ActionChip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconButton
                    color="error"
                    :icon="mdiDeleteForever"
                    :hint="System.lang('paychannel.permstable.deletePerm')"
                    :ok="System.lang('paychannel.messages.PERMRULE_DELETED')"
                    :error="System.lang('messages.REQUEST_FAILED')"
                    :confirm="System.lang('paychannel.permstable.deletePermConfirm')"
                    :confirmHint="item.inst.name + ' / ' + (item.merchant ? item.merchant.name : System.lang('paychannel.newPermForm.allMerchants'))"
                    confirmColor="error"
                    :confirmIcon="mdiTableRemove"
                    :confirmYes="System.lang('buttons.delete')"
                    :fn="() => {return deletePerm(item)}"
                />
            </template>
        </DataTable>
    </v-sheet>


    <!-- New Permission Dialog -->
    <SimpleDialog
        :dialog="newPermDialog"
        :title="System.lang('paychannel.addNewPerm')"
        :icon="mdiTablePlus"
        @close="newPermDialog.close()"
        :width="500"
    >
        <v-progress-linear indeterminate rounded class="my-2" v-if="newPermDialog.loading"></v-progress-linear>

        <div v-else>
            <v-row dense>
                <v-col cols="12">
                    <v-autocomplete
                        :items="merchants"
                        :label="System.lang('paychannel.newPermForm.merchant')"
                        v-model="newPermForm.values.merchant_id"
                        :rules="newPermForm.rules.merchant_id"
                        :error-messages="newPermForm.errors.merchant_id"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
        </div>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addNewPerm"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.close')"
                :fn="() => {newPermDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- Edit Dynamic Permissions Dialog -->
    <SimpleDialog
        v-if="rule"
        :dialog="editDynamicDialog"
        :title="System.lang('paychannel.editDynamicPerm')"
        :icon="mdiTableKey"
        @close="editDynamicDialog.close()"
        :width="500"
    >
        <v-row dense>
            <v-col cols="12">
                <v-radio-group 
                    v-model="editDynamicForm.values.type" 
                    :label="System.lang('paychannel.editDynamicForm.typesLabel')" 
                    mandatory 
                    :row="!System.isMobile()"
                >
                    <v-radio :label="System.lang('paychannel.editDynamicForm.dynamicTypes.1')" :value="1"></v-radio>
                    <v-radio :label="System.lang('paychannel.editDynamicForm.dynamicTypes.2')" :value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12">
                <v-select
                    :disabled="editDynamicForm.values.type != 2"
                    :items="rule.merchant.dynamic_types"
                    :label="System.lang('paychannel.editDynamicForm.dynamicTypesSel')"
                    v-model="editDynamicForm.values.dynamic"
                    item-value="id"
                    item-text="name"
                    multiple
                    chips
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="saveDynamicPerm"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.close')"
                :fn="() => {editDynamicDialog.close()}"
            />
        </template>
    </SimpleDialog>


    <!-- Edit Static Permissions Dialog -->
    <SimpleDialog
        v-if="rule"
        :dialog="editStaticDialog"
        :title="System.lang('paychannel.editStaticPerm')"
        :icon="mdiTableKey"
        @close="editStaticDialog.close()"
        :width="500"
    >
        <v-row dense>
            <v-col cols="12">
                <v-radio-group 
                    v-model="editStaticForm.values.type" 
                    :label="System.lang('paychannel.editStaticForm.typesLabel')" 
                    mandatory 
                    :row="!System.isMobile()"
                >
                    <v-radio :label="System.lang('paychannel.editStaticForm.staticTypes.1')" :value="1"></v-radio>
                    <v-radio :label="System.lang('paychannel.editStaticForm.staticTypes.2')" :value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12">
                <v-select
                    :disabled="editStaticForm.values.type != 2"
                    :items="rule.merchant.static_types"
                    :label="System.lang('paychannel.editStaticForm.staticTypesSel')"
                    v-model="editStaticForm.values.static"
                    item-value="id"
                    item-text="name"
                    multiple
                    chips
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.code}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="saveStaticPerm"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.close')"
                :fn="() => {editStaticDialog.close()}"
            />
        </template>
    </SimpleDialog>


</div>
</template>


<script>
import {
    mdiCreditCardSyncOutline,
    mdiRefresh,
    mdiBackburger,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiArrowULeftBottom,
    mdiTextBoxOutline,
    mdiKeyOutline,
    mdiKeyPlus,
    mdiEyeLockOpenOutline,
    mdiKeyRemove,
    mdiCogTransferOutline,
    mdiTableKey,
    mdiTableRemove,
    mdiTablePlus,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Form, Table, Dialog} from '@/classes/Elements'
import {PAYCHANNEL_STATUS} from '@/constants/system'
import Api from '@/services/api'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import ActionChip from '@/components/system/ActionChip'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'SharedPaychannelView',
    components: {
        HeaderPanel,
        IconButton,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
        ActionChip,
    },
    data () {
        return {
            // Icons
            mdiCreditCardSyncOutline,
            mdiRefresh,
            mdiBackburger,
            mdiCheck,
            mdiClose,
            mdiDeleteForever,
            mdiArrowULeftBottom,
            mdiTextBoxOutline,
            mdiKeyOutline,
            mdiKeyPlus,
            mdiEyeLockOpenOutline,
            mdiKeyRemove,
            mdiCogTransferOutline,
            mdiTableKey,
            mdiTableRemove,
            mdiTablePlus,
            // Globals
            System,
            Interface,
            PAYCHANNEL_STATUS,
            // Internal
            loading: false,
            showMore: false,
            // Data
            instId: undefined,
            paychannelId: undefined,
            paychannel: undefined,
            statusItems: [
                {value: 1, text: System.lang('paychannel.status.1')},
                {value: 2, text: System.lang('paychannel.status.2')},
            ],
            merchants: [],
            merchant: undefined,
            rule: undefined,
            // Forms
            newPermForm: new Form({
                inst_id: [],
                merchant_id: [],
            }),
            editDynamicForm: new Form({
                type: [],
                dynamic: [],
            }),
            editStaticForm: new Form({
                type: [],
                static: [],
            }),
            // Tables
            apiKeysTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '1px',
                },
                {
                    text: System.lang('paychannel.aktable.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    // width: '30%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.aktable.created'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'created_at',
                    width: '250px',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.aktable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '150px',
                },
            ], 'apikeys-table'),
            permsTable: new Table([
                {
                    text: System.lang('paychannel.permstable.inst'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'inst',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.permstable.merchant'),
                    sortable: false,
                    filterable: false,
                    align: 'left',
                    value: 'merchant',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.permstable.dynamic'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'dynamic',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.permstable.static'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'static',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('paychannel.permstable.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '150px',
                },
            ], 'pay-perms-table'),
            // Dialogs
            newPermDialog: new Dialog(this.openNewPermDialog),
            editDynamicDialog: new Dialog(),
            editStaticDialog: new Dialog(),
        }
    },
    methods: {
        init () {
            this.fetchData();
        },
        async fetchData () {
            this.paychannel = undefined;
            this.loading = true;

            return Api.get(`/admin/insts/${this.instId}/sharedpaychannels/${this.paychannelId}`)
                .then(data => {
                    this.paychannel = data.paychannel;
                    this.permsTable.load(data.permissions);
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async openNewPermDialog () {
            this.newPermForm.reset({inst_id: this.instId, merchant_id: 0});
            
            if (this.merchants.length) return true;
            
            return Api.get(`/admin/insts/${this.instId}/merchants`)
                .then(data => {
                this.merchants = [{value: 0, text: System.lang('paychannel.newPermForm.allMerchants')}, ...data.map(merchant => {return {value: merchant.id, text: merchant.code + ' - ' + merchant.name}})];
                    return data;
                })
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addNewPerm () {
            return Api.post(`/admin/paychannels/${this.paychannelId}/permrule`, this.newPermForm.values)
                .then(data => {
                    this.newPermDialog.close();
                    this.permsTable.add(data);
                    Interface.snackSuccess(System.lang('paychannel.messages.PERMRULE_ADDED'));
                })
                .catch(error => {
                    if (!this.newPermForm.catch(error)) {
                        Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                    }
                });
        },
        async deletePerm (rule) {
            return Api.delete(`/admin/paychannels/${this.paychannelId}/permrule/${rule.id}`, {})
                .then(() => {
                    this.permsTable.delete(rule);
                });
        },
        async openEditDynamicDialog (rule) {
            if (!rule.merchant) return;
            
            this.rule = rule;
            this.editDynamicForm.values.dynamic = rule.dynamic;

            if (!this.rule.dynamic) {
                this.editDynamicForm.values.type = 1;
            } else {
                this.editDynamicForm.values.type = 2;
            }

            this.editDynamicDialog.open();
        },
        async openEditStaticDialog (rule) {
            if (!rule.merchant) return;
            
            this.rule = rule;
            this.editStaticForm.values.static = rule.static;

            if (!this.rule.static) {
                this.editStaticForm.values.type = 1;
            } else {
                this.editStaticForm.values.type = 2;
            }

            this.editStaticDialog.open();
        },
        async saveDynamicPerm () {
            return Api.patch(`/admin/paychannels/${this.paychannelId}/permdynamic/${this.rule.id}`, this.editDynamicForm.values)
                .then(data => {
                    this.editDynamicDialog.close();
                    Object.assign(this.rule, data);
                    Interface.snackSuccess(System.lang('paychannel.messages.PERMRULE_SAVED'));
                })
                .catch(error => {
                    if (!this.newPermForm.catch(error)) {
                        Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                    }
                });
        },
        async saveStaticPerm () {
            return Api.patch(`/admin/paychannels/${this.paychannelId}/permstatic/${this.rule.id}`, this.editStaticForm.values)
                .then(data => {
                    this.editStaticDialog.close();
                    Object.assign(this.rule, data);
                    Interface.snackSuccess(System.lang('paychannel.messages.PERMRULE_SAVED'));
                })
                .catch(error => {
                    if (!this.editStaticForm.catch(error)) {
                        Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                    }
                });
        },
    },
    mounted () {
        System.setTitle('paychannel.sharedpaychannel');
        this.paychannelId = this.$route.params.id;
        this.instId = this.$route.params.instId;
        this.init();
    },
}
</script>


<style scoped>
</style>
