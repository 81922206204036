<template>
<div>
    <v-sheet width="100%" elevation="1" rounded class="pa-5 mt-3">
        <HeaderPanel
            :title="System.lang('users.users')"
            :icon="mdiAccountMultipleOutline"
            color="success"
            class="mb-6"
        >
            <IconButton
                color="primary"
                :icon="mdiAccountPlusOutline"
                :hint="System.lang('users.addNewHint')"
                :fn="() => {newDialog.open()}"
            />
            <IconButton
                v-if="usersTable.hasData()"
                :color="showSearch ? 'primary' : 'grey'"
                :icon="mdiMagnify"
                :hint="System.lang('users.searchUsers')"
                :fn="() => {showSearch = !showSearch}"
            />
            <IconButton
                :icon="mdiRefresh"
                :hint="System.lang('buttons.refresh')"
                :fn="init"
            />
        </HeaderPanel>
        <DataTable 
            :table="usersTable" 
            :search="search"
            :no-data-text="System.lang('users.table.noUsers')" 
        >
            <template v-slot:[`body.prepend`] v-if="usersTable.hasData() && showSearch">
                <tr>
                    <td colspan="3" class="px-1">
                        <v-text-field
                            v-model="search"
                            :label="System.lang('users.table.search')"
                            single-line
                            hide-details
                            dense
                            outlined
                            clearable
                        ></v-text-field>                    
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="searchRole"
                            :items="roleItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td class="px-1">
                        <v-select
                            v-model="searchStatus"
                            :items="statusItems"
                            single-line
                            hide-details
                            dense
                            outlined
                        ></v-select>
                    </td>
                    <td></td>
                </tr>
            </template>

            <template v-slot:[`item.username`]="{ item }">
                <span class="font-weight-medium primary--text">{{ item.username }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <IconHint
                    :icon="item['status'] == USER_STATUS.OK ? mdiCheck : mdiClose"
                    :hint="System.lang('users.status.' + item['status'])"
                    :color="item['status'] == USER_STATUS.OK ? 'success' : 'error'"
                />
            </template>

            <template v-slot:[`item.role`]="{ item }">
                <IconHint
                    :icon="USER_ROLES[item.role].icon"
                    :hint="System.lang(`users.roles.${item.role}`)"
                    :color="USER_ROLES[item.role].color"
                />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <IconLink
                    :icon="mdiCogOutline"
                    :hint="System.lang('buttons.config.hint')"
                    color="primary"
                    :to="{name: 'admin-user', params: {id: item.id}}"
                />
            </template>
        </DataTable>

    </v-sheet>

    <SimpleDialog
        :dialog="newDialog"
        :title="System.lang('users.newUser')"
        :icon="mdiAccountPlusOutline"
        @close="newDialog.close()"
    >
        <SimpleForm 
            @ref="newForm.ref = $event"
            @input="newForm.valid = $event"
        >
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                        :label="System.lang('users.newForm.username')"
                        :hint="System.lang('users.newForm.usernameHint')"
                        counter="50"
                        v-model="newForm.values.username"
                        :rules="newForm.rules.username"
                        :error-messages="newForm.errors.username"
                        @click="newForm.errors.username = []"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('users.newForm.name')"
                        :hint="System.lang('users.newForm.nameHint')"
                        counter="150"
                        v-model="newForm.values.name"
                        :rules="newForm.rules.name"
                        :error-messages="newForm.errors.name"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-select
                        :items="roleItemsNew"
                        :label="System.lang('users.newForm.role')"
                        v-model="newForm.values.role"
                    >
                        <template v-slot:item="{ item }">
                            <v-icon left :color="USER_ROLES[item.value].color">{{USER_ROLES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>

                        <template v-slot:selection="{ item }">
                            <v-icon left :color="USER_ROLES[item.value].color">{{USER_ROLES[item.value].icon}}</v-icon>
                            {{item.text}}
                        </template>
                    </v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        :label="System.lang('users.newForm.email')"
                        :hint="System.lang('users.newForm.emailHint')"
                        counter="250"
                        v-model="newForm.values.email"
                        :rules="newForm.rules.email"
                        :error-messages="newForm.errors.email"
                        @input="newForm.resetError('email')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </SimpleForm>

        <template v-slot:actions>
            <ActionButton
                text
                :block="false"
                color="primary" 
                :label="System.lang('buttons.save.label')"
                :fn="addUser"
                :disabled="!newForm.valid"
            />
            <v-spacer></v-spacer>
            <ActionButton
                text
                :block="false"
                color="grey"
                :label="System.lang('buttons.cancel')"
                :fn="() => {newDialog.close()}"
            />
        </template>
    </SimpleDialog>

</div>
</template>


<script>
import {
    mdiLifebuoy,
    mdiRefresh,
    mdiAccountMultipleOutline,
    mdiAccountPlusOutline,
    mdiCogOutline,
    mdiCheck,
    mdiClose,
    mdiMagnify,
} from '@mdi/js';

import System from '@/classes/System';
import Interface from '@/classes/Interface'
import {Table, Dialog, Form} from '@/classes/Elements'
import Api from '@/services/api'
import {USER_STATUS, USER_ROLES, USER_ROLE} from '@/constants/system'

import HeaderPanel from '@/components/system/HeaderPanel'
import IconButton from '@/components/system/IconButton'
import IconLink from '@/components/system/IconLink'
import IconHint from '@/components/system/IconHint'
import DataTable from '@/components/system/DataTable'
import SimpleDialog from '@/components/system/SimpleDialog'
import ActionButton from '@/components/system/ActionButton'
import SimpleForm from '@/components/forms/SimpleForm'

export default {
    name: 'UsersView',
    components: {
        HeaderPanel,
        // ActionForm,
        IconButton,
        IconLink,
        // IconAction,
        IconHint,
        DataTable,
        SimpleDialog,
        ActionButton,
        SimpleForm,
    },
    data () {
        return {
            // Icons
            mdiLifebuoy,
            mdiRefresh,
            mdiAccountMultipleOutline,
            mdiAccountPlusOutline,
            mdiCogOutline,
            mdiCheck,
            mdiClose,
            mdiMagnify,
            // Globals
            System,
            USER_STATUS,
            USER_ROLES,
            USER_ROLE,
            // Data
            showSearch: false,
            search: '',
            searchStatus: 0,
            searchRole: 0,
            statusItems: [
                {value: 0, text: System.lang('users.status.0')},
                {value: 1, text: System.lang('users.status.1')},
                {value: 2, text: System.lang('users.status.2')},
            ],
            roleItems: [
                {value: 0, text: System.lang('users.roles.0')},
                {value: 1, text: System.lang('users.roles.1')},
                {value: 2, text: System.lang('users.roles.2')},
            ],
            roleItemsNew: [
                {value: 1, text: System.lang('users.roles.1')},
                {value: 2, text: System.lang('users.roles.2')},
            ],
            // Forms
            newForm: new Form({
                username: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9-_]*$/.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 50) || System.lang('val.shorter'),
                ],
                name: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[\p{L}\p{N}\p{P}\p{S}\s]*$/u.test(v) || System.lang('val.invalid'),
                    (v) => (v && v.length <= 150) || System.lang('val.shorter'),
                ],
                email: [
                    (v) => !!v || System.lang('val.required'),
                    (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || System.lang('val.email'),
                    (v) => (v && v.length <= 250) || System.lang('val.shorter'),
                ],
                role: [],
            }),
            // Tables
            usersTable: new Table([
                {
                    text: 'ID',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'id',
                    width: '50px',
                },
                {
                    text: System.lang('users.table.username'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'username',
                    width: '15%',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('users.table.name'),
                    sortable: true,
                    filterable: true,
                    align: 'left',
                    value: 'name',
                    cellClass: "no-wrap",
                },
                {
                    text: System.lang('users.table.role'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'role',
                    width: '120px',
                    filter: this.filterRole,
                },
                {
                    text: System.lang('users.table.status'),
                    sortable: true,
                    filterable: true,
                    align: 'center',
                    value: 'status',
                    width: '120px',
                    filter: this.filterStatus,
                },
                {
                    text: System.lang('users.table.actions'),
                    sortable: false,
                    filterable: false,
                    align: 'center',
                    value: 'actions',
                    width: '120px',
                },
            ], 'users-table', this.fetchUsers),
            // Dialogs
            newDialog: new Dialog(() => {this.newForm.reset({role: USER_ROLE.ADMIN})}),
        }
    },
    computed: {
    },
    methods: {
        init () {
            return this.usersTable.load();    
        },
        async fetchUsers () {
            this.search = '';
            this.searchStatus = 0;
            this.searchRole = 0;

            return Api.get('/admin/users')
                .catch(error => {
                    Interface.popupError(System.lang('messages.REQUEST_FAILED'), error.message);
                });
        },
        async addUser () {
            if (this.newForm.validate()) {
                return Api.post('/admin/users', this.newForm.values)
                    .then(data => {
                        this.newDialog.close();
                        this.usersTable.add(data);
                        System.redirectTo('admin-user', {params: {id: data.id}});
                        Interface.snackSuccess(System.lang('users.messages.USER_ADDED'));
                    })
                    .catch(error => {
                        if (!this.newForm.catch(error)) {
                            Interface.popupError(System.lang('messages.SAVE_ERROR'), error.message);
                        }
                    });
            }
        },
        filterStatus (value) {
            return !this.searchStatus || value == this.searchStatus;
        },
        filterRole (value) {
            return !this.searchRole || value == this.searchRole;
        },
    },
    mounted () {
        System.setTitle('users.users');
        this.init();
    },
}
</script>


<style scoped>
</style>
