<template>
    <v-tooltip bottom :disabled="!hint">
        <template v-slot:activator="{on, attrs}">
            <router-link :to="to">
                <v-icon 
                    :color="color"
                    class="mx-1"
                    v-bind="{...attrs, ...$attrs}"
                    v-on="on"
                >{{icon}}</v-icon>
            </router-link>
        </template>
        <span>{{hint}}</span>
    </v-tooltip>
</template>


<script>
// import System from "@/classes/System";
// import Interface from "@/classes/Interface";

export default {
    name: 'IconLink',
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'grey',
        },
        icon: {
            type: String,
            required: true,
        },
        hint: {
            type: [String, Boolean],
            default: false,
        },
        to: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
}
</script>


<style scoped>
</style>
